import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from './APIWorker';
import React from 'react';
import {BodyContext} from './TrainingProvider';

export default class FileUploadModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            recordId : this.props.cardDetailId,
            filesToUpload : [],
            fileNames : [],
            filesToProcess : 0
        }

        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
    }

    showErrorAlert(errorMessage){
        this.context.showErrorAlert(errorMessage);
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    closeModal(){
        this.context.changeModal('');
    }


    handleFileSelect = (e) => {
        var files = e.target.files;
        console.log(files);
        if(files.length > 0){
            this.setState({
                filesToProcess : files.length
            });

            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                console.log(file);
                //30MB -> Bytes (31457280) max size for file
                if(file.size > 31457280){
                    this.showErrorAlert('File ' + file.name + ' cannot be larger than 30 MB!');
                }
                else{
                    this.processFile(file);
                }
            }
        }
        else{
            this.showErrorAlert('No files to upload!');
        }

    };

    processFile(file){
        var fileData;
        const promise = new Promise((resolve) => {
            var reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            }
            reader.readAsDataURL(file);
        });

        promise.then(fileResult => {
            var baseURL = fileResult;
            console.log(baseURL.split(',')[0]);
            console.log(baseURL.split(',')[1]);
            var base64String = baseURL.split(',')[1];
            var fileType = file.type.split('/')[1];

            fileData = {
                'title' : file.name,
                'fileType' : fileType,
                'recordId' : this.props.cardDetailId,
                'base64String' : base64String
            };
    
            this.setState({
                filesToUpload : [...this.state.filesToUpload, fileData],
                fileNames : [...this.state.fileNames, file.name]
            }, 
                () => {
                    if(this.state.filesToUpload.length === this.state.filesToProcess){
                        this.context.showSuccessAlert('Files ready for upload!');
                    }
                }
            );

            console.log(fileData);
        })
    }

    submitFiles() {
        this.showSpinner();
        this.state.filesToUpload.forEach(function (file, i) {
            console.log(file);

            APIWorker.uploadFile(file)
            .then(res => {
                console.log('File uploaded');
            })
            .catch(error => {
                console.log(error);
            })
        });

        this.closeModal();
        this.hideSpinner();
        this.context.showSuccessAlert('Files successfully uploaded!');
    }


    render(){
        return(
            <Modal isOpen={true} heading="File Upload" ariaHideApp={false} align="center" size="large"
            footer={[
                <Button key="1" label="Cancel" onClick={() =>this.closeModal()} />,
                <Button key="2" disabled={this.state.filesToUpload.length === 0 ? true : false} label="Submit" onClick={() => this.submitFiles()} variant="brand"/>
            ]}>
                <div>
                    <article className="slds-card">
                        <div className="slds-card__header slds-grid">
                            <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                <div className="slds-media__body">
                                    <input
                                        type='file'
                                        accept='*'
                                        name='fileSelect'
                                        className='slds-file-selector__input'
                                        multiple='multiple'
                                        onChange={(e) => this.handleFileSelect(e)}
                                    />                        
                                </div>
                            </div>
                        </div>

                        <div className="slds-card__header slds-grid">
                            <b>{this.state.fileNames.length} Files Total</b>
                        </div>
                        
                        <div className="slds-card__header slds-grid">
                            <ul>
                                {this.state.fileNames.map(fileName => <li key={fileName}>{fileName}</li>)}
                            </ul>
                        </div>
                    </article>
                </div>
            </Modal>
        )
    }

}