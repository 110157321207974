import Button from '@salesforce/design-system-react/lib/components/button';
import React from 'react';
import Input from '@salesforce/design-system-react/lib/components/input';
import APIWorker from '../APIWorker';

export default class OSConfirmCheckInComponent extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data : [],
            firstName : this.props.record.FirstName !== null ? this.props.record.FirstName : '', 
            lastName : this.props.record.LastName !== null ? this.props.record.LastName : '',
            email : this.props.record.Email !== null ? this.props.record.Email : '',
            mode: this.props.mode,
            requiresEmail : this.props.sa.Requires_Attendee_Email__c,
            
            nameErrorMessage : '',
            emailErrorMessage : ''
        }

        this.validateAttendee = this.validateAttendee.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.determineMode = this.determineMode.bind(this);
    }

    clearErrors(){
        this.setState({
            nameErrorMessage : '',
            emailErrorMessage : ''
        });
    }

    validateAttendee(){
        this.clearErrors();
        
        if(this.state.firstName === '' || this.state.lastName === ''){
            this.setState({
                nameErrorMessage : 'Cannot leave first name or last name blank!'
            });
        }
        else if(/^[a-zA-ZÀ-Ÿ-'`’.\s]+$/.test(this.state.firstName) !== true || /^[a-zA-ZÀ-Ÿ-'`’.\s]+$/.test(this.state.lastName) !== true){
            this.setState({
                nameErrorMessage : 'First or last name can only contain letters, hyphens, accents and apostrophes!'
            });
        }
        else if(this.state.email === '' && this.state.requiresEmail){
            this.setState({
                emailErrorMessage : 'Email is a required field!'
            });                  
        }   
        else if(/^.+@.+\..+$/.test(this.state.email) !== true && this.state.email !== ''){
            this.setState({
                emailErrorMessage : 'Email format is invalid! (ex. john@gmail.com)'
            });  
        }
        //If no errors, proceed with check-in process
        else{
            this.props.showSpinner();

        this.setState({
            firstName : this.state.firstName.trim(),
            lastName : this.state.lastName.trim()
        });

            this.determineMode();
        }
    }

    //Determine if creating contact or using existing
    determineMode(){
        let json = {
            "AccountId" : this.props.record.AccountId,
            "FirstName" : this.state.firstName,
            "LastName" : this.state.lastName,
            "Email" : this.state.email
        };

        //Contact already exists, update with existing data
        if(this.state.mode === 'link'){
            APIWorker.updateContact(this.props.record.Id, json)
            .then(res => {
                this.props.checkInAttendee(this.props.record);
            })
            .catch(error => {
                console.log(error);
                var errorMessage = String(JSON.parse(error.response.data.message)[0].message).includes('unique_email__c') ? 'Email must be unique!' : null;
                this.props.showErrorAlert(errorMessage);
            });
        }
        //Contact needs to be created
        else if(this.state.mode === 'create'){

            APIWorker.createContact(json)
            .then(res => {
                let contactJson = {
                    "Id" : res.data,
                    "AccountId" : this.props.record.AccountId,
                    "FirstName" : this.state.firstName,
                    "LastName" : this.state.lastName,
                    "Email" : this.state.email
                };
                this.props.checkInAttendee(contactJson);
            })
            .catch(error => {
                console.log(error);
                var errorMessage = String(JSON.parse(error.response.data.message)[0].message).includes('unique_email__c') ? 'Email must be unique!' : null;
                this.props.showErrorAlert(errorMessage);
            });
        }
    }



    clearResults(){
        this.props.clearResults();
    }

    render(){
        return(
            <div>
                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.record.FirstName} {this.props.record.LastName}</b>
                                </h2>
                                <span>{this.props.record.Company_Name__c}</span>
                                <br/>                                
                            </div>
                            <div className="slds-media__body">

                                <Input
                                    label="First Name"
                                    placeholder="ex. John"
                                    type="text"
                                    onChange={(event, data) => {
                                        this.setState({
                                            firstName : data.value
                                        });
                                    }}
                                    value={this.state.firstName}
                                    disabled={true}
                                    minLength="1"
                                    required={true}
                                />

                                <Input
                                    label="Last Name"
                                    placeholder="ex. Smith"
                                    type="text"
                                    errorText={this.state.nameErrorMessage}
                                    onChange={(event, data) => {
                                        this.setState({
                                            lastName : data.value
                                        });
                                    }}
                                    value={this.state.lastName}
                                    disabled={true}
                                    minLength="1"
                                    required={true}
                                />

                                <Input
                                    label="Email"
                                    placeholder="ex. john@gmail.com"
                                    type="text"
                                    errorText={this.state.emailErrorMessage}
                                    onChange={(event, data) => {
                                        this.setState({
                                            email : data.value
                                        });
                                    }}
                                    required={this.state.requiresEmail}
                                    value={this.state.email}
                                />

                            </div>
                        </div>
                    </div>

                    <div style={{'textAlign' : 'center'}} className="slds-card__body slds-card__body_inner">
                        <Button onClick={() => this.validateAttendee()} variant="brand">Confirm Details</Button>
                        <Button onClick={() => this.clearResults()}>Cancel Check-In</Button>
                    </div>
                </article>
            </div>
        )
    }
}