import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';
import ManagementComponent from './ManagementComponent';

export default class ManagementModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            cardDetailId : this.props.cardDetailId,
            isLoading : false,
            data : [],
            cards : [],
            emptyArray : true
        }

        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.getAttendees = this.getAttendees.bind(this);
        this.updateAttendees = this.updateAttendees.bind(this);
        this.updateAttendee = this.updateAttendee.bind(this);
        this.generateLayout = this.generateLayout.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
    }

    componentDidMount(){
        this.getAttendees();
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    getAttendees(){
        this.showSpinner();
        APIWorker.getCheckedAttendees(this.state.cardDetailId)
        .then(res => {
            this.setState({
                data : res.data,
                emptyArray : res.data.length === 0 ? true : false
            },
                () => {
                    this.generateLayout();
                    this.hideSpinner();
                }
            )
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    //updateAttendee pass function to the child component which in turn sends the data back to the parent and update the specific record in array
    updateAttendee(id, theoryStatus, theoryExamResults, doNotSyncCRC){
        this.setState({
            data: this.state.data.map(attendee => (attendee.Id === id ? {...attendee, Theory_Status__c : theoryStatus, Theory_Exam_Results__c : theoryExamResults, Do_Not_Sync_With_CRC__c : doNotSyncCRC} : attendee))
        });

        this.generateLayout();
    }

    updateAttendees(){
        this.showSpinner();

        //Loop through data that is up to date and update each attendee, see if forrest supports batch data
        this.state.data.map((attendee, i) => {
            let json = {
                "Theory_Status__c" : attendee.Theory_Status__c,
                "Theory_Exam_Results__c" : attendee.Theory_Exam_Results__c,
                "Do_Not_Sync_With_CRC__c" : attendee.Do_Not_Sync_With_CRC__c
            };

            return APIWorker.updateAttendee(attendee.Id, json)
            .then(res => {
                //On the last attendee update, hide spinner and close modal. Add 1.5 seconds of delay as attendee records may not be updated by modal render
                if(this.state.data.map.length === i + 1){
                    setTimeout(() => {
                        this.hideSpinner();
                        this.context.changeModal('');
                        this.context.showSuccessAlert('Attendee Theory Statuses submitted!');
                    }, 1500);
                }
            })
            .catch(error =>{
                this.showErrorAlert();
            });
        });
    }

    generateLayout(){
        let cards = [];

        this.state.data.map((attendee) => {
            return cards.push(
                <ManagementComponent updateAttendee={this.updateAttendee} key={attendee.Id} record={attendee} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner}></ManagementComponent>
            );
        });

        if(this.state.emptyArray){
            cards.push(
                <section className="slds-p-around_medium">There are 0 checked in attendees! Use the Check-In tool before proceeding.</section>
            );
        }

        this.setState({
            cards
        });
    }

    render(){
        return(
            <div>

                <Modal isOpen={true} heading="Attendee Management" ariaHideApp={false} align="center" size="large" 
                footer={[
                    <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                    <Button key="2" disabled={this.state.emptyArray} label="Save" onClick={() => this.updateAttendees()} variant="brand"/>
                ]}>
                    {this.state.cards}
                </Modal>   
            </div>
        )
    }

}