import React from "react";
import Login from "./Login";

import TrainingV1 from "./v1/Training";
import TrainingV2 from "./v2/Training";

import { AppContext } from "./AppProvider";

const CACHE_NAME = "trainerAppAuth";
const TOKEN_KEY = "tokenV2";
const ENDPOINT = "/auth";

const apiVersions = {
  v1: TrainingV1,
  v2: TrainingV2,
};

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingLoad: true,
      pendingLogin: true,
      appName: "Training App",
      appVersion: "v1",
      userEmail: null,
      userId: null,
      loginUserId: null,
      userProps: {},
      logout: this.logout.bind(this),
    };

    this.isCacheSupported = "caches" in window;
    // alert(this.isCacheSupported);
    this.setUser = this.setUser.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (this.isCacheSupported) {
      this.displayCachedToken().then((res) => {
        let loginUserId = null;
        let userEmail = null;
        console.log("res", res);
        if (res) {
          loginUserId = res.split("-")[0];
          userEmail = res.split("-")[1];
        }

        this.setState({
          pendingLoad: false,
          loginUserId,
          userEmail,
        });
      });

      return;
    }

    this.setState({
      pendingLoad: false,
    });
  }

  setUser(userEmail, userId, userProps) {
    this.setState({
      userEmail,
      userId,
      userProps,
      appVersion: userProps.app_version,
      pendingLoad: false,
      pendingLogin: false,
    });

    if (this.isCacheSupported) {
      this.saveToken(userProps.Id + "-" + userEmail);
    }
  }

  logout() {
    if (this.isCacheSupported) {
      this.deleteToken();
    }

    window.location.reload(true);
  }

  saveToken = async (token) => {
    try {
      const cache = await caches.open(CACHE_NAME);
      const responseBody = JSON.stringify({
        [TOKEN_KEY]: token,
      });
      const response = new Response(responseBody);
      await cache.put(ENDPOINT, response);
    } catch (error) {
      console.log("saveToken error:", { error });
    }
  };

  getToken = async () => {
    try {
      const cache = await caches.open(CACHE_NAME);
      const response = await cache.match(ENDPOINT);

      if (!response) {
        return null;
      }

      const responseBody = await response.json();
      return responseBody[TOKEN_KEY];
    } catch (error) {
      console.log("getToken error:", { error });
    }
  };

  deleteToken = async () => {
    try {
      const cache = await caches.open(CACHE_NAME);
      const response = await cache.delete(ENDPOINT);

      const responseBody = await response;
      return responseBody;
    } catch (error) {
      console.log("deleteToken error:", { error });
    }
  };

  displayCachedToken = async () => {
    const cachedToken = await this.getToken();
    return cachedToken;
  };

  render() {
    if (this.state.pendingLoad) {
      return null;
    }

    // console.log("this.state", this.state);

    if (this.state.pendingLogin) {
      return (
        <Login
          setUser={this.setUser}
          userId={this.state.loginUserId}
          userEmail={this.state.userEmail}
        />
      );
    }

    // return "OK";

    // console.log("VVVVVVVVV", this.state.appVersion);
    let trainingClass = apiVersions[this.state.appVersion]
      ? apiVersions[this.state.appVersion]
      : apiVersions["v1"];

    let trainingInstance = React.createElement(trainingClass, {
      userId: this.state.userId,
    });
    // console.log("userProps", this.state.userProps);
    // console.log("apiClasses", apiClasses);

    return <AppContext.Provider value={this.state}>{trainingInstance}</AppContext.Provider>;
  }
}
