import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';
import CheckInComponent from './CheckInComponent';
import ConfirmCheckInComponent from './ConfirmCheckInComponent';

export default class CheckInModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            cardDetailId : this.props.cardDetailId,
            attendeeId : '',
            isLoading : false,
            data : [],
            cards : [],
            showPrompt : false
        }

        this.refreshData = this.refreshData.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.generateLayout = this.generateLayout.bind(this);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.showPrompt = this.showPrompt.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
    }

    componentDidMount(){
        this.refreshData();
    }

    componentDidUpdate(oldProps){
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    toggleDetails(attendeeId){
        this.setState({
            attendeeId
        },
            () => {
                this.refreshData();
            }
        );
    }

    refreshData(){
        this.showSpinner();

        if(this.state.attendeeId !== ''){
            APIWorker.getAttendee(this.state.attendeeId)
            .then(res => {
                this.setState({
                    data : res.data
                },
                    () => {
                        this.generateLayout();
                        this.hideSpinner();
                    }
                );
            })
            .catch(error => {
                this.showErrorAlert();
            });
        }
        else{
            APIWorker.getConfirmedAttendees(this.state.cardDetailId)
            .then(res => {
                this.setState({
                    data : res.data
                },
                    () => {
                        this.generateLayout();
                        this.hideSpinner();
                    }
                );
            })
            .catch(error => {
                this.showErrorAlert();
            });
        }   
    }

    showPrompt(){
        this.setState({
            showPrompt : true
        });

        setTimeout(() => {
            this.setState({
                showPrompt : false
            });
        }, 2500);
    }

    generateLayout(){
        let cards = [];

        if(this.state.attendeeId !== ''){
            this.state.data.map((attendee) => {
                return cards.push(
                    <ConfirmCheckInComponent showPrompt={this.showPrompt} toggleDetails={this.toggleDetails} key={attendee.Id} record={attendee} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} showErrorAlert={this.showErrorAlert}></ConfirmCheckInComponent>
                );
            });
        }
        else{
            this.state.data.map((attendee) => {
                return cards.push(
                    <CheckInComponent toggleDetails={this.toggleDetails} key={attendee.Id} record={attendee} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} refreshData={this.refreshData}></CheckInComponent>
                );
            });
        }

        this.setState({
            cards
        });
    }

    render(){
        return (
            <div>

                <Modal isOpen={true} heading="Attendee Check-In" ariaHideApp={false} align="center" size="large" 
                footer={[
                    <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                ]}>
                    <Modal disableClose ariaHideApp={false} isOpen={this.state.showPrompt} prompt="success" size="medium" heading="Attendee Check-In">
						<div className="slds-m-around_medium">
							Attendee is successfully checked in!
						</div>
					</Modal>

                    {this.state.cards}
                </Modal>   
            </div>
        
        )
    }

}