import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Input from '@salesforce/design-system-react/lib/components/input';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';
import React from 'react';

const options = [
    {
        id: 'Passed',
        label: 'Passed',
    },
    {
        id: 'Failed',
        label: 'Failed',
    }
];

export default class ManagementComponent extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isCRC : (this.props.record.Service_Appointment__r.Course_Product_Line__c.includes('CPR-C')) ? true : false,
            doNotSyncWithCRC : this.props.record.Do_Not_Sync_With_CRC__c,
            theoryStatus : this.props.record.Theory_Status__c,
            theoryExamResults : this.props.record.Theory_Exam_Results__c !== null ? this.props.record.Theory_Exam_Results__c : '',
            selection : this.props.record.Theory_Status__c !== 'Pending' ? [{id:this.props.record.Theory_Status__c, label:this.props.record.Theory_Status__c}] : []
        }
        
        this.recordUpdated = this.recordUpdated.bind(this);
    }

    componentDidUpdate(){

    }

    recordUpdated(theoryStatus, theoryExamResults, doNotSyncCRC){
        this.props.updateAttendee(this.props.record.Id, theoryStatus, theoryExamResults, doNotSyncCRC);
    }


    render(){
        return(
            <div class="slds-p-around_x-small">
                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.record.Name}</b>
                                </h2>
                                <span>{this.props.record.Company__c}</span>
                            </div>
                            <div className="slds-media__body">
                                <Combobox
                                    events={{
                                        onSelect : (event, data) => {
                                            //If the selection is not different, it won't be populated
                                            if(data.selection.length > 0){
                                                this.setState({
                                                    selection : [{id:data.selection[0].id, label:data.selection[0].id}],
                                                    theoryStatus : data.selection[0].id
                                                });
                                                this.recordUpdated(data.selection[0].id, this.state.theoryExamResults, this.state.doNotSyncWithCRC);    
                                            }
                                        }
                                    }}
                                    labels={{
                                        label: 'Theory Status'
                                    }}
                                    selection={this.state.selection}
                                    value={this.state.theoryStatus}
                                    options={options}
                                    variant="readonly"
                                />

                                {
                                    (this.state.isCRC === true && this.state.theoryStatus === 'Passed') ? (
                                        <div class="slds-p-around_xx-small">

                                            <Checkbox
                                                id={`doNotSyncCRC-` + this.props.record.Id}
                                                key="doNotSyncCRC"
                                                labels={{
                                                    label: 'Do Not Sync Attendee With CRC?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.doNotSyncWithCRC}
                                                onChange={() => {
                                                    this.setState({
                                                        doNotSyncWithCRC : !this.state.doNotSyncWithCRC
                                                    }, 
                                                        () => {
                                                            this.recordUpdated(this.state.theoryStatus, this.state.theoryExamResults, this.state.doNotSyncWithCRC);    
                                                        }
                                                    );
                                                }}
                                                variant="toggle"
                                            />
                                        </div>
                                    ) :
                                    (
                                        ''
                                    )
                                }

                                <Input
                                    label="Exam Results"
                                    placeholder="ex. 85%"
                                    type="text"
                                    onChange={(event, data) => {
                                        this.setState({
                                            theoryExamResults : data.value
                                        });
                                        this.recordUpdated(this.state.theoryStatus, data.value);
                                    }}
                                    value={this.state.theoryExamResults}
                                />                        

                            </div>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}