import React from "react";
import { FooterContext } from "../TrainingProvider";
import Accordion from "@salesforce/design-system-react/lib/components/accordion";
import AccordionPanel from "@salesforce/design-system-react/lib/components/accordion/panel";
import IconSettings from "@salesforce/design-system-react/lib/components/icon-settings";

export default class AttendeeCard extends React.Component {
  static contextType = FooterContext;

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      show: true,
      expandedPanels: {},
      checkInTime:
        this.props.record.Check_in_Time__c !== null
          ? new Date(this.props.record.Check_in_Time__c.substr(0, 19) + "Z")
          : null,
      lastSendDate:
        this.props.record.Send_Date__c !== null
          ? new Date(this.props.record.Send_Date__c.substr(0, 19) + "Z")
          : null,
    };
  }

  componentDidMount() {
    this.context.changeFooter("");
    if (this.props.record.Service_Appointment__r.Product_Family__c === "Courses") {
      if (this.props.record.Service_Appointment__r.Has_SkillsPass_Key__c) {
        this.context.changeFooter("AttendeeSkillsPass");
      }
    } else if (this.props.record.Service_Appointment__r.Product_Family__c === "Evaluations") {
      this.context.changeFooter("AttendeeAddEval");
    }
  }

  togglePanel(event, data) {
    let toggle = true;
    if (this.state.expandedPanels[data] === true) {
      toggle = false;
    }

    this.setState(
      {
        expandedPanels: {},
      },
      () => {
        this.setState((state) => ({
          expandedPanels: {
            [data]: toggle,
          },
        }));
      }
    );
  }

  //this.context.changeFooter either uses Home if showingDetails or props.Object if not
  changeContext(action) {
    this.context.changeFooter(action);
    this.props.toggleDetails(this.props.record.Service_Appointment__c);
  }

  render() {
    if (!this.state.show) return null;

    return (
      <article className="slds-card">
        <div className="slds-card__header slds-grid">
          <div className="slds-media slds-media_center slds-has-flexi-truncate">
            <div
              className="slds-media__figure"
              onClick={() => this.changeContext(this.props.Action)}
            >
              <span
                className={`slds-icon_container slds-icon_container--circle slds-icon-action-${this.props.Icon}`}
                title="account"
              >
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use
                    xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#${this.props.SVGSymbol}`}
                  ></use>
                </svg>
              </span>
            </div>
            <div className="slds-media__body">
              <h2 className="slds-card__header-title">
                <b>{this.props.record.Name}</b>
              </h2>
              <span>{this.props.record.Company__c}</span>
            </div>
          </div>
        </div>

        <div className="slds-card__body slds-card__body_inner">
          {"Course: " + this.props.record.SA_Product_Name__c}
        </div>
        <div className="slds-card__body slds-card__body_inner">
          {"Email: " + this.props.record.Email__c}
        </div>
        <div className="slds-card__body slds-card__body_inner">
          {"Consent Form Filled: " + this.props.record.Contact__r.Consent_Form_Filled__c}
        </div>
        <div className="slds-card__body slds-card__body_inner">
          {"Requires Eval: " + this.props.record.Requires_Evaluation__c}
        </div>
        <div className="slds-card__body slds-card__body_inner">
          {"Account Owner: " + this.props.record.Account_Owner__c}
        </div>
        <div className="slds-card__body slds-card__body_inner">
          {this.state.lastSendDate
            ? "Last Email Date: " +
              this.state.lastSendDate.toDateString() +
              " " +
              this.state.lastSendDate.toLocaleTimeString()
            : ""}
        </div>

        <IconSettings iconPath="/assets/icons">
          <Accordion>
            <AccordionPanel
              expanded={!!this.state.expandedPanels[1]}
              id={1}
              key={1}
              onTogglePanel={(event) => this.togglePanel(event, 1)}
              summary="Service Appointment Details"
            >
              <div className="slds-card__body slds-card__body_inner">
                {"Product Name: " + this.props.record.SA_Product_Name__c}
              </div>
              <div className="slds-card__body slds-card__body_inner">
                {this.state.checkInTime
                  ? "Check-In Time: " +
                    this.state.checkInTime.toDateString() +
                    " " +
                    this.state.checkInTime.toLocaleTimeString()
                  : ""}
              </div>
              <div className="slds-card__body slds-card__body_inner">
                {"Status: " + this.props.record.Status__c}
              </div>
              <div className="slds-card__body slds-card__body_inner">
                {"Confirmed: " + this.props.record.Confirmed__c}
              </div>
              <div className="slds-card__body slds-card__body_inner">
                {"Theory Status: " + this.props.record.Theory_Status__c}
              </div>
              <div className="slds-card__body slds-card__body_inner">
                {"Exam Results: " + (this.props.record.Theory_Exam_Results__c || "NO RESULTS")}
              </div>
            </AccordionPanel>
          </Accordion>
        </IconSettings>
      </article>
    );
  }
}
