import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.min.css";
import IconSettings from "@salesforce/design-system-react/lib/components/icon-settings";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
<IconSettings iconPath="/assets/icons">
  <App />
</IconSettings>
);

/*
render(
  <IconSettings iconPath="/assets/icons">
    <App />
  </IconSettings>,
  document.getElementById("root")
);
*/

serviceWorker.unregister();
