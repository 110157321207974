import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Button from '@salesforce/design-system-react/lib/components/button';
import Input from '@salesforce/design-system-react/lib/components/input';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Accordion from '@salesforce/design-system-react/lib/components/accordion'; 
import AccordionPanel from '@salesforce/design-system-react/lib/components/accordion/panel';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Dropdown from '@salesforce/design-system-react/lib/components/menu-dropdown'; 
import APIWorker from '../APIWorker';

const options = [
    {
        id: 'Yes',
        label: 'Yes',
    },
    {
        id: 'No',
        label: 'No',
    },
    {
        id: 'N/A',
        label: 'N/A'
    }
];

const statusOptions = [
    {
        id: 'Pass',
        label: 'Pass'
    },
    {
        id: 'Fail',
        label: 'Fail'
    }
]

const fuelSources = [
    {
        id: 'Propane',
        label: 'Propane'
    },
    {
        id: 'Electric',
        label: 'Electric'
    },
    {
        id: 'Gas/Diesel',
        label: 'Gas/Diesel'
    }
]

export default class AerialLiftForm extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            evalDeviceList: [],
            selectedDevices: [],
            selectedDevicesString: '',
            deviceListErrorMessage: '',
            liftCapacity: '',
            liftCapacityErrorMessage: '',
            selectedFuelSources: [],
            selectedFuelSourcesString: '',
            fuelSourceErrorMessage: '',
            status: '',
            statusErrorMessage: '',
            failureReason: '',
            failureReasonErrorMessage : '',
            evaluationComments: '',
            commentErrorMessage : '',
            recordTypeId: '',
            answer1 : 'Yes',
            answer2: 'Yes',
            answer3: 'Yes',
            answer4: 'Yes',
            answer5: 'Yes',
            answer6: 'Yes',
            answer7: 'Yes',
            answer8: 'Yes',
            answer9: 'Yes',
            answer10: 'Yes',
            answer11: 'Yes',
            answer12: 'Yes',
            answer13: 'Yes',
            answer14: 'Yes',
            answer15: 'Yes',
            answer16: 'Yes',
            answer17: 'Yes',
            answer18: 'Yes',
            answer19: 'Yes',
            answer20: 'Yes',
            answer21: 'Yes',
            answer22: 'Yes',
            answer23: 'Yes',
            answer24: 'Yes'
        }

        this.validateInformation = this.validateInformation.bind(this);
        this.submitEvaluation = this.submitEvaluation.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount(){
        //Populate the devices from the eval product
        var productDeviceList = (this.props.sa.Service_Appointment__r.Product__r.Devices__c).split(';');
        var evalDeviceList = [];
        productDeviceList.map((device) => {
            return evalDeviceList.push(
                {id: device, label:device}
            );
        });

        //retrieve the record type id
        let json = {
            "EvalRecordTypeName" : this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c
        };

        APIWorker.getEvaluationRecordType(json)
        .then(res => {
            var recordTypeId = res.data[0].Id;
            this.setState({
                recordTypeId,
                evalDeviceList
            })
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    clearErrors(){
        this.setState({
            commentErrorMessage : '',
            failureReasonErrorMessage: '',
            statusErrorMessage : '',
            deviceListErrorMessage: '',
            fuelSourceErrorMessage: '',
            liftCapacityErrorMessage : ''
        });
    }

    validateInformation(){
        this.showSpinner();
        this.clearErrors();

        //If any answer set to No, require an evaluation comment
        for(var i = 1; i < 25; i++){
            var answerName = 'answer' + i;
            if(this.state[answerName] === 'No' && this.state.evaluationComments === ''){
                this.setState({
                    commentErrorMessage : 'Evaluation comments required if any criteria was set to "No"!'
                });
                this.hideSpinner();
                return;
            }
        }

        if(this.state.status === ''){
            this.setState({
                statusErrorMessage : 'Need to set either pass or fail for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //If evaluation set to fail, failure reason is required
        if(this.state.status === 'Fail' && this.state.failureReason === ''){
            this.setState({
                failureReasonErrorMessage : 'Failure reason required if evaluation set to "Fail"!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedDevicesString === ''){
            this.setState({
                deviceListErrorMessage : 'Need to select device(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedFuelSourcesString === ''){
            this.setState({
                fuelSourceErrorMessage : 'Need to select fuel source(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.liftCapacity === ''){
            this.setState({
                liftCapacityErrorMessage : 'Need to set a lift capacity for each device for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //if all criteria met, submitEvaluation
        this.submitEvaluation();
    }

    submitEvaluation(){
        let json = {
            "RecordTypeId" : this.state.recordTypeId,
            "Status__c" : this.state.status,
            "Reason_for_Failure__c" : this.state.failureReason,
            "Evaluation_Comments__c" : this.state.evaluationComments,
            "Service_Appointment__c" : this.props.sa.Service_Appointment__c,
            "Attendee__c" : this.props.sa.Id,
            "Attendee_Contact__c" : this.props.sa.Contact__c,
            "Lift_Device__c" : this.state.selectedDevicesString,
            "Fuel_Source__c" : this.state.selectedFuelSourcesString,
            "Lift_Device_Capacity__c" : this.state.liftCapacity,

            "Pre_operational_Check_Visual__c" : this.state.answer1,
            "Pre_operational_Check_Practical__c" : this.state.answer2,
            "Parking_Procedure__c" : this.state.answer3,
            "Breakdown_Procedure__c" : this.state.answer4,
            "Demonstrates_good_judgment__c" : this.state.answer5,
            "Performs_safely_around_pedestrians__c" : this.state.answer6,
            "Sounds_horn_at_intersections_and_corners__c" : this.state.answer7,
            "Travels_w_lift_platform_in_proper_pos__c" : this.state.answer8,
            "Uses_controls_properly_with_confidence__c" : this.state.answer9,
            "Smooth_starts_and_stops__c" : this.state.answer10,
            "Looks_over_both_shoulders_reversing__c" : this.state.answer11,
            "Clears_obstacles_safely__c" : this.state.answer12,
            "Smooth_range_of_motion_no_jerky_motions__c" : this.state.answer13,
            "Comfortable_with_the_controls__c" : this.state.answer14,
            "Clears_obstructions_raising_lowering__c" : this.state.answer15,
            "Knowledge_of_needed_equipment__c" : this.state.answer16,
            "Proper_donning_of_fall_protection__c" : this.state.answer17,
            "Proper_anchorage_of_fall_protection__c" : this.state.answer18,
            "Cleaning_and_storage_of_PPE__c" : this.state.answer19,
            "Operates_safely_on_ramps__c" : this.state.answer20,
            "Wears_appropriate_PPE__c" : this.state.answer21,
            "Proper_procedure_for_gas_or_diesel__c" : this.state.answer22,
            "Proper_procedure_for_electrical_vehicles__c" : this.state.answer23,
            "Proper_procedure_for_propane_vehicles__c" : this.state.answer24
        };

        APIWorker.createEvaluation(json)
        .then(res => {
            let attendeeJson = {
                "Status__c" : "Confirmed",
                "Check_in_Time__c" : new Date().toISOString()
            };

            APIWorker.updateAttendee(this.props.attendeeId, attendeeJson)
            .then(res => {
                this.hideSpinner();
                this.closeModal();
                this.context.showSuccessAlert('Aerial Lift Evaluation submitted!');
            })
            .catch(error => {
                this.showErrorAlert();
            });
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    closeModal(){
        this.context.changeModal('');
    }

    render(){
        return(
            <div>
                <article className="slds-card slds-align_absolute-center">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.sa.Contact__r.FirstName} {this.props.sa.Contact__r.LastName}</b>
                                </h2>
                                <span>{this.props.sa.Company__c}</span>
                            </div>  
                        </div>
                    </div>
                </article>

                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">                         
                            <div className="slds-media__body">
                                <IconSettings iconPath="/assets/icons">
                                    <Accordion>
                                        <AccordionPanel expanded={true} id={0} key={0} summary="Device Details">

                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedDevicesString = '';
                                                        data.selection.map((device) => {
                                                            selectedDevicesString += (device.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedDevices: data.selection,
                                                            selectedDevicesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Device(s)'
                                                }}
                                                multiple
                                                errorText={this.state.deviceListErrorMessage}
                                                options={this.state.evalDeviceList}
                                                selection={this.state.selectedDevices}
                                                variant="readonly"
                                            />               

                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedFuelSourcesString = '';
                                                        data.selection.map((fuelSource) => {
                                                            selectedFuelSourcesString += (fuelSource.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedFuelSources: data.selection,
                                                            selectedFuelSourcesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Fuel Source(s)'
                                                }}
                                                multiple
                                                errorText={this.state.fuelSourceErrorMessage}
                                                options={fuelSources}
                                                selection={this.state.selectedFuelSources}
                                                variant="readonly"
                                            />

                                            <Input
                                                label="Lift Capacity"
                                                placeholder="ex. 1000 lbs"
                                                type="text"
                                                errorText={this.state.liftCapacityErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        liftCapacity : data.value
                                                    });
                                                }}
                                                value={this.state.liftCapacity}
                                            />
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={1} key={1} summary="Inspections and Procedures">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer1 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '1. Pre-operational Check - Visual'
                                                }}
                                                selection={[{id:this.state.answer1, label:this.state.answer1}]}
                                                value={this.state.answer1}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer2 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '2. Pre-operational Check - Practical'
                                                }}
                                                selection={[{id:this.state.answer2, label:this.state.answer2}]}
                                                value={this.state.answer2}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer3 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '3. Parking Procedure'
                                                }}
                                                selection={[{id:this.state.answer3, label:this.state.answer3}]}
                                                value={this.state.answer3}
                                                options={options}
                                                variant="readonly"
                                            />
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={2} key={2} summary="Work Area Protection">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer4 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '4. Breakdown Procedure'
                                                }}
                                                selection={[{id:this.state.answer4, label:this.state.answer4}]}
                                                value={this.state.answer4}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                               
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={3} key={3} summary="Observations of Practices">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer5 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '5. Demonstrates good judgment'
                                                }}
                                                selection={[{id:this.state.answer5, label:this.state.answer5}]}
                                                value={this.state.answer5}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer6 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '6. Performs safely around pedestrians'
                                                }}
                                                selection={[{id:this.state.answer6, label:this.state.answer6}]}
                                                value={this.state.answer6}
                                                options={options}
                                                variant="readonly"
                                            />                            

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer7 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '7. Sounds horn at intersections and corners'
                                                }}
                                                selection={[{id:this.state.answer7, label:this.state.answer7}]}
                                                value={this.state.answer7}
                                                options={options}
                                                variant="readonly"
                                            />    

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer8 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '8. Travels with lift platform in proper position.'
                                                }}
                                                selection={[{id:this.state.answer8, label:this.state.answer8}]}
                                                value={this.state.answer8}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer9 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '9. Uses controls properly & with confidence'
                                                }}
                                                selection={[{id:this.state.answer9, label:this.state.answer9}]}
                                                value={this.state.answer9}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                         
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={4} key={4} summary="Maneuvering Skills - Forward & Reverse">        
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer10 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '10. Smooth starts and stops'
                                                }}
                                                selection={[{id:this.state.answer10, label:this.state.answer10}]}
                                                value={this.state.answer10}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer11 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '11. Looks over both shoulders reversing'
                                                }}
                                                selection={[{id:this.state.answer11, label:this.state.answer11}]}
                                                value={this.state.answer11}
                                                options={options}
                                                variant="readonly"
                                            />    

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer12 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '12. Clears obstacles safely'
                                                }}
                                                selection={[{id:this.state.answer12, label:this.state.answer12}]}
                                                value={this.state.answer12}
                                                options={options}
                                                variant="readonly"
                                            />    
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={5} key={5} summary="Lifting/Loweing">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer13 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '13. Smooth range of motion, no jerky motions'
                                                }}
                                                selection={[{id:this.state.answer13, label:this.state.answer13}]}
                                                value={this.state.answer13}
                                                options={options}
                                                variant="readonly"
                                            />            

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer14 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '14. Comfortable with the controls'
                                                }}
                                                selection={[{id:this.state.answer14, label:this.state.answer14}]}
                                                value={this.state.answer14}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer15 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '15. Clears obstructions raising & lowering'
                                                }}
                                                selection={[{id:this.state.answer15, label:this.state.answer15}]}
                                                value={this.state.answer15}
                                                options={options}
                                                variant="readonly"
                                            />                                           
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={6} key={6} summary="Protective Equipment">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer16 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '16. Knowledge of needed equipment'
                                                }}
                                                selection={[{id:this.state.answer16, label:this.state.answer16}]}
                                                value={this.state.answer16}
                                                options={options}
                                                variant="readonly"
                                            />       

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer17 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '17. Proper donning of fall protection'
                                                }}
                                                selection={[{id:this.state.answer17, label:this.state.answer17}]}
                                                value={this.state.answer17}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer18 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '18. Proper anchorage of fall protection'
                                                }}
                                                selection={[{id:this.state.answer18, label:this.state.answer18}]}
                                                value={this.state.answer18}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer19 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '19. Cleaning and storage of PPE'
                                                }}
                                                selection={[{id:this.state.answer19, label:this.state.answer19}]}
                                                value={this.state.answer19}
                                                options={options}
                                                variant="readonly"
                                            />                                          
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={7} key={7} summary="Miscellaneous (if applicable)">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer20 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '20. Operates safely on ramps'
                                                }}
                                                selection={[{id:this.state.answer20, label:this.state.answer20}]}
                                                value={this.state.answer20}
                                                options={options}
                                                variant="readonly"
                                            />                                           
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={8}key={8} summary="Fuel Sources">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer21 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '21. Wears appropriate PPE'
                                                }}
                                                selection={[{id:this.state.answer21, label:this.state.answer21}]}
                                                value={this.state.answer21}
                                                options={options}
                                                variant="readonly"
                                            /> 

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer22 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '22. Proper procedure for gas or diesel'
                                                }}
                                                selection={[{id:this.state.answer22, label:this.state.answer22}]}
                                                value={this.state.answer22}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer23 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '23. Proper procedure for electrical vehicles'
                                                }}
                                                selection={[{id:this.state.answer23, label:this.state.answer23}]}
                                                value={this.state.answer23}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer24 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '24. Proper procedure for propane vehicles'
                                                }}
                                                selection={[{id:this.state.answer24, label:this.state.answer24}]}
                                                value={this.state.answer24}
                                                options={options}
                                                variant="readonly"
                                            />                                        
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={9} key={9} summary="Evaluation Status">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        if(data.selection.length > 0){
                                                            var failureReason = this.state.failureReason;
                                                            if(data.selection[0].id == 'Pass'){
                                                                failureReason = '';
                                                            }
                                                            this.setState({
                                                                status : data.selection[0].id,
                                                                failureReason
                                                            });
                                                        }
                                                    }
                                                }}                                
                                                labels={{
                                                    label: 'Status (Pass/Fail)'
                                                }}
                                                errorText={this.state.statusErrorMessage}
                                                selection={[{id:this.state.status, label:this.state.status}]}
                                                value={this.state.status}
                                                options={statusOptions}
                                                variant="readonly"
                                            />
                                            
                                            {
                                                this.state.status == 'Fail' &&
                                                <Input
                                                    label="Reason for Failure"
                                                    placeholder="ex. Failure reason"
                                                    type="text"
                                                    errorText={this.state.failureReasonErrorMessage}
                                                    onChange={(event,data) => {
                                                        this.setState({
                                                            failureReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.failureReason}
                                                />
                                            }

                                            <Input
                                                label="Evaluation comments"
                                                placeholder="ex. comments"
                                                type="text"
                                                errorText={this.state.commentErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        evaluationComments : data.value
                                                    });
                                                }}
                                                value={this.state.evaluationComments}
                                            />
                                        </AccordionPanel>                                           
                                    </Accordion>
                                </IconSettings>
                                                                                                                                                   

                            </div>
                        </div>
                    </div>
                </article>

                <article className="slds-card slds-align_absolute-center">
                    <Button key="1" variant="brand" label="Submit Evaluation" onClick={() => this.validateInformation()}/>
                </article>
            </div>            
        )
    }
}