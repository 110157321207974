import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Button from '@salesforce/design-system-react/lib/components/button';
import Input from '@salesforce/design-system-react/lib/components/input';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Accordion from '@salesforce/design-system-react/lib/components/accordion'; 
import AccordionPanel from '@salesforce/design-system-react/lib/components/accordion/panel';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Dropdown from '@salesforce/design-system-react/lib/components/menu-dropdown'; 
import APIWorker from '../APIWorker';

const options = [
    {
        id: 'Yes',
        label: 'Yes',
    },
    {
        id: 'No',
        label: 'No',
    },
    {
        id: 'N/A',
        label: 'N/A'
    }
];

const statusOptions = [
    {
        id: 'Pass',
        label: 'Pass'
    },
    {
        id: 'Fail',
        label: 'Fail'
    }
]

const fuelSources = [
    {
        id: 'Propane',
        label: 'Propane'
    },
    {
        id: 'Electric',
        label: 'Electric'
    },
    {
        id: 'Gas/Diesel',
        label: 'Gas/Diesel'
    }
]

export default class BucketTruckForm extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            evalDeviceList: [],
            selectedDevices: [],
            selectedDevicesString: '',
            deviceListErrorMessage: '',
            liftCapacity: '',
            liftCapacityErrorMessage: '',
            selectedFuelSources: [],
            selectedFuelSourcesString: '',
            fuelSourceErrorMessage: '',
            status: '',
            statusErrorMessage: '',
            failureReason: '',
            failureReasonErrorMessage : '',
            evaluationComments: '',
            commentErrorMessage : '',
            recordTypeId: '',
            answer1 : 'Yes',
            answer2: 'Yes',
            answer3: 'Yes',
            answer4: 'Yes',
            answer5: 'Yes',
            answer6: 'Yes',
            answer7: 'Yes',
            answer8: 'Yes',
            answer9: 'Yes',
            answer10: 'Yes',
            answer11: 'Yes',
            answer12: 'Yes',
            answer13: 'Yes',
            answer14: 'Yes',
            answer15: 'Yes',
            answer16: 'Yes',
            answer17: 'Yes',
            answer18: 'Yes',
            answer19: 'Yes',
            answer20: 'Yes',
            answer21: 'Yes',
            answer22: 'Yes',
            answer23: 'Yes',
            answer24: 'Yes',
            answer25: 'Yes',
            answer26: 'Yes',
            answer27: 'Yes',
            answer28: 'Yes',
            answer29: 'Yes'
        }

        this.validateInformation = this.validateInformation.bind(this);
        this.submitEvaluation = this.submitEvaluation.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount(){
        //Populate the devices from the eval product
        var productDeviceList = (this.props.sa.Service_Appointment__r.Product__r.Devices__c).split(';');
        var evalDeviceList = [];
        productDeviceList.map((device) => {
            return evalDeviceList.push(
                {id: device, label:device}
            );
        });

        //retrieve the record type id
        let json = {
            "EvalRecordTypeName" : this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c
        };

        APIWorker.getEvaluationRecordType(json)
        .then(res => {
            var recordTypeId = res.data[0].Id;
            this.setState({
                recordTypeId,
                evalDeviceList
            })
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    clearErrors(){
        this.setState({
            commentErrorMessage : '',
            failureReasonErrorMessage: '',
            statusErrorMessage : '',
            deviceListErrorMessage: '',
            fuelSourceErrorMessage: '',
            liftCapacityErrorMessage : ''
        });
    }

    validateInformation(){
        this.showSpinner();
        this.clearErrors();

        //If any answer set to No, require an evaluation comment
        for(var i = 1; i < 30; i++){
            var answerName = 'answer' + i;
            if(this.state[answerName] === 'No' && this.state.evaluationComments === ''){
                this.setState({
                    commentErrorMessage : 'Evaluation comments required if any criteria was set to "No"!'
                });
                this.hideSpinner();
                return;
            }
        }

        if(this.state.status === ''){
            this.setState({
                statusErrorMessage : 'Need to set either pass or fail for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //If evaluation set to fail, failure reason is required
        if(this.state.status === 'Fail' && this.state.failureReason === ''){
            this.setState({
                failureReasonErrorMessage : 'Failure reason required if evaluation set to "Fail"!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedDevicesString === ''){
            this.setState({
                deviceListErrorMessage : 'Need to select device(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedFuelSourcesString === ''){
            this.setState({
                fuelSourceErrorMessage : 'Need to select fuel source(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.liftCapacity === ''){
            this.setState({
                liftCapacityErrorMessage : 'Need to set a lift capacity for each device for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //if all criteria met, submitEvaluation
        this.submitEvaluation();
    }

    submitEvaluation(){
        let json = {
            "RecordTypeId" : this.state.recordTypeId,
            "Status__c" : this.state.status,
            "Reason_for_Failure__c" : this.state.failureReason,
            "Evaluation_Comments__c" : this.state.evaluationComments,
            "Service_Appointment__c" : this.props.sa.Service_Appointment__c,
            "Attendee__c" : this.props.sa.Id,
            "Attendee_Contact__c" : this.props.sa.Contact__c,
            "Lift_Device__c" : this.state.selectedDevicesString,
            "Fuel_Source__c" : this.state.selectedFuelSourcesString,
            "Lift_Device_Capacity__c" : this.state.liftCapacity,

            "Inspects_harness_lanyard_shockabsorber__c" : this.state.answer1,
            "Proper_donning_fall_arrest_PPE_snug_fit__c" : this.state.answer2,
            "Wears_and_uses_appropriate_PPE__c" : this.state.answer3,
            "Checks_ground_surfaces_ready_to_support__c" : this.state.answer4,
            "Checks_ground_surfaces_hazards_removed__c" : this.state.answer5,
            "Ensures_no_overhead_obstructions__c" : this.state.answer6,
            "Clears_area_of_wrkrs_by_barricading_etc__c" : this.state.answer7,
            "Positions_vehicle_to_operate_safely__c" : this.state.answer8,
            "Engages_outriggers_using_pads_when_req__c" : this.state.answer9,
            "Establishes_safe_working_zone_around_veh__c" : this.state.answer10,
            "Uses_company_inspection_form__c" : this.state.answer11,
            "Visual_Inspection_Completed__c" : this.state.answer12,
            "Operational_Inspection_Completed__c" : this.state.answer13,
            "Operates_controls_approp_w_confidence__c" : this.state.answer14,
            "Uses_spotter_if_required__c" : this.state.answer15,
            "Requests_assistance_where_appropriate__c" : this.state.answer16,
            "Communicates_effectively_with_co_workers__c" : this.state.answer17,
            "Maintains_safe_working_dist_from_pwr__c" : this.state.answer18,
            "Does_not_perform_unsafe_acts_w_equipment__c" : this.state.answer19,
            "Looks_for_hazards_b4_moving_boom_bucket__c" : this.state.answer20,
            "Maintains_safe_dist_from_obstructions__c" : this.state.answer21,
            "Operates_eqp_w_smooth_actions_safe_speed__c" : this.state.answer22,
            "Recognizes_bucket_weight_cap_no_overload__c" : this.state.answer23,
            "Does_not_overextend_reach_or_climb_out__c" : this.state.answer24,
            "Climbs_using_3_point_contact__c" : this.state.answer25,
            "Enters_bucket_safely__c" : this.state.answer26,
            "Inspects_anchour_b4_connecting_lanyard__c" : this.state.answer27,
            "Ensures_established_working_zone_is_safe__c" : this.state.answer28,
            "Corrects_any_hazardous_conditions__c" : this.state.answer29
        };

        APIWorker.createEvaluation(json)
        .then(res => {
            let attendeeJson = {
                "Status__c" : "Confirmed",
                "Check_in_Time__c" : new Date().toISOString()
            };

            APIWorker.updateAttendee(this.props.attendeeId, attendeeJson)
            .then(res => {
                this.hideSpinner();
                this.closeModal();
                this.context.showSuccessAlert('Bucket Truck Evaluation submitted!');
            })
            .catch(error => {
                this.showErrorAlert();
            });
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    closeModal(){
        this.context.changeModal('');
    }

    render(){
        return(
            <div>
                <article className="slds-card slds-align_absolute-center">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.sa.Contact__r.FirstName} {this.props.sa.Contact__r.LastName}</b>
                                </h2>
                                <span>{this.props.sa.Company__c}</span>
                            </div>  
                        </div>
                    </div>
                </article>

                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">                         
                            <div className="slds-media__body">
                                <IconSettings iconPath="/assets/icons">
                                    <Accordion>
                                        <AccordionPanel expanded={true} id={0} key={0} summary="Device Details">

                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedDevicesString = '';
                                                        data.selection.map((device) => {
                                                            selectedDevicesString += (device.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedDevices: data.selection,
                                                            selectedDevicesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Device(s)'
                                                }}
                                                multiple
                                                errorText={this.state.deviceListErrorMessage}
                                                options={this.state.evalDeviceList}
                                                selection={this.state.selectedDevices}
                                                variant="readonly"
                                            />               

                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedFuelSourcesString = '';
                                                        data.selection.map((fuelSource) => {
                                                            selectedFuelSourcesString += (fuelSource.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedFuelSources: data.selection,
                                                            selectedFuelSourcesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Fuel Source(s)'
                                                }}
                                                multiple
                                                errorText={this.state.fuelSourceErrorMessage}
                                                options={fuelSources}
                                                selection={this.state.selectedFuelSources}
                                                variant="readonly"
                                            />

                                            <Input
                                                label="Lift Capacity"
                                                placeholder="ex. 1000 lbs"
                                                type="text"
                                                errorText={this.state.liftCapacityErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        liftCapacity : data.value
                                                    });
                                                }}
                                                value={this.state.liftCapacity}
                                            />
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={1} key={1} summary="Fall Protection">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer1 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '1. Inspects harness lanyard & shockabsorber'
                                                }}
                                                selection={[{id:this.state.answer1, label:this.state.answer1}]}
                                                value={this.state.answer1}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer2 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '2. Proper donning fall arrest PPE/snug fit'
                                                }}
                                                selection={[{id:this.state.answer2, label:this.state.answer2}]}
                                                value={this.state.answer2}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer3 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '3. Wears and uses appropriate PPE'
                                                }}
                                                selection={[{id:this.state.answer3, label:this.state.answer3}]}
                                                value={this.state.answer3}
                                                options={options}
                                                variant="readonly"
                                            />
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={2} key={2} summary="Work Area Protection">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer4 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '4. Checks ground surfaces-ready to support'
                                                }}
                                                selection={[{id:this.state.answer4, label:this.state.answer4}]}
                                                value={this.state.answer4}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer5 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '5. Checks ground surfaces - hazards removed'
                                                }}
                                                selection={[{id:this.state.answer5, label:this.state.answer5}]}
                                                value={this.state.answer5}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer6 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '6. Ensures no overhead obstructions'
                                                }}
                                                selection={[{id:this.state.answer6, label:this.state.answer6}]}
                                                value={this.state.answer6}
                                                options={options}
                                                variant="readonly"
                                            />                            

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer7 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '7. Clears area of workers by barricading, etc'
                                                }}
                                                selection={[{id:this.state.answer7, label:this.state.answer7}]}
                                                value={this.state.answer7}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                   
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={3} key={3} summary="Vehicle Setup">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer8 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '8. Positions vehicle to operate safely'
                                                }}
                                                selection={[{id:this.state.answer8, label:this.state.answer8}]}
                                                value={this.state.answer8}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer9 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '9. Engages outriggers using pads when required'
                                                }}
                                                selection={[{id:this.state.answer9, label:this.state.answer9}]}
                                                value={this.state.answer9}
                                                options={options}
                                                variant="readonly"
                                            />                                           
                                           
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer10 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '10. Establishes safe working zone around vehicle'
                                                }}
                                                selection={[{id:this.state.answer10, label:this.state.answer10}]}
                                                value={this.state.answer10}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={4} key={4} summary="Visual & Operational Inspection">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer11 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '11. Uses company inspection form'
                                                }}
                                                selection={[{id:this.state.answer11, label:this.state.answer11}]}
                                                value={this.state.answer11}
                                                options={options}
                                                variant="readonly"
                                            />    

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer12 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '12. Visual Inspection Completed'
                                                }}
                                                selection={[{id:this.state.answer12, label:this.state.answer12}]}
                                                value={this.state.answer12}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer13 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '13. Operational Inspection Completed'
                                                }}
                                                selection={[{id:this.state.answer13, label:this.state.answer13}]}
                                                value={this.state.answer13}
                                                options={options}
                                                variant="readonly"
                                            />   
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={5} key={5} summary="Task Performance">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer14 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '14. Operates controls appropriately & with confidence'
                                                }}
                                                selection={[{id:this.state.answer14, label:this.state.answer14}]}
                                                value={this.state.answer14}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer15 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '15. Uses spotter if required'
                                                }}
                                                selection={[{id:this.state.answer15, label:this.state.answer15}]}
                                                value={this.state.answer15}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer16 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '16. Requests assistance where appropriate'
                                                }}
                                                selection={[{id:this.state.answer16, label:this.state.answer16}]}
                                                value={this.state.answer16}
                                                options={options}
                                                variant="readonly"
                                            />       

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer17 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '17. Communicates effectively with co-workers'
                                                }}
                                                selection={[{id:this.state.answer17, label:this.state.answer17}]}
                                                value={this.state.answer17}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer18 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '18. Maintains safe working distance from power'
                                                }}
                                                selection={[{id:this.state.answer18, label:this.state.answer18}]}
                                                value={this.state.answer18}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer19 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '19. Does not perform unsafe acts with equipment'
                                                }}
                                                selection={[{id:this.state.answer19, label:this.state.answer19}]}
                                                value={this.state.answer19}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer20 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '20. Looks for hazards before moving boom/bucket'
                                                }}
                                                selection={[{id:this.state.answer20, label:this.state.answer20}]}
                                                value={this.state.answer20}
                                                options={options}
                                                variant="readonly"
                                            />   

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer21 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '21. Maintains safe distance from obstructions'
                                                }}
                                                selection={[{id:this.state.answer21, label:this.state.answer21}]}
                                                value={this.state.answer21}
                                                options={options}
                                                variant="readonly"
                                            /> 

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer22 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '22. Operates equipment with smooth actions & safe speed'
                                                }}
                                                selection={[{id:this.state.answer22, label:this.state.answer22}]}
                                                value={this.state.answer22}
                                                options={options}
                                                variant="readonly"
                                            />

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer23 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '23. Recognizes bucket weight capacity and does not overload'
                                                }}
                                                selection={[{id:this.state.answer23, label:this.state.answer23}]}
                                                value={this.state.answer23}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer24 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '24. Does not overextend reach or climb out'
                                                }}
                                                selection={[{id:this.state.answer24, label:this.state.answer24}]}
                                                value={this.state.answer24}
                                                options={options}
                                                variant="readonly"
                                            />                                         
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={6} key={6} summary="Safety Precautions">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer25 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '25. Climbs using 3 point contact'
                                                }}
                                                selection={[{id:this.state.answer25, label:this.state.answer25}]}
                                                value={this.state.answer25}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer26 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '26. Enters bucket safely'
                                                }}
                                                selection={[{id:this.state.answer26, label:this.state.answer26}]}
                                                value={this.state.answer26}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer27 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '27. Inspects anchour before connecting lanyard'
                                                }}
                                                selection={[{id:this.state.answer27, label:this.state.answer27}]}
                                                value={this.state.answer27}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer28 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '28. Ensures established working zone is safe'
                                                }}
                                                selection={[{id:this.state.answer28, label:this.state.answer28}]}
                                                value={this.state.answer28}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer29 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '29. Corrects any hazardous conditions'
                                                }}
                                                selection={[{id:this.state.answer29, label:this.state.answer29}]}
                                                value={this.state.answer29}
                                                options={options}
                                                variant="readonly"
                                            />                                           
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={7} key={7} summary="Evaluation Status">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        if(data.selection.length > 0){
                                                            var failureReason = this.state.failureReason;
                                                            if(data.selection[0].id == 'Pass'){
                                                                failureReason = '';
                                                            }
                                                            this.setState({
                                                                status : data.selection[0].id,
                                                                failureReason
                                                            });
                                                        }
                                                    }
                                                }}                                
                                                labels={{
                                                    label: 'Status (Pass/Fail)'
                                                }}
                                                errorText={this.state.statusErrorMessage}
                                                selection={[{id:this.state.status, label:this.state.status}]}
                                                value={this.state.status}
                                                options={statusOptions}
                                                variant="readonly"
                                            />
                                            
                                            {
                                                this.state.status == 'Fail' &&
                                                <Input
                                                    label="Reason for Failure"
                                                    placeholder="ex. Failure reason"
                                                    type="text"
                                                    errorText={this.state.failureReasonErrorMessage}
                                                    onChange={(event,data) => {
                                                        this.setState({
                                                            failureReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.failureReason}
                                                />
                                            }

                                            <Input
                                                label="Evaluation comments"
                                                placeholder="ex. comments"
                                                type="text"
                                                errorText={this.state.commentErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        evaluationComments : data.value
                                                    });
                                                }}
                                                value={this.state.evaluationComments}
                                            />
                                        </AccordionPanel>                                           
                                    </Accordion>
                                </IconSettings>
                                                                                                                                                   

                            </div>
                        </div>
                    </div>
                </article>

                <article className="slds-card slds-align_absolute-center">
                    <Button key="1" variant="brand" label="Submit Evaluation" onClick={() => this.validateInformation()}/>
                </article>
            </div>            
        )
    }
}