import React from "react";
import { FooterContext } from "./TrainingProvider";

export default class CardComponent extends React.Component {
  static contextType = FooterContext;

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      show: true,
    };

    //binding functions
  }

  componentDidMount() {}

  //this.context.changeFooter either uses Home if showingDetails or props.Object if not
  changeContext(action) {
    if (action !== null) {
      this.props.toggleDetails(this.props.id);
    }
  }

  render() {
    if (!this.state.show) return null;

    return (
      <article className="slds-card" onClick={() => this.changeContext(this.props.Action)}>
        <div className="slds-card__header slds-grid" style={{ pointerEvents: "none" }}>
          <div className="slds-media slds-media_center slds-has-flexi-truncate">
            <div className="slds-media__figure">
              <span
                className={`slds-icon_container slds-icon_container--circle slds-icon-action-${this.props.Icon}`}
                title="account"
                style={{ borderRadius: "5px" }}
              >
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use
                    xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#${this.props.SVGSymbol}`}
                  ></use>
                </svg>
              </span>
            </div>
            <div className="slds-media__body">
              <h2 className="slds-card__header-title">
                <div className="slds-card__header-link" title="Accounts">
                  <span>
                    {this.props.title} • {this.props.field3}
                  </span>
                </div>
              </h2>
            </div>
          </div>
        </div>

        {this.props.field1 ? (
          <div className="slds-card__body slds-card__body_inner">{this.props.field1}</div>
        ) : null}

        {this.props.field2 ? (
          <div className="slds-card__body slds-card__body_inner">{this.props.field2}</div>
        ) : null}

        {this.props.field3 ? (
          <div className="slds-card__body slds-card__body_inner">{this.props.field3}</div>
        ) : null}

        {this.props.field4 ? (
          <div className="slds-card__body slds-card__body_inner">{this.props.field4}</div>
        ) : null}
      </article>
    );
  }
}
