import React from 'react';
import {BodyContext} from '../TrainingProvider';
import APIWorker from '../APIWorker';
import Button from '@salesforce/design-system-react/lib/components/button';
import Input from '@salesforce/design-system-react/lib/components/input';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Accordion from '@salesforce/design-system-react/lib/components/accordion'; 
import AccordionPanel from '@salesforce/design-system-react/lib/components/accordion/panel';
import Checkbox from '@salesforce/design-system-react/lib/components/checkbox';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';

const times = [
    {
        id: '0:00:00.000Z',
        label: '12:00 AM'
    },
    {
        id: '0:15:00.000Z',
        label: '12:15 AM'
    },
    {
        id: '0:30:00.000Z',
        label: '12:30 AM'
    },
    {
        id: '0:45:00.000Z',
        label: '12:45 AM'
    },
    {
        id: '1:00:00.000Z',
        label: '1:00 AM'
    },
    {
        id: '1:15:00.000Z',
        label: '1:15 AM'
    },
    {
        id: '1:30:00.000Z',
        label: '1:30 AM'
    },
    {
        id: '1:45:00.000Z',
        label: '1:45 AM'
    },
    {
        id: '2:00:00.000Z',
        label: '2:00 AM'
    },
    {
        id: '2:15:00.000Z',
        label: '2:15 AM'
    },
    {
        id: '2:30:00.000Z',
        label: '2:30 AM'
    },
    {
        id: '2:45:00.000Z',
        label: '2:45 AM'
    },
    {
        id: '3:00:00.000Z',
        label: '3:00 AM'
    },
    {
        id: '3:15:00.000Z',
        label: '3:15 AM'
    },
    {
        id: '3:30:00.000Z',
        label: '3:30 AM'
    },
    {
        id: '3:45:00.000Z',
        label: '3:45 AM'
    },
    {
        id: '4:00:00.000Z',
        label: '4:00 AM'
    },
    {
        id: '4:15:00.000Z',
        label: '4:15 AM'
    },
    {
        id: '4:30:00.000Z',
        label: '4:30 AM'
    },
    {
        id: '4:45:00.000Z',
        label: '4:45 AM'
    },
    {
        id: '5:00:00.000Z',
        label: '5:00 AM'
    },
    {
        id: '5:15:00.000Z',
        label: '5:15 AM'
    },
    {
        id: '5:30:00.000Z',
        label: '5:30 AM'
    },
    {
        id: '5:45:00.000Z',
        label: '5:45 AM'
    },
    {
        id: '6:00:00.000Z',
        label: '6:00 AM'
    },
    {
        id: '6:15:00.000Z',
        label: '6:15 AM'
    },
    {
        id: '6:30:00.000Z',
        label: '6:30 AM'
    },
    {
        id: '6:45:00.000Z',
        label: '6:45 AM'
    },
    {
        id: '7:00:00.000Z',
        label: '7:00 AM'
    },
    {
        id: '7:15:00.000Z',
        label: '7:15 AM'
    },
    {
        id: '7:30:00.000Z',
        label: '7:30 AM'
    },
    {
        id: '7:45:00.000Z',
        label: '7:45 AM'
    },
    {
        id: '8:00:00.000Z',
        label: '8:00 AM'
    },
    {
        id: '8:15:00.000Z',
        label: '8:15 AM'
    },
    {
        id: '8:30:00.000Z',
        label: '8:30 AM'
    },
    {
        id: '8:45:00.000Z',
        label: '8:45 AM'
    },
    {
        id: '9:00:00.000Z',
        label: '9:00 AM'
    },
    {
        id: '9:15:00.000Z',
        label: '9:15 AM'
    },
    {
        id: '9:30:00.000Z',
        label: '9:30 AM'
    },
    {
        id: '9:45:00.000Z',
        label: '9:45 AM'
    },
    {
        id: '10:00:00.000Z',
        label: '10:00 AM'
    },
    {
        id: '10:15:00.000Z',
        label: '10:15 AM'
    },
    {
        id: '10:30:00.000Z',
        label: '10:30 AM'
    },
    {
        id: '10:45:00.000Z',
        label: '10:45 AM'
    },
    {
        id: '11:00:00.000Z',
        label: '11:00 AM'
    },
    {
        id: '11:15:00.000Z',
        label: '11:15 AM'
    },
    {
        id: '11:30:00.000Z',
        label: '11:30 AM'
    },
    {
        id: '11:45:00.000Z',
        label: '11:45 AM'
    },
    {
        id: '12:00:00.000Z',
        label: '12:00 PM'
    },
    {
        id: '12:15:00.000Z',
        label: '12:15 PM'
    },
    {
        id: '12:30:00.000Z',
        label: '12:30 PM'
    },
    {
        id: '12:45:00.000Z',
        label: '12:45 PM'
    },
    {
        id: '13:00:00.000Z',
        label: '1:00 PM'
    },
    {
        id: '13:15:00.000Z',
        label: '1:15 PM'
    },
    {
        id: '13:30:00.000Z',
        label: '1:30 PM'
    },
    {
        id: '13:45:00.000Z',
        label: '1:45 PM'
    },
    {
        id: '14:00:00.000Z',
        label: '2:00 PM'
    },
    {
        id: '14:15:00.000Z',
        label: '2:15 PM'
    },
    {
        id: '14:30:00.000Z',
        label: '2:30 PM'
    },
    {
        id: '14:45:00.000Z',
        label: '2:45 PM'
    },
    {
        id: '15:00:00.000Z',
        label: '3:00 PM'
    },
    {
        id: '15:15:00.000Z',
        label: '3:15 PM'
    },
    {
        id: '15:30:00.000Z',
        label: '3:30 PM'
    },
    {
        id: '15:45:00.000Z',
        label: '3:45 PM'
    },
    {
        id: '16:00:00.000Z',
        label: '4:00 PM'
    },
    {
        id: '16:15:00.000Z',
        label: '4:15 PM'
    },
    {
        id: '16:30:00.000Z',
        label: '4:30 PM'
    },
    {
        id: '16:45:00.000Z',
        label: '4:45 PM'
    },
    {
        id: '17:00:00.000Z',
        label: '5:00 PM'
    },
    {
        id: '17:15:00.000Z',
        label: '5:15 PM'
    },
    {
        id: '17:30:00.000Z',
        label: '5:30 PM'
    },
    {
        id: '17:45:00.000Z',
        label: '5:45 PM'
    },
    {
        id: '18:00:00.000Z',
        label: '6:00 PM'
    },
    {
        id: '18:15:00.000Z',
        label: '6:15 PM'
    },
    {
        id: '18:30:00.000Z',
        label: '6:30 PM'
    },
    {
        id: '18:45:00.000Z',
        label: '6:45 PM'
    },
    {
        id: '19:00:00.000Z',
        label: '7:00 PM'
    },
    {
        id: '19:15:00.000Z',
        label: '7:15 PM'
    },
    {
        id: '19:30:00.000Z',
        label: '7:30 PM'
    },
    {
        id: '19:45:00.000Z',
        label: '7:45 PM'
    },
    {
        id: '20:00:00.000Z',
        label: '8:00 PM'
    },
    {
        id: '20:15:00.000Z',
        label: '8:15 PM'
    },
    {
        id: '20:30:00.000Z',
        label: '8:30 PM'
    },
    {
        id: '20:45:00.000Z',
        label: '8:45 PM'
    },
    {
        id: '21:00:00.000Z',
        label: '9:00 PM'
    },
    {
        id: '21:15:00.000Z',
        label: '9:15 PM'
    },
    {
        id: '21:30:00.000Z',
        label: '9:30 PM'
    },
    {
        id: '21:45:00.000Z',
        label: '9:45 PM'
    },
    {
        id: '22:00:00.000Z',
        label: '10:00 PM'
    },
    {
        id: '22:15:00.000Z',
        label: '10:15 PM'
    },
    {
        id: '22:30:00.000Z',
        label: '10:30 PM'
    },
    {
        id: '22:45:00.000Z',
        label: '10:45 PM'
    },
    {
        id: '23:00:00.000Z',
        label: '11:00 PM'
    },
    {
        id: '23:15:00.000Z',
        label: '11:15 PM'
    },
    {
        id: '23:30:00.000Z',
        label: '11:30 PM'
    },
    {
        id: '23:45:00.000Z',
        label: '11:45 PM'
    }
];


export default class ToyotaForm extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            expandedPanels: {},
            arriveAtStartTime : false,
            finishAtEndTime : false,
            actualEndTime : null,
            actualEndTimeErrorMessage : '',
            numberOfParticipants : '',
            numberOfParticipantsErrorMessage : '',
            providedCorrectMaterials : false,
            providedCorrectMaterialsReason : '',
            providedCorrectMaterialsReasonErrorMessage : '',
            providedCorrectRoom : false,
            providedCorrectRoomReason : '',
            providedCorrectRoomReasonErrorMessage : '',
            requiredEquipmentAvailable : false,
            requiredEquipmentAvailableReason : '',
            requiredEquipmentAvailableReasonErrorMessage : '',
            ranAccordingToExpectation : false,
            ranAccordingToExpectationReason : '',
            ranAccordingToExpectationReasonErrorMessage : '',
            contactHSContact : false,
            contactHSContactReason : '',
            contactHSContactReasonErrorMessage : '',
            recordTypeId : '',
            selection : []
        }

        this.validateInformation = this.validateInformation.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.submitQuestionnaire = this.submitQuestionnaire.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
    }

    componentDidMount(){
        let json = {
            "EvalRecordTypeName" : "Toyota"
        };

        APIWorker.getCourseQuestionnaireRecordType(json)
        .then(res => {
            this.setState({
                recordTypeId : res.data[0].Id
            });
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    togglePanel(event, data) {
        let toggle = true;
        if (this.state.expandedPanels[data] === true) {
            toggle = false;
        }

        this.setState(
            {
            expandedPanels: {},
            },
            () => {
                this.setState((state) => ({
                    expandedPanels: {
                        [data]: toggle,
                    },
                }));
            }
        );
    }

    validateInformation(){
        this.clearErrors();
        this.showSpinner();

        if(this.state.finishAtEndTime === false && this.state.actualEndTime === null){
            this.setState({
                actualEndTimeErrorMessage : 'Need to select actual end time for questionnaire!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.providedCorrectRoom === false && this.state.providedCorrectRoomReason === ''){
            this.setState({
                providedCorrectRoomReasonErrorMessage : 'Must specify details if provided incorrect room details!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.numberOfParticipants === ''){
            this.setState({
                numberOfParticipantsErrorMessage : 'Must specify how many participants were present!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.providedCorrectMaterials === false && this.state.providedCorrectMaterialsReason === ''){
            this.setState({
                providedCorrectMaterialsReasonErrorMessage : 'Must specify details if provided incorrect materials!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.requiredEquipmentAvailable === false && this.state.requiredEquipmentAvailableReason === ''){
            this.setState({
                requiredEquipmentAvailableReasonErrorMessage : 'Must specify details if required equipment not available!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.ranAccordingToExpectation === false && this.state.ranAccordingToExpectationReason === ''){
            this.setState({
                ranAccordingToExpectationReasonErrorMessage : 'Must specify details if appointment did not run according to expectation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.contactHSContact === true && this.state.contactHSContactReason === ''){
            this.setState({
                contactHSContactReasonErrorMessage : 'Must specify details about contacting H&S contact!'
            });
            this.hideSpinner();
            return;
        }
        
        this.submitQuestionnaire();
    }

    submitQuestionnaire(){
        //check if id was retrieved, update if existing, otherwise create
        let json = {
            "Arrived_On_Time__c" : this.state.arriveAtStartTime,
            "Ended_On_Time__c" : this.state.finishAtEndTime,
            "Actual_End_Time__c" : this.state.actualEndTime,
            "Number_of_Participants__c" : this.state.numberOfParticipants,
            "Provided_with_correct_room_location__c" : this.state.providedCorrectRoom,
            "Room_Location_Incorrect_Details__c" : this.state.providedCorrectRoomReason,
            "Materials_were_correct_available__c" : this.state.providedCorrectMaterials,
            "Materials_Incorrect_Details__c" : this.state.providedCorrectMaterialsReason,
            "Required_equipment_was_available__c" : this.state.requiredEquipmentAvailable,
            "Equipment_Unavailable_Details__c" : this.state.requiredEquipmentAvailableReason,
            "Ran_according_to_expectations__c" : this.state.ranAccordingToExpectation,
            "Expectations_Incorrect_Details__c" : this.state.ranAccordingToExpectationReason,
            "Did_you_contact_H_S_Contact__c" : this.state.contactHSContact,
            "Contacted_H_S_Contact_Details__c" : this.state.contactHSContactReason,
            "Service_Appointment__c" : this.props.saId,
            "RecordTypeId" : this.state.recordTypeId
        }
        console.log(json);

        //create questionnaire
        APIWorker.createCourseQuestionnaire(json)
        .then(res => {
            this.hideSpinner();
            this.closeModal();
            this.context.showSuccessAlert('Toyota Questionnaire submitted!');
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    closeModal(){
        this.context.changeModal('');
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    clearErrors(){
        this.setState({
            actualEndTimeErrorMessage : '',
            numberOfParticipantsErrorMessage : '',
            providedCorrectMaterialsReasonErrorMessage : '',
            providedCorrectRoomReasonErrorMessage : '',
            requiredEquipmentAvailableReasonErrorMessage : '',
            ranAccordingToExpectationReasonErrorMessage : ''
        });
    }

    render(){
        return(
            <div>
                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">                                             
                            <div className="slds-media__body">
                                <IconSettings iconPath="/assets/icons">
                                    <Accordion>
                                        <AccordionPanel expanded={true} id={0} key={0} summary="Checklist" onTogglePanel={(event) => (event)}>
                                            <Checkbox
                                                id="arriveAtStartTime"
                                                key="arriveAtStartTime"
                                                labels={{
                                                    label: 'Did you arrive at the scheduled start time?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.arriveAtStartTime}
                                                variant="toggle"
                                                required
                                                onChange={() => {
                                                    this.setState({
                                                        arriveAtStartTime : !this.state.arriveAtStartTime
                                                    });
                                                }}
                                            />

                                            <Checkbox
                                                id="finishAtEndTime"
                                                key="finishAtEndTime"
                                                labels={{
                                                    label: 'Did you finish at the scheduled end time?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.finishAtEndTime}
                                                onChange={() => {
                                                    this.setState({
                                                        finishAtEndTime : !this.state.finishAtEndTime,
                                                        actualEndTimeErrorMessage : ''
                                                    });
                                                }}
                                                errorText={this.state.actualEndTimeErrorMessage}
                                                variant="toggle"
                                                required
                                            />  

                                            <Combobox
                                                id="combobox-endtime"
                                                labels={{
                                                    label: 'Actual End Time',
                                                    placeholder: 'Select Time',
                                                }}
                                                events={{
                                                    onSelect: (event, data) => {
                                                        this.setState({
                                                            actualEndTime: data.selection[0].id,
                                                            selection: data.selection,
                                                            actualEndTimeErrorMessage : ''
                                                        });
                                                    }
                                                }}
                                                options={times}
                                                selection={this.state.selection}
                                                value={this.state.actualEndTime}
                                                required={!this.state.finishAtEndTime}
                                                variant="readonly"
                                            />

                                            <Input
                                                label="How many participants were there?"
                                                placeholder="ex. 5"
                                                type="number"
                                                errorText={this.state.numberOfParticipantsErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        numberOfParticipants : Number(data.value)
                                                    });
                                                }}
                                                value={this.state.numberOfParticipants}
                                            />                                                             

                                            <br/>
                                            
                                            <Checkbox
                                                id="providedCorrectRoom"
                                                key="providedCorrectRoom"
                                                labels={{
                                                    label: 'Were you provided the correct room/location?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.providedCorrectRoom}
                                                onChange={() => {
                                                    this.setState({
                                                        providedCorrectRoom : !this.state.providedCorrectRoom
                                                    });
                                                }}
                                                variant="toggle"
                                                required
                                            />

                                            {
                                                this.state.providedCorrectRoom === false &&
                                                <Input
                                                    label="If no, provide additional details"
                                                    placeholder="ex. Wrong room provided"
                                                    type="text"
                                                    errorText={this.state.providedCorrectRoomReasonErrorMessage}
                                                    onChange={(event, data) => {
                                                        this.setState({
                                                            providedCorrectRoomReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.providedCorrectRoomReason}
                                                />
                                            }

                                            <br/>

                                            <Checkbox
                                                id="providedCorrectMaterials"
                                                key="providedCorrectMaterials"
                                                labels={{
                                                    label: 'Were the provided materials correct/available?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.providedCorrectMaterials}
                                                onChange={() => {
                                                    this.setState({
                                                        providedCorrectMaterials : !this.state.providedCorrectMaterials
                                                    });
                                                }}
                                                variant="toggle"
                                                required
                                            />

                                            {
                                                this.state.providedCorrectMaterials === false &&
                                                <Input
                                                    label="If no, provide additional details"
                                                    placeholder="ex. Wrong materials provided"
                                                    type="text"
                                                    errorText={this.state.providedCorrectMaterialsReasonErrorMessage}
                                                    onChange={(event, data) => {
                                                        this.setState({
                                                            providedCorrectMaterialsReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.providedCorrectMaterialsReason}
                                                />     
                                            }

                                            <br/>

                                            <Checkbox
                                                id="requiredEquipmentAvailable"
                                                key="requiredEquipmentAvailable"
                                                labels={{
                                                    label: 'Was the required equipment available?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.requiredEquipmentAvailable}
                                                onChange={() => {
                                                    this.setState({
                                                        requiredEquipmentAvailable : !this.state.requiredEquipmentAvailable
                                                    });
                                                }}
                                                variant="toggle"
                                                required
                                            />

                                            {
                                                this.state.requiredEquipmentAvailable === false &&
                                                <Input
                                                    label="If no, provide additional details"
                                                    placeholder="ex. Wrong equipment provided"
                                                    type="text"
                                                    errorText={this.state.requiredEquipmentAvailableReasonErrorMessage}
                                                    onChange={(event, data) => {
                                                        this.setState({
                                                            requiredEquipmentAvailableReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.requiredEquipmentAvailableReason}
                                                />     
                                            }          

                                            <br/>                      
                                    
                                            <Checkbox
                                                id="ranAccordingToExpectation"
                                                key="ranAccordingToExpectation"
                                                labels={{
                                                    label: 'Did the appointment run according to expectation?',
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.ranAccordingToExpectation}
                                                onChange={() => {
                                                    this.setState({
                                                        ranAccordingToExpectation : !this.state.ranAccordingToExpectation
                                                    });
                                                }}
                                                variant="toggle"
                                                required
                                            />

                                            {
                                                this.state.ranAccordingToExpectation === false &&
                                                <Input
                                                    label="If no, provide additional details"
                                                    placeholder="ex. Didnt meet expectation"
                                                    type="text"
                                                    errorText={this.state.ranAccordingToExpectationReasonErrorMessage}
                                                    onChange={(event, data) => {
                                                        this.setState({
                                                            ranAccordingToExpectationReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.ranAccordingToExpectationReason}
                                                />     
                                            }         

                                            <br/>

                                            <Checkbox
                                                id="contactHSContact"
                                                key="contactHSContact"
                                                labels={{
                                                    label: `Did you contact ${this.props.primaryContactName}?`,
                                                    toggleDisabled: 'No',
                                                    toggleEnabled: 'Yes'
                                                }}
                                                checked={this.state.contactHSContact}
                                                onChange={() => {
                                                    this.setState({
                                                        contactHSContact : !this.state.contactHSContact
                                                    });
                                                }}
                                                variant="toggle"
                                                required
                                            />

                                            {
                                                this.state.contactHSContact === true &&
                                                <Input
                                                    label="If yes, provide additional details"
                                                    placeholder={`ex. Emailed ${this.props.primaryContactName}`}
                                                    type="text"
                                                    errorText={this.state.contactHSContactReasonErrorMessage}
                                                    onChange={(event, data) => {
                                                        this.setState({
                                                            contactHSContactReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.contactHSContactReason}
                                                />     
                                            }  
                                        </AccordionPanel>                                                      
                                    </Accordion>
                                </IconSettings>
                            </div>
                        </div>
                    </div>
                </article> 

                <article className="slds-card slds-align_absolute-center">
                    <Button key="1" variant="brand" label="Submit" onClick={() => this.validateInformation()}/>
                </article>                
            </div>
        )
    }
}