import React from "react";
import logo from "./osg_logo.svg";
import "./Login.css";
import Input from "@salesforce/design-system-react/lib/components/input";
import Button from "@salesforce/design-system-react/lib/components/button";
import APIWorker from "../src/v1/APIWorker";
import Spinner from "@salesforce/design-system-react/lib/components/spinner";
import InputIcon from "@salesforce/design-system-react/lib/components/icon/input-icon";
import IconSettings from "@salesforce/design-system-react/lib/components/icon-settings";
import Alert from "@salesforce/design-system-react/lib/components/alert";
import AlertContainer from "@salesforce/design-system-react/lib/components/alert/container";
import Icon from "@salesforce/design-system-react/lib/components/icon";

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.loginFailed = false;

    this.state = {
      userEmail: process.env.REACT_APP_EMAIL ? process.env.REACT_APP_EMAIL : "",
      userPassword: process.env.REACT_APP_PASS ? process.env.REACT_APP_PASS : "",
      userVerified: false,
      userAlias: "",
      userId: "",
      userProps: {},
      message: "",
      isLoading: false,
      passwordType: "password",
      icon: "preview",
      showAlertError: false,
    };

    this.showSpinner = this.showSpinner.bind(this);
    this.hideSpinner = this.hideSpinner.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.showErrorAlert = this.showErrorAlert.bind(this);
  }

  componentDidMount() {
    console.log("Login Mounted", this.props);

    if (this.props.userId && this.props.userEmail) {
      console.log("Auto login");
      this.checkUser();
    }
  }

  showSpinner() {
    this.setState({
      isLoading: true,
    });
  }

  hideSpinner() {
    this.setState({
      isLoading: false,
    });
  }

  togglePassword() {
    let passwordType = "";
    let icon = "";
    if (this.state.passwordType === "password") {
      passwordType = "text";
      icon = "hide";
    } else {
      passwordType = "password";
      icon = "preview";
    }
    this.setState(
      {
        passwordType,
      },
      () => {
        this.setState({
          icon,
        });
      }
    );
  }

  //TODO
  //Once cache persisted for username and password, we can retrieve if they exist and place them in the state via ctor
  //If they both have a value, then just call checkUser and it should proceed straight through

  setUser() {
    console.log("setUser", this.state);
    this.props.setUser(this.state.userEmail, this.state.userId, this.state.userProps);
  }

  showErrorAlert() {
    this.setState({
      showAlertError: true,
    });
    setTimeout(() => {
      this.hideSpinner();
    }, 2500);
  }

  checkUser() {
    let json = {};

    if (!this.loginFailed && this.props.userId) {
      json = {
        userId: this.props.userId,
        email: this.props.userEmail,
      };
    } else {
      this.showSpinner();

      json = {
        email: this.state.userEmail,
        password: this.state.userPassword,
      };
    }

    //TODO
    //Check if authenticated before calling this to reduce unneeded calls
    // APIWorker.authenticateUser()
    // .then((res) => {
    APIWorker.checkUser(json)
      .then((res) => {
        if (res.data == false) {
          this.loginFailed = true;
          this.setState(
            {
              message: "Error logging in",
            },
            () => {
              this.hideSpinner();
            }
          );
        } else if (res.data.error) {
          this.loginFailed = true;
          this.setState(
            {
              message: res.data.error,
            },
            () => {
              this.hideSpinner();
            }
          );
        } else {
          let data = res.data[0] ? res.data[0] : res.data;
          this.setState(
            {
              userId: data["SF_User__c"], //sf_user id to be used for assigned resource to filter the list of SA
              userProps: data,
              userEmail: data["User_Email__c"],
            },
            () => {
              this.setUser();
            }
          );
        }
      })
      .catch((error) => {
        this.showErrorAlert();
      });
    // })
    // .catch((error) => {
    // this.showErrorAlert();
    // });
  }

  render() {
    if (!this.state.message && this.props.userId && this.props.userEmail) {
      return <Spinner size="large" variant="brand" />;
    }

    return (
      <div>
        {this.state.isLoading === true && <Spinner size="large" variant="brand" />}

        {this.state.showAlertError === true && (
          <IconSettings iconPath="/assets/icons">
            <AlertContainer>
              <Alert
                dismissible
                icon={<Icon category="utility" name="warning" />}
                labels={{
                  heading: "An error has occured. Try again later.",
                }}
                variant="error"
                onRequestClose={() => {
                  this.setState({ showAlertError: false });
                }}
              />
            </AlertContainer>
          </IconSettings>
        )}
        <div className="loginContent">
          <div className="loginForm">
            <img
              src={logo}
              alt="OSG"
              style={{ width: "120px", margin: "auto", display: "block", marginBottom: "2vh" }}
            />

            {this.state.message ? (
              <div style={{ textAlign: "center", color: "red" }}>{this.state.message}</div>
            ) : null}

            <div className="slds-form__row">
              <div className="slds-form__item" role="listitem">
                <div className="slds-form-element slds-form-element_stacked slds-is-editing">
                  <div className="slds-form-element__control">
                    <div className="slds-combobox_container">
                      <div
                        className="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click"
                        aria-controls="email"
                        aria-expanded="false"
                        aria-haspopup="listbox"
                        role="combobox"
                      >
                        <div className="slds-combobox__form-element" role="none">
                          <Input
                            label="Email"
                            type="email"
                            onChange={(event, data) => {
                              this.setState({
                                userEmail: data.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <IconSettings iconPath="/assets/icons">
              <div className="slds-form__row" style={{ marginTop: "0.8rem" }}>
                <div className="slds-form__item" role="listitem">
                  <div className="slds-form-element slds-form-element_stacked slds-is-editing">
                    <div className="slds-form-element__control">
                      <div className="slds-combobox_container">
                        <div
                          className="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click"
                          aria-controls="password"
                          aria-expanded="false"
                          aria-haspopup="listbox"
                          role="combobox"
                        >
                          <div className="slds-combobox__form-element" role="none">
                            <Input
                              label="Password"
                              type={this.state.passwordType}
                              iconRight={
                                <InputIcon
                                  name={this.state.icon}
                                  category="utility"
                                  onClick={() => this.togglePassword()}
                                  style={{
                                    height: "36px",
                                    width: "36px",
                                    top: "20%",
                                    visibility:
                                      this.state.userPassword === "" ? "hidden" : "visible",
                                  }}
                                  iconSize="large"
                                />
                              }
                              onChange={(event, data) => {
                                this.setState({
                                  userPassword: data.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </IconSettings>

            <Button
              key="login"
              label="Login"
              onClick={() => this.checkUser()}
              variant="brand"
              style={{ width: "100%", marginTop: "1rem", height: "2.75rem" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
