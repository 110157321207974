import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import ToyotaForm from './ToyotaForm';

export default class QuestionnaireModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            cards : []
        }

        this.generateLayout = this.generateLayout.bind(this);
    }

    componentDidMount(){
        this.generateLayout();
    }

    generateLayout(){
        let cards = [];

        if(this.props.type === 'toyota'){
            cards.push(
                <ToyotaForm key="Toyota" saId={this.props.cardDetailId} primaryContactName={this.props.data.Primary_Contact__c}/>
            );
        }

        this.setState({
            cards
        });
    }

    render(){
        return(
            <div>
                <Modal isOpen={true} heading="Questionnaire" ariaHideApp={false} align="center" size="large"
                footer={[
                    <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                ]}>
                    {this.state.cards}
                </Modal>    
            </div>
        )
    }
}