import Button from '@salesforce/design-system-react/lib/components/button';
import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Input from '@salesforce/design-system-react/lib/components/input'; 
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Datepicker from '@salesforce/design-system-react/lib/components/date-picker';
import ProgressIndicator from '@salesforce/design-system-react/lib/components/progress-indicator'; 
import SearchResultComponent from './SearchResultComponent';
import OSConfirmCheckInComponent from './OSConfirmCheckInComponent';
import APIWorker from '../APIWorker';

export default class SearchAttendeeComponent extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        //searchForm vs steps to determine layout within component
        this.state = {
            sa : this.props.sa,
            hasSkillsPassKey: this.props.sa.Has_SkillsPass_Key__c,
            firstName : '',
            lastName : '',

            dob : null,
            dateOfBirth : '',

            nameErrorMessage : '',
            dobErrorMessage : '',
            data : [],
            cards : [],
            steps : [{id:0, label:"Step 1: Search For Contact"}, {id:1, label:"Step 2: Select The Contact"}, {id:2, label:"Step 3: Confirm Details"}, {id:3, label:""}],
			completedSteps: [],
			disabledSteps : [],
            selectedStep : {},
        }

        this.checkInAttendee = this.checkInAttendee.bind(this);
        this.selectContact = this.selectContact.bind(this);
        this.createContact = this.createContact.bind(this);
        this.clearResults = this.clearResults.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
    }

    componentDidMount(){
        this.setState({
            disabledSteps: this.state.steps.slice(1, this.state.steps.length),
            selectedStep: this.state.steps[0],
        })
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(message){
        this.context.showErrorAlert(message);
    }

    clearErrors(){
        this.setState({
            nameErrorMessage : '',
            dobErrorMessage : ''
        });
    }

    searchAttendee(){
        this.setState({
            selectedStep : this.state.steps[1],
            searchForm : false
        });

        let contactJson = {
            "AccountId" : this.props.sa.Account__r.Id,
            "FirstName" : this.state.firstName,
            "LastName" : this.state.lastName,
            "Date_Of_Birth__c" : this.state.dob
        };

        APIWorker.searchContact(contactJson)
        .then(res => {
            this.setState({
                data : res.data
            },
                () => {
                    this.showResults();
                    this.hideSpinner();
                }
            );
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    //Creates attendee and links to contact (json) record
    checkInAttendee(json){
        let attendeeJson = {
            "Name" : json.FirstName + ' ' + json.LastName,
            "Service_Appointment__c" : this.props.sa.Id,
            "Confirmed__c" : "Yes",
            "Contact__c" : json.Id,
            "Order_Product__c" : this.props.sa.Order_Product__c,
            "Status__c" : "Confirmed",
            "Check_in_Time__c" : new Date().toISOString()
        };

        APIWorker.createAttendee(attendeeJson)
        .then(res => {
            this.setState({
                selectedStep : this.state.steps[3],
                searchForm : false
            });
            this.props.hideSpinner();
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    selectContact(id, duplicateReview = false){
        this.showSpinner();

        let cards = [];
        APIWorker.getContact(id)
        .then(res =>{
            cards.push(
                <OSConfirmCheckInComponent key="link" mode="link" sa={this.props.sa} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} showErrorAlert={this.showErrorAlert} clearResults={this.clearResults} checkInAttendee={this.checkInAttendee} record={res.data[0]} dateOfBirth={res.data[0].Date_Of_Birth__c !== null ? res.data[0].Date_Of_Birth__c : this.state.dob} duplicateReview={duplicateReview} hasSkillsPassKey={this.state.hasSkillsPassKey}/>
            );
            this.setState({
                cards,
                selectedStep : this.state.steps[2]
            });
            this.hideSpinner();
        })
        .catch(error => {
            this.showErrorAlert();
        });

    }

    createContact(){
        this.showSpinner();

        let cards = [];
        let contactData = {
            "AccountId" : this.props.sa.Account__r.Id,
            "FirstName" : this.state.firstName,
            "LastName" : this.state.lastName,
            "Email" : null
        };

        cards.push(
            <OSConfirmCheckInComponent key="create" mode="create" sa={this.props.sa} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} showErrorAlert={this.showErrorAlert} clearResults={this.clearResults} checkInAttendee={this.checkInAttendee} record={contactData} dateOfBirth={this.state.dob} hasSkillsPassKey={this.state.hasSkillsPassKey}/>
        );
        this.setState({
            cards,
            selectedStep : this.state.steps[2]
        });
        this.hideSpinner();

    }

    clearResults(){
        let cards = [];
        this.setState({
            selectedStep: this.state.steps[0],
            cards
        });
    }


    showResults(){ 
        let cards = [];

        //No matches found for contact information
        if(this.state.data.length === 0){
            let data = {
                'FirstName' : this.state.firstName,
                'LastName' : this.state.lastName
            }

            cards.push(
                <SearchResultComponent key={1} data={data} createContact={this.createContact} clearResults={this.clearResults}></SearchResultComponent>
            );
        }

        //If there are multiple matches, only show the top result and tag it as 'Duplicate Review'
        else{
            let duplicateReview = false;

            if(this.state.data.length > 1){
                duplicateReview = true;
                let contact = this.state.data[0];

                cards.push(
                    <SearchResultComponent key={contact.Id} duplicateReview={duplicateReview} record={contact} selectContact={this.selectContact}></SearchResultComponent>
                );
            }
            else{
                this.state.data.map((contact) => {
                    return cards.push(
                        <SearchResultComponent key={contact.Id} duplicateReview={duplicateReview} record={contact} selectContact={this.selectContact}></SearchResultComponent>
                    );
                });
            }
        }

        this.setState({
            cards
        });
    }

    validateInformation(){
        this.clearErrors();
        let runValidations = true;

        if(this.state.hasSkillsPassKey){
            runValidations = false;
            if(this.state.dateOfBirth !== '' && this.state.dateOfBirth.getFullYear().toString() === new Date().getFullYear().toString()){
                this.setState({
                    dobErrorMessage : 'Select a valid year of birth!'
                });
            }
            else{
                runValidations = true;
            }
        }
       
        if(runValidations === true){
            if(this.state.firstName === '' || this.state.lastName === ''){
                this.setState({
                    nameErrorMessage : 'Cannot leave first name or last name blank!'
                });
            }

            else if(/^[a-zA-ZÀ-Ÿ-'`. ]+$/.test(this.state.firstName) !== true || /^[a-zA-ZÀ-Ÿ-'`. ]+$/.test(this.state.lastName) !== true){
                this.setState({
                    nameErrorMessage : 'First or last name can only contain letters, hyphens, accents and apostrophes!'
                });
            }

            else{
                this.showSpinner();

                //If this SA is an eval, this needs to call a different query that checks theory based if there is a match found for contact
                
                this.setState({
                    firstName : this.state.firstName.trim(),
                    lastName : this.state.lastName.trim()
                },
                    () => {
                        this.searchAttendee();
                    }
                );
            }            
        }
    }

    render(){
        return(
            <div>
                {this.state.selectedStep.id === 0 && 
                    <article className="slds-card">
                        <div className="slds-card__header slds-grid">
                            
                            <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                <div className="slds-media__figure">
                                    <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                            <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                        </svg>
                                    </span>                            
                                </div>

                                <div className="slds-media__body">
                                    <h2 className="slds-card__header-title">
                                        <Button key="2" label="Search for Attendee" onClick={() => this.validateInformation()}/>
                                    </h2>
                                </div>
                                
                                <div className="slds-media__body">

                                    <Input
                                        label="Company"
                                        placeholder="Company Name"
                                        type="text"
                                        value={this.props.sa.Account__r.Name}
                                        readOnly={true}
                                    />

                                    <Input
                                        label="First Name"
                                        placeholder="ex. John"
                                        type="text"
                                        onChange={(event, data) => {
                                            this.setState({
                                                firstName : data.value
                                            });
                                        }}                                            
                                        value={this.state.firstName}
                                        minLength="1"
                                        required={true}
                                    />

                                    <Input
                                        label="Last Name"
                                        placeholder="ex. Smith"
                                        type="text"
                                        errorText={this.state.nameErrorMessage}
                                        onChange={(event, data) => {
                                            this.setState({
                                                lastName : data.value
                                            });
                                        }}                                            
                                        value={this.state.lastName}
                                        minLength="1"
                                        required={true}
                                    />

                                {/*
                                DO NOT CHANGE -> UTCDate() - 1
                                There is a bug in the datepicker where the display date and internal date is 1 day higher than what you pick so you must reduce by 1 
                                Unsure why this happens only in this component
                                */}
                                {
                                    this.state.hasSkillsPassKey &&
                                    <IconSettings iconPath="/assets/icons">
                                        <Datepicker
                                        labels={{
                                            label: 'Date of Birth (MM/DD/YYYY)'
                                        }}
                                        onChange={(event, data) => {
                                            console.log(data);
                                            console.log(data.date);
                                            this.setState({
                                                dob : data.date.getFullYear() + '-' + ((data.date.getMonth() + 1) < 10 ? '0' : '') + (data.date.getMonth() + 1) + '-' + (data.date.getDate() < 10 ? '0' : '') + data.date.getDate(),
                                                dateOfBirth : new Date(data.date.getFullYear(), data.date.getMonth(), data.date.getDate())
                                            });
                                        }}
                                        relativeYearFrom={-90}
                                        relativeYearTo={1}
                                        value={this.state.dateOfBirth}
                                        
                                        formatter={(date) => {
                                            if(date !== ''){
                                                let month = (date.getMonth() + 1).toString();
                                                month = month.length === 2 ? month : "0" + month;

                                                let day = date.getDate().toString();
                                                day = day.length === 2 ? day : "0" + day;
                                                return month + '/' + day + '/' + date.getFullYear();
                                            }
                                            else{
                                                return '';
                                            }
                                            
                                        }}
                                        
                                        parser={(str) =>{
                                            return new Date(str);
                                        }}
                                        input={
                                            <Input
                                                readOnly={false}
                                                //required={this.state.hasSkillsPassKey && true}
                                                styleInput={{
                                                    'borderColor' : '#dddbda',
                                                    'padding' : '0 1rem 0 0.75rem'
                                                }}
                                                errorText={this.state.dobErrorMessage}
                                            />
                                        }
                                        />
                                    </IconSettings>
                                }

                                </div>   
                            </div>
                        </div>
                    </article>  

                }

                {
                    this.state.selectedStep.id === 1 &&
                    this.state.cards
                }

                
                {
                    this.state.selectedStep.id === 2 &&
                    this.state.cards
                }

                {this.state.selectedStep.id === 3 &&
                    <article className="slds-card">
                        <div className="slds-card__header slds-grid">
                            <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                
                                <div className="slds-media__body">
                                    <h2 className="slds-card__header-title">
                                        {this.state.firstName} {this.state.lastName} has been successfully checked in! Press 'Proceed' to continue.
                                    </h2>
                                </div>
                                
                                <div className="slds-no-flex">
                                    <Button onClick={this.props.toggleSearch}>Proceed</Button>
                                </div>
                            </div>
                        </div>
                    </article>
                }

                <br/>
                <hr/>
                
                <IconSettings iconPath="/assets/icons">
                    <div>
                        <ProgressIndicator
                            id="example-progress-indicator"
                            completedSteps={this.state.completedSteps}
                            disabledSteps={this.state.disabledSteps}
                            onStepClick={this.handleStepEvent}
                            steps={this.state.steps}
                            selectedStep={this.state.selectedStep}
                        />
                        <div style={{"textAlign":"center"}}>{this.state.selectedStep.label}</div>
                    </div>
                </IconSettings>
                <br/>
                
            </div>

        )
    }
}