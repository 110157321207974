import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Button from '@salesforce/design-system-react/lib/components/button';
import Input from '@salesforce/design-system-react/lib/components/input';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Accordion from '@salesforce/design-system-react/lib/components/accordion'; 
import AccordionPanel from '@salesforce/design-system-react/lib/components/accordion/panel';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Dropdown from '@salesforce/design-system-react/lib/components/menu-dropdown'; 
import APIWorker from '../APIWorker';

const options = [
    {
        id: 'Yes',
        label: 'Yes',
    },
    {
        id: 'No',
        label: 'No',
    },
    {
        id: 'N/A',
        label: 'N/A'
    }
];

const statusOptions = [
    {
        id: 'Pass',
        label: 'Pass'
    },
    {
        id: 'Fail',
        label: 'Fail'
    }
]

const fuelSources = [
    {
        id: 'Propane',
        label: 'Propane'
    },
    {
        id: 'Electric',
        label: 'Electric'
    },
    {
        id: 'Gas/Diesel',
        label: 'Gas/Diesel'
    }
]

export default class LiftTruckForm extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            evalDeviceList: [],
            selectedDevices: [],
            selectedDevicesString: '',
            deviceListErrorMessage: '',
            liftCapacity: '',
            liftCapacityErrorMessage: '',
            selectedFuelSources: [],
            selectedFuelSourcesString: '',
            fuelSourceErrorMessage: '',
            status: '',
            statusErrorMessage: '',
            failureReason: '',
            failureReasonErrorMessage : '',
            evaluationComments: '',
            commentErrorMessage : '',
            recordTypeId: '',
            answer1 : 'Yes',
            answer2: 'Yes',
            answer3: 'Yes',
            answer4: 'Yes',
            answer5: 'Yes',
            answer6: 'Yes',
            answer7: 'Yes',
            answer8: 'Yes',
            answer9: 'Yes',
            answer10: 'Yes',
            answer11: 'Yes',
            answer12: 'Yes',
            answer13: 'Yes',
            answer14: 'Yes',
            answer15: 'Yes',
            answer16: 'Yes',
            answer17: 'Yes',
            answer18: 'Yes',
            answer19: 'Yes',
            answer20: 'Yes',
            answer21: 'Yes',
            answer22: 'Yes',
            answer23: 'Yes',
            answer24: 'Yes',
            answer25: 'Yes',
            answer26: 'Yes',
            answer27: 'Yes',
            answer28: 'Yes',
            answer29: 'Yes',
            answer30: 'Yes',
            answer31: 'Yes',
            answer32: 'Yes',
            answer33: 'Yes',
            answer34: 'Yes',
            answer35: 'Yes',
            answer36: 'Yes',
            answer37: 'Yes',
            answer38: 'Yes',
            answer39: 'Yes',
            answer40: 'Yes'
        }

        this.validateInformation = this.validateInformation.bind(this);
        this.submitEvaluation = this.submitEvaluation.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount(){
        //Populate the devices from the eval product
        var productDeviceList = (this.props.sa.Service_Appointment__r.Product__r.Devices__c).split(';');
        var evalDeviceList = [];
        productDeviceList.map((device) => {
            return evalDeviceList.push(
                {id: device, label:device}
            );
        });

        //retrieve the record type id
        let json = {
            "EvalRecordTypeName" : this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c
        };

        APIWorker.getEvaluationRecordType(json)
        .then(res => {
            var recordTypeId = res.data[0].Id;
            this.setState({
                recordTypeId,
                evalDeviceList
            })
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    clearErrors(){
        this.setState({
            commentErrorMessage : '',
            failureReasonErrorMessage: '',
            statusErrorMessage : '',
            deviceListErrorMessage: '',
            fuelSourceErrorMessage: '',
            liftCapacityErrorMessage : ''
        });
    }

    validateInformation(){
        this.showSpinner();
        this.clearErrors();

        //If any answer set to No, require an evaluation comment
        for(var i = 1; i < 41; i++){
            var answerName = 'answer' + i;
            if(this.state[answerName] === 'No' && this.state.evaluationComments === ''){
                this.setState({
                    commentErrorMessage : 'Evaluation comments required if any criteria was set to "No"!'
                });
                this.hideSpinner();
                return;
            }
        }

        if(this.state.status === ''){
            this.setState({
                statusErrorMessage : 'Need to set either pass or fail for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //If evaluation set to fail, failure reason is required
        if(this.state.status === 'Fail' && this.state.failureReason === ''){
            this.setState({
                failureReasonErrorMessage : 'Failure reason required if evaluation set to "Fail"!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedDevicesString === ''){
            this.setState({
                deviceListErrorMessage : 'Need to select device(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedFuelSourcesString === ''){
            this.setState({
                fuelSourceErrorMessage : 'Need to select fuel source(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.liftCapacity === ''){
            this.setState({
                liftCapacityErrorMessage : 'Need to set a lift capacity for each device for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //if all criteria met, submitEvaluation
        this.submitEvaluation();
    }

    submitEvaluation(){
        let json = {
            "RecordTypeId" : this.state.recordTypeId,
            "Status__c" : this.state.status,
            "Reason_for_Failure__c" : this.state.failureReason,
            "Evaluation_Comments__c" : this.state.evaluationComments,
            "Service_Appointment__c" : this.props.sa.Service_Appointment__c,
            "Attendee__c" : this.props.sa.Id,
            "Attendee_Contact__c" : this.props.sa.Contact__c,
            "Lift_Device__c" : this.state.selectedDevicesString,
            "Fuel_Source__c" : this.state.selectedFuelSourcesString,
            "Lift_Device_Capacity__c" : this.state.liftCapacity,

            "Performs_complete_pre_operational_check__c" : this.state.answer1,
            "Performs_practical_pre_operational_check__c" : this.state.answer2,
            "Wears_Seatbelt_if_equipped__c" : this.state.answer3,
            "Wears_appropriate_PPE_for_environment__c" : this.state.answer4,
            "Operates_controls_smoothly_correct_seq__c" : this.state.answer5,
            "Always_faces_proper_direction_of_travel__c" : this.state.answer6,
            "Operates_Safely__c" : this.state.answer7,
            "Forks_set_in_proper_travel_position__c" : this.state.answer8,
            "Honks_horn_makes_eye_contact_w_peds__c" : this.state.answer9,
            "Honks_horn_at_blind_corners_intersect__c" : this.state.answer10,
            "Proper_travel_speed_for_conditions__c" : this.state.answer11,
            "Approaches_load_squarely__c" : this.state.answer12,
            "Engages_load_properly__c" : this.state.answer13,
            "Travels_in_the_proper_direction_for_load__c" : this.state.answer14,
            "Check_over_shoulders_prior_to_reversing__c" : this.state.answer15,
            "Approaches_rack_squarely__c" : this.state.answer16,
            "Load_raised_smoothly_no_contact_any_obj__c" : this.state.answer17,
            "Adj_fork_angle_prior_to_depositing_load__c" : this.state.answer18,
            "Forks_load_does_not_contact_rack__c" : this.state.answer19,
            "Check_over_shoulders_prior_to_reversing1__c" : this.state.answer20,
            "Removes_forks_without_moving_the_skid__c" : this.state.answer21,
            "Forks_do_not_contact_rack_objects__c" : this.state.answer22,
            "Truck_does_not_proceed_until_forks_low__c" : this.state.answer23,
            "Approaches_rack_squarely1__c" : this.state.answer24,
            "Mast_raised_smoothly_no_contact_w_obj__c" : this.state.answer25,
            "Fork_angle_adj_properly_prior_to_load__c" : this.state.answer26,
            "Load_engaged_smoothly_lifted_to_clear__c" : this.state.answer27,
            "Check_over_shoulders_prior_to_reversing2__c" : this.state.answer28,
            "Load_tilted_back_to_stabilize_reverses__c" : this.state.answer29,
            "Load_lowered_smoothly_no_contact_w_obj__c" : this.state.answer30,
            "Truck_not_proceed_until_load_low_travel__c" : this.state.answer31,
            "Checks_floor_condition_of_trailer_prior__c" : this.state.answer32,
            "Ensures_appropriate_lighting_present__c" : this.state.answer33,
            "Ensures_trailer_wheelchocks_are_in_place__c" : this.state.answer34,
            "Honks_horn_prior_to_exiting_trailer__c" : this.state.answer35,
            "Travels_in_proper_direction_on_ramp__c" : this.state.answer36,
            "Understands_procedures_entering_elevator__c" : this.state.answer37,
            "Understands_procedure_when_lifting_pers__c" : this.state.answer38,
            "Wears_PPE_when_changing_propane_cylinder__c" : this.state.answer39,
            "Wears_PPE_when_inspecting_battery__c" : this.state.answer40
        };

        APIWorker.createEvaluation(json)
        .then(res => {
            let attendeeJson = {
                "Status__c" : "Confirmed",
                "Check_in_Time__c" : new Date().toISOString()
            };

            APIWorker.updateAttendee(this.props.attendeeId, attendeeJson)
            .then(res => {
                this.hideSpinner();
                this.closeModal();
                this.context.showSuccessAlert('Lift Truck Evaluation submitted!');
            })
            .catch(error => {
                this.showErrorAlert();
            });
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    closeModal(){
        this.context.changeModal('');
    }

    render(){
        return(
            <div>
                <article className="slds-card slds-align_absolute-center">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.sa.Contact__r.FirstName} {this.props.sa.Contact__r.LastName}</b>
                                </h2>
                                <span>{this.props.sa.Company__c}</span>
                            </div>  
                        </div>
                    </div>
                </article>

                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">                         
                            <div className="slds-media__body">
                                <IconSettings iconPath="/assets/icons">
                                    <Accordion>
                                        <AccordionPanel expanded={true} id={0} key={0} summary="Device Details">

                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedDevicesString = '';
                                                        data.selection.map((device) => {
                                                            selectedDevicesString += (device.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedDevices: data.selection,
                                                            selectedDevicesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Device(s)'
                                                }}
                                                multiple
                                                errorText={this.state.deviceListErrorMessage}
                                                options={this.state.evalDeviceList}
                                                selection={this.state.selectedDevices}
                                                variant="readonly"
                                            />               

                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedFuelSourcesString = '';
                                                        data.selection.map((fuelSource) => {
                                                            selectedFuelSourcesString += (fuelSource.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedFuelSources: data.selection,
                                                            selectedFuelSourcesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Fuel Source(s)'
                                                }}
                                                multiple
                                                errorText={this.state.fuelSourceErrorMessage}
                                                options={fuelSources}
                                                selection={this.state.selectedFuelSources}
                                                variant="readonly"
                                            />

                                            <Input
                                                label="Lift Capacity"
                                                placeholder="ex. 1000 lbs"
                                                type="text"
                                                errorText={this.state.liftCapacityErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        liftCapacity : data.value
                                                    });
                                                }}
                                                value={this.state.liftCapacity}
                                            />
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={1} key={1} summary="Pre-Operational Check (Visual)">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer1 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '1. Performs complete pre-operational check'
                                                }}
                                                selection={[{id:this.state.answer1, label:this.state.answer1}]}
                                                value={this.state.answer1}
                                                options={options}
                                                variant="readonly"
                                            />            
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={2} key={2} summary="Pre-Operational Check (Practical)">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer2 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '2. Performs practical pre-operational check'
                                                }}
                                                selection={[{id:this.state.answer2, label:this.state.answer2}]}
                                                value={this.state.answer2}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                          
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={3} key={3} summary="Observations of Practices">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer3 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '3. Wears Seatbelt (if equipped)'
                                                }}
                                                selection={[{id:this.state.answer3, label:this.state.answer3}]}
                                                value={this.state.answer3}
                                                options={options}
                                                variant="readonly"
                                            />  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer4 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '4. Wears appropriate PPE for environment'
                                                }}
                                                selection={[{id:this.state.answer4, label:this.state.answer4}]}
                                                value={this.state.answer4}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer5 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '5. Operates controls smoothly & correct'
                                                }}
                                                selection={[{id:this.state.answer5, label:this.state.answer5}]}
                                                value={this.state.answer5}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer6 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '6. Always faces proper direction of travel'
                                                }}
                                                selection={[{id:this.state.answer6, label:this.state.answer6}]}
                                                value={this.state.answer6}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer7 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '7. Operates Safely'
                                                }}
                                                selection={[{id:this.state.answer7, label:this.state.answer7}]}
                                                value={this.state.answer7}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={4} key={4} summary="Driving Without a Load">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer8 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '8. Forks set in proper travel position'
                                                }}
                                                selection={[{id:this.state.answer8, label:this.state.answer8}]}
                                                value={this.state.answer8}
                                                options={options}
                                                variant="readonly"
                                            />             

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer9 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '9. Honks horn & makes eye contact with pedestrians'
                                                }}
                                                selection={[{id:this.state.answer9, label:this.state.answer9}]}
                                                value={this.state.answer9}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer10 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '10. Honks horn at blind corners & intersect.'
                                                }}
                                                selection={[{id:this.state.answer10, label:this.state.answer10}]}
                                                value={this.state.answer10}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer11 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '11. Proper travel speed for conditions'
                                                }}
                                                selection={[{id:this.state.answer11, label:this.state.answer11}]}
                                                value={this.state.answer11}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={5} key={5} summary="Driving With Loads">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer12 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '12. Approaches load squarely'
                                                }}
                                                selection={[{id:this.state.answer12, label:this.state.answer12}]}
                                                value={this.state.answer12}
                                                options={options}
                                                variant="readonly"
                                            />       

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer13 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '13. Engages load properly'
                                                }}
                                                selection={[{id:this.state.answer13, label:this.state.answer13}]}
                                                value={this.state.answer13}
                                                options={options}
                                                variant="readonly"
                                            />         

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer14 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '14. Travels in the proper direction for load'
                                                }}
                                                selection={[{id:this.state.answer14, label:this.state.answer14}]}
                                                value={this.state.answer14}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer15 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '15. Check over shoulders prior to reversing'
                                                }}
                                                selection={[{id:this.state.answer15, label:this.state.answer15}]}
                                                value={this.state.answer15}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                                                                                                                                                                            
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={6} key={6} summary="Stacking">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer16 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '16. Approaches rack squarely'
                                                }}
                                                selection={[{id:this.state.answer16, label:this.state.answer16}]}
                                                value={this.state.answer16}
                                                options={options}
                                                variant="readonly"
                                            />           

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer17 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '17. Load raised smoothly, no contact any object'
                                                }}
                                                selection={[{id:this.state.answer17, label:this.state.answer17}]}
                                                value={this.state.answer17}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer18 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '18. Adjust fork angle prior to depositing load'
                                                }}
                                                selection={[{id:this.state.answer18, label:this.state.answer18}]}
                                                value={this.state.answer18}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer19 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '19. Forks/load does not contact rack'
                                                }}
                                                selection={[{id:this.state.answer19, label:this.state.answer19}]}
                                                value={this.state.answer19}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer20 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '20. Check over shoulders prior to reversing'
                                                }}
                                                selection={[{id:this.state.answer20, label:this.state.answer20}]}
                                                value={this.state.answer20}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer21 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '21. Removes forks without moving the skid'
                                                }}
                                                selection={[{id:this.state.answer21, label:this.state.answer21}]}
                                                value={this.state.answer21}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer22 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '22. Forks do not contact rack/objects'
                                                }}
                                                selection={[{id:this.state.answer22, label:this.state.answer22}]}
                                                value={this.state.answer22}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer23 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '23. Truck does not proceed until forks low'
                                                }}
                                                selection={[{id:this.state.answer23, label:this.state.answer23}]}
                                                value={this.state.answer23}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                                                                                                                           
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={7} key={7} summary="De-Stacking">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer24 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '24. Approaches rack squarely'
                                                }}
                                                selection={[{id:this.state.answer24, label:this.state.answer24}]}
                                                value={this.state.answer24}
                                                options={options}
                                                variant="readonly"
                                            />                

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer25 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '25. Mast raised smoothly, no contact with object'
                                                }}
                                                selection={[{id:this.state.answer25, label:this.state.answer25}]}
                                                value={this.state.answer25}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer26 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '26. Fork angle adjust properly prior to load'
                                                }}
                                                selection={[{id:this.state.answer26, label:this.state.answer26}]}
                                                value={this.state.answer26}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer27 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '27. Load engaged smoothly & lifted to clear'
                                                }}
                                                selection={[{id:this.state.answer27, label:this.state.answer27}]}
                                                value={this.state.answer27}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer28 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '28. Check over shoulders prior to reversing'
                                                }}
                                                selection={[{id:this.state.answer28, label:this.state.answer28}]}
                                                value={this.state.answer28}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer29 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '29. Load tilted back to stabilize, reverses'
                                                }}
                                                selection={[{id:this.state.answer29, label:this.state.answer29}]}
                                                value={this.state.answer29}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer30 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '30. Load lowered smoothly, no contact with object'
                                                }}
                                                selection={[{id:this.state.answer30, label:this.state.answer30}]}
                                                value={this.state.answer30}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer31 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '31. Truck does not proceed until load low travel'
                                                }}
                                                selection={[{id:this.state.answer31, label:this.state.answer31}]}
                                                value={this.state.answer31}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                                                                                                                                                                                                                                      
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={8} key={8} summary="Loading Docks (if applicable)">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer32 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '32. Checks floor condition of trailer prior'
                                                }}
                                                selection={[{id:this.state.answer32, label:this.state.answer32}]}
                                                value={this.state.answer32}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer33 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '33. Ensures appropriate lighting present'
                                                }}
                                                selection={[{id:this.state.answer33, label:this.state.answer33}]}
                                                value={this.state.answer33}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer34 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '34. Ensures trailer wheelchocks are in place'
                                                }}
                                                selection={[{id:this.state.answer34, label:this.state.answer34}]}
                                                value={this.state.answer34}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer35 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '35. Honks horn prior to exiting trailer'
                                                }}
                                                selection={[{id:this.state.answer35, label:this.state.answer35}]}
                                                value={this.state.answer35}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                         
                                        </AccordionPanel>         

                                        <AccordionPanel expanded={true} id={9} key={9} summary="Miscellaneous (if applicable)">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer36 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '36. Travels in proper direction on ramp'
                                                }}
                                                selection={[{id:this.state.answer36, label:this.state.answer36}]}
                                                value={this.state.answer36}
                                                options={options}
                                                variant="readonly"
                                            />         

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer37 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '37. Understands procedures entering elevator'
                                                }}
                                                selection={[{id:this.state.answer37, label:this.state.answer37}]}
                                                value={this.state.answer37}
                                                options={options}
                                                variant="readonly"
                                            />     

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer38 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '38. Understands procedure when lifting pers'
                                                }}
                                                selection={[{id:this.state.answer38, label:this.state.answer38}]}
                                                value={this.state.answer38}
                                                options={options}
                                                variant="readonly"
                                            />                                                                                                                                 
                                        </AccordionPanel>            

                                        <AccordionPanel expanded={true} id={10} key={10} summary="Fuel Sources">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer39 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '39. Wears PPE when changing propane cylinder'
                                                }}
                                                selection={[{id:this.state.answer39, label:this.state.answer39}]}
                                                value={this.state.answer39}
                                                options={options}
                                                variant="readonly"
                                            />                  

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            answer40 : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '40. Wears PPE when inspecting battery'
                                                }}
                                                selection={[{id:this.state.answer40, label:this.state.answer40}]}
                                                value={this.state.answer40}
                                                options={options}
                                                variant="readonly"
                                            />                                                                            
                                        </AccordionPanel>                                                                                                             

                                        <AccordionPanel expanded={true} id={11} key={11} summary="Evaluation Status">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        if(data.selection.length > 0){
                                                            var failureReason = this.state.failureReason;
                                                            if(data.selection[0].id == 'Pass'){
                                                                failureReason = '';
                                                            }
                                                            this.setState({
                                                                status : data.selection[0].id,
                                                                failureReason
                                                            });
                                                        }
                                                    }
                                                }}                                
                                                labels={{
                                                    label: 'Status (Pass/Fail)'
                                                }}
                                                errorText={this.state.statusErrorMessage}
                                                selection={[{id:this.state.status, label:this.state.status}]}
                                                value={this.state.status}
                                                options={statusOptions}
                                                variant="readonly"
                                            />
                                            
                                            {
                                                this.state.status == 'Fail' &&
                                                <Input
                                                    label="Reason for Failure"
                                                    placeholder="ex. Failure reason"
                                                    type="text"
                                                    errorText={this.state.failureReasonErrorMessage}
                                                    onChange={(event,data) => {
                                                        this.setState({
                                                            failureReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.failureReason}
                                                />
                                            }

                                            <Input
                                                label="Evaluation comments"
                                                placeholder="ex. comments"
                                                type="text"
                                                errorText={this.state.commentErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        evaluationComments : data.value
                                                    });
                                                }}
                                                value={this.state.evaluationComments}
                                            />
                                        </AccordionPanel>                                           
                                    </Accordion>
                                </IconSettings>
                                                                                                                                                   

                            </div>
                        </div>
                    </div>
                </article>

                <article className="slds-card slds-align_absolute-center">
                    <Button key="1" variant="brand" label="Submit Evaluation" onClick={() => this.validateInformation()}/>
                </article>
            </div>            
        )
    }
}