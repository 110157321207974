import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Button from '@salesforce/design-system-react/lib/components/button';
import Input from '@salesforce/design-system-react/lib/components/input';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Accordion from '@salesforce/design-system-react/lib/components/accordion'; 
import AccordionPanel from '@salesforce/design-system-react/lib/components/accordion/panel';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import Dropdown from '@salesforce/design-system-react/lib/components/menu-dropdown'; 
import APIWorker from '../APIWorker';

const options = [
    {
        id: 'Yes',
        label: 'Yes',
    },
    {
        id: 'No',
        label: 'No',
    },
    {
        id: 'N/A',
        label: 'N/A'
    }
];

const statusOptions = [
    {
        id: 'Pass',
        label: 'Pass'
    },
    {
        id: 'Fail',
        label: 'Fail'
    }
]

const sizes = [
    {
        id: 'Small',
        label: 'Small'
    },
    {
        id: 'Medium',
        label: 'Medium'
    },
    {
        id: 'Large',
        label: 'Large'
    }
]

const protocols = [
    {
        id: 'Saccharin',
        label: 'Saccharin'
    },
    {
        id: 'Bitter',
        label: 'Bitter'
    },
    {
        id: 'Smoke',
        label: 'Smoke'
    },
    {
        id: 'Banana Oil',
        label: 'Banana Oil'
    }
]

export default class QualitativeRespiratorForm extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            evalDeviceList: [],
            selectedDevices: [],
            selectedDevicesString: '',
            deviceListErrorMessage: '',
            status: '',
            statusErrorMessage: '',
            failureReason: '',
            failureReasonErrorMessage : '',
            evaluationComments: '',
            commentErrorMessage : '',
            recordTypeId: '',
            manufacturer: '',
            manufacturerErrorMessage: '',
            model: '',
            modelErrorMessage : '',
            size: '',
            sizeErrorMessage: '',
            qlftProtocol: '',
            qlftProtocolErrorMessage: ''
        }

        this.validateInformation = this.validateInformation.bind(this);
        this.submitEvaluation = this.submitEvaluation.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount(){
        //Populate the devices from the eval product
        var productDeviceList = (this.props.sa.Service_Appointment__r.Product__r.Devices__c).split(';');
        var evalDeviceList = [];
        productDeviceList.map((device) => {
            return evalDeviceList.push(
                {id: device, label:device}
            );
        });

        //retrieve the record type id
        let json = {
            "EvalRecordTypeName" : this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c
        };

        APIWorker.getEvaluationRecordType(json)
        .then(res => {
            var recordTypeId = res.data[0].Id;
            this.setState({
                recordTypeId,
                evalDeviceList
            })
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    clearErrors(){
        this.setState({
            commentErrorMessage : '',
            failureReasonErrorMessage: '',
            statusErrorMessage : '',
            deviceListErrorMessage: '',
            manufacturerErrorMessage: '',
            modelErrorMessage: '',
            sizeErrorMessage: '',
            qlftProtocolErrorMessage: ''
        });
    }

    validateInformation(){
        this.showSpinner();
        this.clearErrors();

        if(this.state.manufacturer === ''){
            this.setState({
                manufacturerErrorMessage : 'Need to enter respirator manufacturer!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.model === ''){
            this.setState({
                modelErrorMessage : 'Need to enter respirator model!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.size === ''){
            this.setState({
                sizeErrorMessage : 'Need to select respirator size!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.qlftProtocol === ''){
            this.setState({
                qlftProtocolErrorMessage : 'Need to select QLFT protocol!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.status === ''){
            this.setState({
                statusErrorMessage : 'Need to set either pass or fail for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //If evaluation set to fail, failure reason is required
        if(this.state.status === 'Fail' && this.state.failureReason === ''){
            this.setState({
                failureReasonErrorMessage : 'Failure reason required if evaluation set to "Fail"!'
            });
            this.hideSpinner();
            return;
        }

        if(this.state.selectedDevicesString === ''){
            this.setState({
                deviceListErrorMessage : 'Need to select device(s) for evaluation!'
            });
            this.hideSpinner();
            return;
        }

        //if all criteria met, submitEvaluation
        this.submitEvaluation();
    }

    submitEvaluation(){
        let json = {
            "RecordTypeId" : this.state.recordTypeId,
            "Status__c" : this.state.status,
            "Reason_for_Failure__c" : this.state.failureReason,
            "Evaluation_Comments__c" : this.state.evaluationComments,
            "Service_Appointment__c" : this.props.sa.Service_Appointment__c,
            "Attendee__c" : this.props.sa.Id,
            "Attendee_Contact__c" : this.props.sa.Contact__c,
            "Lift_Device__c" : this.state.selectedDevicesString,

            "Respirator_Manufacturer__c" : this.state.manufacturer,
            "Respirator_Model__c" : this.state.model,
            "Respirator_QLFT_Protocol__c" : this.state.qlftProtocol,
            "Respirator_Size__c" : this.state.size
        };

        APIWorker.createEvaluation(json)
        .then(res => {
            let attendeeJson = {
                "Status__c" : "Confirmed",
                "Check_in_Time__c" : new Date().toISOString()
            };

            APIWorker.updateAttendee(this.props.attendeeId, attendeeJson)
            .then(res => {
                this.hideSpinner();
                this.closeModal();
                this.context.showSuccessAlert('(Qualitative) Respirator Fit Test submitted!');
            })
            .catch(error => {
                this.showErrorAlert();
            });
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    closeModal(){
        this.context.changeModal('');
    }

    render(){
        return(
            <div>
                <article className="slds-card slds-align_absolute-center">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.sa.Contact__r.FirstName} {this.props.sa.Contact__r.LastName}</b>
                                </h2>
                                <span>{this.props.sa.Company__c}</span>
                            </div>  
                        </div>
                    </div>
                </article>

                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">                         
                            <div className="slds-media__body">
                                <IconSettings iconPath="/assets/icons">
                                    <Accordion>
                                        <AccordionPanel expanded={true} id={0} key={0} summary="Device Details">
                                            <Combobox
                                                events={{
                                                    onSelect: (event, data) => {
                                                        var selectedDevicesString = '';
                                                        data.selection.map((device) => {
                                                            selectedDevicesString += (device.id + ';') 
                                                        });
                                                        this.setState({
                                                            selectedDevices: data.selection,
                                                            selectedDevicesString
                                                        });
                                                    }
                                                }}
                                                labels={{
                                                    label: 'Select Device(s)'
                                                }}
                                                errorText={this.state.deviceListErrorMessage}
                                                options={this.state.evalDeviceList}
                                                selection={this.state.selectedDevices}
                                                variant="readonly"
                                            />
                                        </AccordionPanel>

                                        <AccordionPanel expanded={true} id={1} key={1} summary="Respirator Equipment">
                                            <Input
                                                label="1. Respirator Manufacturer"
                                                placeholder="ex. 3M"
                                                type="text"
                                                errorText={this.state.manufacturerErrorMessage}
                                                onChange={(event,data) => {
                                                    this.setState({
                                                        manufacturer : data.value
                                                    });
                                                }}
                                                value={this.state.manufacturer}
                                            />         

                                            <Input
                                                label="2. Respirator Model"
                                                placeholder="ex. 8210"
                                                type="text"
                                                errorText={this.state.modelErrorMessage}
                                                onChange={(event,data) => {
                                                    this.setState({
                                                        model : data.value
                                                    });
                                                }}
                                                value={this.state.model}
                                            />                                                                                   
                                            
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            size : data.selection[0].id
                                                        });
                                                    }
                                                }}                                
                                                labels={{
                                                    label: '3. Respirator Size'
                                                }}
                                                selection={[{id:this.state.size, label:this.state.size}]}
                                                value={this.state.size}
                                                options={sizes}
                                                variant="readonly"
                                            />            

                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        this.setState({
                                                            qlftProtocol : data.selection[0].id
                                                        });
                                                    }
                                                }}                                  
                                                labels={{
                                                    label: '4. Respirator QLFT Protocol'
                                                }}
                                                selection={[{id:this.state.qlftProtocol, label:this.state.qlftProtocol}]}
                                                value={this.state.qlftProtocol}
                                                options={protocols}
                                                variant="readonly"
                                            />                                                                                             
                                        </AccordionPanel>
                                       
                                        <AccordionPanel expanded={true} id={2} key={2} summary="Evaluation Status">
                                            <Combobox
                                                events={{
                                                    onSelect : (event, data) => {
                                                        if(data.selection.length > 0){
                                                            var failureReason = this.state.failureReason;
                                                            if(data.selection[0].id == 'Pass'){
                                                                failureReason = '';
                                                            }
                                                            this.setState({
                                                                status : data.selection[0].id,
                                                                failureReason
                                                            });
                                                        }
                                                    }
                                                }}                                
                                                labels={{
                                                    label: 'Status (Pass/Fail)'
                                                }}
                                                errorText={this.state.statusErrorMessage}
                                                selection={[{id:this.state.status, label:this.state.status}]}
                                                value={this.state.status}
                                                options={statusOptions}
                                                variant="readonly"
                                            />
                                            
                                            {
                                                this.state.status == 'Fail' &&
                                                <Input
                                                    label="Reason for Failure"
                                                    placeholder="ex. Failure reason"
                                                    type="text"
                                                    errorText={this.state.failureReasonErrorMessage}
                                                    onChange={(event,data) => {
                                                        this.setState({
                                                            failureReason : data.value
                                                        });
                                                    }}
                                                    value={this.state.failureReason}
                                                />
                                            }

                                            <Input
                                                label="Evaluation comments"
                                                placeholder="ex. comments"
                                                type="text"
                                                errorText={this.state.commentErrorMessage}
                                                onChange={(event, data) => {
                                                    this.setState({
                                                        evaluationComments : data.value
                                                    });
                                                }}
                                                value={this.state.evaluationComments}
                                            />
                                        </AccordionPanel>                                              
                                    </Accordion>
                                </IconSettings>
                                                                                                                                                   

                            </div>
                        </div>
                    </div>
                </article>

                <article className="slds-card slds-align_absolute-center">
                    <Button key="1" variant="brand" label="Submit Evaluation" onClick={() => this.validateInformation()}/>
                </article>
            </div>            
        )
    }
}