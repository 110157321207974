import React from 'react';
import {FooterContext} from '../TrainingProvider';

//Custom view of a service appointment detailed card
export default class FeedbackCard extends React.Component{
    static contextType = FooterContext;

    constructor(props){        
        super(props);

        //Initial state
        this.state = {
            show : true
        }

        //binding functions
    }

    componentDidMount(){
        this.context.changeFooter('');
    }
    

    //this.context.changeFooter either uses Home if showingDetails or props.Object if not
    changeContext(action){
        this.context.changeFooter(action);
        this.props.toggleDetails(this.props.record.Service_Appointment__c);
    }

    render(){
        if(!this.state.show) 
            return null;

        return(
            <article className="slds-card" onClick={() => this.changeContext(this.props.Action)}>
                <div className="slds-card__header slds-grid">
                    <div className="slds-media slds-media_center slds-has-flexi-truncate">
                        <div className="slds-media__figure">
                            <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-${this.props.Icon}`} title="feedback">
                                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                    <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#${this.props.SVGSymbol}`}></use>
                                </svg>
                            </span>
                        </div>
                        <div className="slds-media__body">
                            <h2 className="slds-card__header-title">
                                <div className="slds-card__header-link slds-truncate" title="Feedback List">
                                    <span>Attendee Feedback</span>
                                </div>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="slds-card__body slds-card__body_inner">{'Overall Experience: ' + this.props.record.Overall_Experience__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Instructor Rating: ' + this.props.record.Instructor__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Course Engaging: ' + this.props.record.Course_Engaging__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Increased Knowledge: ' + this.props.record.Increased_Knowledge__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Instructor Knowledgable: ' + this.props.record.Instructor_Knowledgable__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Instructor Engaging: ' + this.props.record.Instructor_Engaging__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Relevant Examples: ' + this.props.record.Relevant_Examples__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Questions and Discussions: ' + this.props.record.Questions_And_Discussions__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Recommend OSG: ' + this.props.record.Recommend_OSG__c}</div>
                <div className="slds-card__body slds-card__body_inner">{'Additional Feedback: ' + this.props.record.Additional_Feedback__c}</div>
            </article>
        )
    }
}