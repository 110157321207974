import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';
import SearchAttendeeComponent from './SearchAttendeeComponent';
import CheckInComponent from './CheckInComponent';
import ConfirmCheckInComponent from './ConfirmCheckInComponent';



export default class AddAttendeeModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            cardDetailId : this.props.cardDetailId,
            showPrompt : false,
            attendeeId : '',
            data: [],
            cards : [],
            footer : [],
            searchAttendee : false,
            disabled : false
        }

        this.refreshData = this.refreshData.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.generateLayout = this.generateLayout.bind(this);
        this.generateFooter = this.generateFooter.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.toggleDetails = this.toggleDetails.bind(this);
        this.showPrompt = this.showPrompt.bind(this);
    }

    componentDidMount(){
        this.refreshData();
    }
    
    componentDidUpdate(oldProps){

    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }


    generateFooter(){
        let footer = [];
        if(this.state.attendeeId === '' && !this.state.searchAttendee){
            footer = [
                <Button key="1" label="Cancel" onClick={() => this.context.changeModal('')} />,
                <Button key="2" disabled={this.state.disabled} label="Add Attendee" onClick={() => this.toggleSearch()} variant="brand"/>
            ];
        }
        else{
            footer = [ 
                <Button key="1" label="Cancel" onClick={() => this.context.changeModal('')} />
            ];
        }

        this.setState({
            footer
        });
    }
    
    refreshData(){
        this.showSpinner();

        if(this.state.attendeeId !== ''){
            APIWorker.getAttendee(this.state.attendeeId)
            .then(res => {
                this.setState({
                    data : res.data
                },
                    () => {
                        this.generateLayout();
                        this.generateFooter();
                        this.hideSpinner();
                    }
                );
            })
            .catch(error => {
                this.showErrorAlert();
            });
        }
        else{
            APIWorker.getAttendees(this.state.cardDetailId)
            .then(res => {
                this.setState({
                    data : res.data
                },
                    () => {
                        //Get the true capacity of the course and compare against the number of attendees included in course
                        let numberOfAttendees = this.state.data.filter(function(item){
                            if (item.Confirmed__c === "Yes") {
                              return true;
                            } else {
                              return false;
                            }
                          }).length;
                        let courseCapacity = this.props.sa.Course_Hard_Capacity__c;
                        let disabled = false;

                        if(courseCapacity === numberOfAttendees && courseCapacity !== 0){
                            disabled = true;
                        }

                        if(this.props.sa.Product__r.Family === 'Evaluations'){
                            if(this.props.sa.Product__r.Course_Product_Line__c !== 'RFTE'){
                                disabled = true;
                            }
                        }
    
                        this.setState({
                            disabled
                        }, 
                            () => {
                                this.generateLayout();
                                this.generateFooter();
                                this.hideSpinner();
                            }
                        )
                    }
                );
            })
            .catch(error => {
                this.showErrorAlert();
            });
        }
    }

    toggleSearch(){
        let searchAttendee = !this.state.searchAttendee;
        this.setState({
            searchAttendee
        }, 
            () => {
                if(searchAttendee === true){
                    this.generateLayout();
                    this.generateFooter();
                    this.hideSpinner();
                }
                else{
                    this.refreshData();
                }
            }
        );
    }

    toggleDetails(attendeeId){
        this.setState({
            attendeeId,
        },
            () => {
                this.refreshData();
            }
        );
    }

    showPrompt(){
        this.setState({
            showPrompt : true
        });

        setTimeout(() => {
            this.setState({
                showPrompt : false
            });
        }, 2500);
    }

    generateLayout(){
        let cards = [];

        if(this.state.searchAttendee === true){
            cards.push(
                <SearchAttendeeComponent key="search" sa={this.props.sa} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} toggleSearch={this.toggleSearch}/>
            );
        }
        else{
            if(this.state.attendeeId !== ''){
                this.state.data.map((attendee) => {
                    return cards.push(
                        <ConfirmCheckInComponent showPrompt={this.showPrompt} toggleDetails={this.toggleDetails} key={attendee.Id} record={attendee} showSpinner={this.showSpinner} hideSpinner={this.hideSpinner} showErrorAlert={this.showErrorAlert}></ConfirmCheckInComponent>
                    );
                });
            }
            else{
                this.state.data.map((attendee) => {
                    return cards.push(
                        <CheckInComponent toggleDetails={this.toggleDetails} key={attendee.Id} record={attendee} show={false} refreshData={this.refreshData}></CheckInComponent>
                    );
                });
            }
        }

        //this.state.data.map((attendee) => {});
        this.setState({
            cards
        });
    }


    render(){
        return(
            <Modal isOpen={true} heading="Add Attendee" ariaHideApp={false} align="center" size="large" footer={this.state.footer}>
                {this.state.cards}
            </Modal>   
        )
    }
}