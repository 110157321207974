import React from "react";
import { AppContext } from "./../AppProvider";
import logo from "./../osg_logo.svg";
import IconSettings from "@salesforce/design-system-react/lib/components/icon-settings";
import Dropdown from "@salesforce/design-system-react/lib/components/menu-dropdown";
import GlobalHeaderSetup from "@salesforce/design-system-react/lib/components/global-header/setup";
import GlobalHeaderProfile from "@salesforce/design-system-react/lib/components/global-header/profile";
import Popover from "@salesforce/design-system-react/lib/components/popover";
import Button from "@salesforce/design-system-react/lib/components/button";
import GlobalNavigationBar from "@salesforce/design-system-react/lib/components/global-navigation-bar";
import GlobalNavigationBarRegion from "@salesforce/design-system-react/lib/components/global-navigation-bar/region";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appLauncherIsOpen: false,
    };

    this.closeAppLauncher = this.closeAppLauncher.bind(this);
    this.openAppLauncher = this.openAppLauncher.bind(this);
  }

  static contextType = AppContext;

  componentDidMount() {}

  reloadApp() {
    window.location.reload(true);
  }

  //Add to appLauncher props
  //isOpen={this.state.appLauncherIsOpen} triggerOnClick={() => this.openAppLauncher()}
  closeAppLauncher() {
    setTimeout(() => {
      this.setState({
        appLauncherIsOpen: false,
      });
    }, 1000);
  }

  openAppLauncher() {
    this.setState({
      appLauncherIsOpen: true,
    });
  }

  render() {
    return (
      <IconSettings iconPath="/assets/icons">
        <GlobalNavigationBar>
          <GlobalNavigationBarRegion region="primary">
            <img src={logo} alt="OSG" style={{ width: "25px" }} />

            <span style={{ fontSize: "16px", paddingTop: "6px", paddingLeft: "5px" }}>
              Trainer App&nbsp;
              <span style={{ fontWeight: "300", fontSize: "10px", color: "#777" }}>
                v1.{window.REACT_APP_BUILD_DATE.substring(window.REACT_APP_BUILD_DATE.length - 4)}
              </span>
            </span>
          </GlobalNavigationBarRegion>

          <GlobalNavigationBarRegion region="tertiary">
            <ul className="slds-global-actions" style={{ marginRight: "0.15rem" }}>
              <li className="slds-global-actions__item">
                <GlobalHeaderSetup
                  dropdown={
                    <Dropdown
                      id="header-setup-dropdown-id"
                      onSelect={(selection) => {
                        if (selection.value === "reload") {
                          this.reloadApp();
                        } else if (selection.value === "logout") {
                          this.context.logout();
                        }
                      }}
                      options={[
                        { label: "Reload app", value: "reload" },
                        { label: "Logout", value: "logout" },
                      ]}
                    />
                  }
                />
              </li>

              {/* <li className="slds-global-actions__item">
                <GlobalHeaderProfile
                  userName={"Version: " + this.context.appVersion}
                  avatar={<Avatar imgSrc={logo} />}
                  popover={
                    <Popover
                      id="header-profile-popover-id"
                      body={
                        <div>
                          <p>User: {this.context.userEmail}</p>
                        </div>
                      }
                    />
                  }
                />
              </li> */}
            </ul>
          </GlobalNavigationBarRegion>
        </GlobalNavigationBar>
      </IconSettings>
    );
  }
}
