import React from "react";

import ServiceAppointmentCard from "./ServiceAppointmentCard";

export default class ServiceAppointmentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    let cardsArr = [];

    this.props.data.map((sa) => {
      return cardsArr.push(
        <ServiceAppointmentCard data={sa} key={sa.Id} toggleDetails={this.props.toggleDetails} />
      );
    });

    return cardsArr;
  }
}

//   if (sa.hasOwnProperty("AppointmentNumber")) {
//     let startDate = new Date(sa.SchedStartTime.substr(0, 19) + "Z");
//     let endDate = new Date(sa.SchedEndTime.substr(0, 19) + "Z");

//     let contractor = sa.Contractor__c ? " | Contractor: " + sa.Contractor__c : "";

//     let trainingAddress =
//       sa.Account__r.Name +
//       " (" +
//       (sa.Training_Address1__r.Name.includes("Distance Learning")
//         ? "Distance Learning"
//         : sa.Training_Address1__r.Name) +
//       ")";

//     if (sa.IH3POS__c === "On-Site") {
//       if (sa.Product_Family__c === "Evaluations") {
//         let attendeeName =
//           sa.Attendees__r != null && sa.Attendees__r.length === 1
//             ? " | " +
//               sa.Attendees__r[0].Contact__r.FirstName +
//               " " +
//               sa.Attendees__r[0].Contact__r.LastName
//             : "";
//         return cardsArr.push(
//           <ServiceAppointmentCard
//             key={sa.Id}
//             id={sa.Id}
//             toggleDetails={this.props.toggleDetails}
//             Icon="new-note"
//             SVGSymbol="new_note"
//             Action="EvalServiceAppointment"
//             title={sa.AppointmentNumber}
//             field1={trainingAddress}
//             field2={sa.Product_Name__c}
//             field3={
//               formatDateTime(startDate) +
//               " → " +
//               formatTime(endDate) +
//               attendeeName +
//               contractor
//             }
//           />
//         );
//       } else {
//         //TODO - temporary, treat like DLIH - JHSC
//         if (sa.Product_Name__c.includes("DLOS - JHSC")) {
//           return cardsArr.push(
//             <ServiceAppointmentCard
//               key={sa.Id}
//               id={sa.Id}
//               toggleDetails={this.props.toggleDetails}
//               Icon="record"
//               SVGSymbol="record"
//               Action="ServiceAppointment"
//               title={sa.AppointmentNumber}
//               field1={trainingAddress}
//               field2={sa.Product_Name__c}
//               field3={
//                 formatDateTime(startDate) +
//                 " → " +
//                 formatTime(endDate) +
//                 " → " +
//                 endDate.toLocaleTimeString() +
//                 contractor
//               }
//             />
//           );
//         } else {
//           return cardsArr.push(
//             <ServiceAppointmentCard
//               key={sa.Id}
//               id={sa.Id}
//               toggleDetails={this.props.toggleDetails}
//               Icon="record"
//               SVGSymbol="record"
//               Action="OnSiteServiceAppointment"
//               title={sa.AppointmentNumber}
//               field1={trainingAddress}
//               field2={sa.Product_Name__c}
//               field3={formatDateTime(startDate) + " → " + formatTime(endDate) + contractor}
//             />
//           );
//         }
//       }
//     } else {
//       return cardsArr.push(
//         <ServiceAppointmentCard
//           key={sa.Id}
//           id={sa.Id}
//           toggleDetails={this.props.toggleDetails}
//           Icon="record"
//           SVGSymbol="record"
//           Action="ServiceAppointment"
//           title={sa.AppointmentNumber}
//           field1={trainingAddress}
//           field2={sa.Product_Name__c}
//           field3={formatDateTime(startDate) + " → " + formatTime(endDate) + contractor}
//         />
//       );
//     }
//   } else {
//     let startDate = new Date(sa.SchedStartTime.substr(0, 19) + "Z");
//     let endDate = new Date(sa.SchedEndTime.substr(0, 19) + "Z");

//     let dateRangeDisplay = getDateFieldWIthIcon(
//       formatDateTime(startDate) + " → " + formatDateTime(endDate)
//     );

//     return cardsArr.push(
//       <ServiceAppointmentCard
//         key={sa.Id}
//         id={sa.Id}
//         Icon="defer"
//         SVGSymbol="defer"
//         Action={null}
//         title={sa.AbsenceNumber}
//         field1={dateRangeDisplay}
//         field3={sa.Type + (sa.Description ? "/" + sa.Description : "")}
//       />
//     );
//   }
