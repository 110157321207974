import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';

//This component holds each attendees state
//If the user can still be checked in, show a button
//If the user is already checked in, show text (Checked In)

//When the user checks in, set the state via function
//When this component gets rendered again via SA->check in
//It will pass the attendee into the component and the state will be constructed via the prop


export default class CheckInComponent extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            show : this.props.show,
            isCRC : (this.props.record.Service_Appointment__r.Course_Product_Line__c.includes('CPR-C')) ? true : false,
            confirmed : this.props.record.Status__c === 'Confirmed' ? true : false,
            status : this.props.record.Status__c,
            checkInTime : this.props.record.Check_in_Time__c,
            noShowButtonVariant : 'text-destructive',
            noShowButtonText : 'No Show',
            noShowButtonConfirm : false,
            withdrawButtonVariant : 'text-destructive',
            withdrawButtonText : 'Withdraw',
            withdrawButtonConfirm : false,
            //TODO
            //if the SA is an eval allow 'Add Eval' after checking in
            //Will we need to add multiple evals per person?
            //If there is no check in time, show button, otherwise, blank
        }
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    resetWithdraw(){
        this.setState({
            withdrawButtonConfirm : false,
            withdrawButtonText : 'Withdraw',
            withdrawButtonVariant : 'text-destructive'
        })
    }

    confirmWithdraw(attendeeId){
        if(this.state.withdrawButtonConfirm){
            this.withdrawAttendee(attendeeId);
        }
        else{
            this.setState({
                withdrawButtonConfirm : true,
                withdrawButtonText : 'Confirm Withdraw',
                withdrawButtonVariant : 'destructive'
            })
        }
    }

    withdrawAttendee(attendeeId){
        this.showSpinner();
        console.log('WITHDRAW ATTENDEE: ' + attendeeId);

        let json = {
            "Confirmed__c" : "No",
            "Status__c" : "Withdrew"
        };

        APIWorker.updateAttendee(attendeeId, json)
        .then(res =>{
            this.setState({
                confirmed : false,
                status : 'Withdrew'
            },
                () => {
                    this.props.refreshData();
                    this.hideSpinner();  
                }
            );    
        })
        .catch(error => {
            this.props.showErrorAlert();
        });
    }

    resetNoShow(){
        this.setState({
            noShowButtonConfirm : false,
            noShowButtonText : 'No Show',
            noShowButtonVariant : 'text-destructive'
        })
    }

    confirmNoShow(attendeeId){
        if(this.state.noShowButtonConfirm){
            this.noShowAttendee(attendeeId);
        }
        else{
            this.setState({
                noShowButtonConfirm : true,
                noShowButtonText : 'Confirm No Show',
                noShowButtonVariant : 'destructive'
            })
        }
    }

    noShowAttendee(attendeeId){
        this.showSpinner();
        console.log('WITHDRAW ATTENDEE: ' + attendeeId);

        let json = {
            "Confirmed__c" : "No",
            "Status__c" : "No Show"
        };

        APIWorker.updateAttendee(attendeeId, json)
        .then(res =>{
            this.setState({
                confirmed : false,
                status : "No Show"
            },
                () => {
                    this.props.refreshData();
                    this.hideSpinner();  
                }
            );    
        })
        .catch(error => {
            this.props.showErrorAlert();
        });
    }



    render(){
        return(
            <div>
                <article className="slds-card">
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="attendee">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.record.Name}</b>
                                </h2>
                                <span>{this.props.record.Company__c}</span>                                
                            </div>
                            {
                                this.state.confirmed ? (    
                                    <div>
                                        <Button variant={this.state.withdrawButtonVariant} onBlur={() => this.resetWithdraw()} onClick={() => this.confirmWithdraw(this.props.record.Id)}>{this.state.withdrawButtonText}</Button>
                                    </div>
                                ) :
                                (
                                    this.state.status === 'Withdrew' ? (
                                        <span>Attendee Withdrew</span>
                                    ) :
                                    this.state.status === 'No Show' ? (
                                        <span>Attendee No Show</span>
                                    ) :
                                    (
                                        <div>
                                            <Button variant={this.state.noShowButtonVariant} onBlur={() => this.resetNoShow()} onClick={() => this.confirmNoShow(this.props.record.Id)}>{this.state.noShowButtonText}</Button>
                                            <Button onClick={() => this.props.toggleDetails(this.props.record.Id)}>Check-In/Swap</Button>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                </article>
            </div>
        );
    }

}