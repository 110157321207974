import React from "react";
import Footer from "./Footer";
import CardLayout from "./CardLayout";
import Header from "./Header";
import "./Training.scss";
import { BodyContext, FooterContext } from "./TrainingProvider";
import Spinner from "@salesforce/design-system-react/lib/components/spinner";
import IconSettings from "@salesforce/design-system-react/lib/components/icon-settings";
import Alert from "@salesforce/design-system-react/lib/components/alert";
import AlertContainer from "@salesforce/design-system-react/lib/components/alert/container";
import Toast from "@salesforce/design-system-react/lib/components/toast";
import ToastContainer from "@salesforce/design-system-react/lib/components/toast/container";
import Icon from "@salesforce/design-system-react/lib/components/icon";
import { AppContext } from "./../AppProvider";

export default class Training extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.userId,
      footer: "Home",
      object: "ServiceAppointment",
      modal: "",
      showDetails: false,
      getUserId: this.getUserId.bind(this),
      changeBody: this.changeBody.bind(this),
      changeFooter: this.changeFooter.bind(this),
      changeShowDetails: this.changeShowDetails.bind(this),
      changeModal: this.changeModal.bind(this),
      showSpinner: this.showSpinner.bind(this),
      hideSpinner: this.hideSpinner.bind(this),
      showErrorAlert: this.showErrorAlert.bind(this),
      showSuccessAlert: this.showSuccessAlert.bind(this),
      successMessage: "",
      errorMessage: null,
      isLoading: false,
      showAlertError: false,
      showAlertSuccess: false,
    };
  }

  componentDidMount() {
    // console.log("AppContext", this.context);
  }

  getUserId() {
    return this.state.userId;
  }

  changeBody(object) {
    this.setState({
      object,
    });
  }

  changeFooter(footer) {
    this.setState({
      footer,
    });
  }

  changeShowDetails(showDetails) {
    this.setState({
      showDetails,
    });
  }

  changeModal(modal) {
    this.setState({
      modal,
    });
  }

  showSpinner() {
    this.setState({
      isLoading: true,
    });
  }

  hideSpinner() {
    this.setState({
      isLoading: false,
    });
  }

  showErrorAlert(errorMessage=null) {
    this.setState({
      errorMessage,
      showAlertError: true,
    });
    setTimeout(() => {
      this.hideSpinner();
    }, 2500);
  }

  showSuccessAlert(successMessage) {
    console.log(successMessage);
    this.setState({
      successMessage,
      showAlertSuccess: true,
    });
    setTimeout(() => {
      this.hideSpinner();
    }, 2500);
  }

  render() {
    return (
      <div className="training-v1">
        <Header />

        <div>
          {this.state.isLoading === true && <Spinner size="large" variant="brand" />}

          {this.state.showAlertError === true && (
            <IconSettings iconPath="/assets/icons">
              <AlertContainer>
                <Alert
                  dismissible
                  icon={<Icon category="utility" name="warning" />}
                  labels={{
                    heading: this.state.errorMessage ?? "An error has occured. Try again later.",
                  }}
                  variant="error"
                  onRequestClose={() => {
                    this.setState({ showAlertError: false });
                  }}
                />
              </AlertContainer>
            </IconSettings>
          )}

          {this.state.showAlertSuccess === true && (
            <IconSettings iconPath="/assets/icons">
              <ToastContainer>
                <Toast
                  duration={3000}
                  labels={{
                    heading: this.state.successMessage,
                  }}
                  variant="success"
                  onRequestClose={() => {
                    this.setState({ showAlertSuccess: false });
                  }}
                />
              </ToastContainer>
            </IconSettings>
          )}

          <BodyContext.Provider value={this.state}>
            <FooterContext.Provider value={this.state}>
              <CardLayout
                object={this.state.object}
                showDetails={this.state.showDetails}
                modal={this.state.modal}
              />

              <Footer layout={this.state.footer} />
            </FooterContext.Provider>
          </BodyContext.Provider>
        </div>
      </div>
    );
  }
}
