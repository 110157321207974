import Button from '@salesforce/design-system-react/lib/components/button';
import React from 'react';
import Input from '@salesforce/design-system-react/lib/components/input';
import APIWorker from '../APIWorker';

export default class ConfirmCheckInComponent extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data : [],
            firstName : this.props.record.First_Name__c !== null ? this.props.record.First_Name__c : '', 
            lastName : this.props.record.Last_Name__c !== null ? this.props.record.Last_Name__c : '',
            email : this.props.record.Contact__r.Email !== null ? this.props.record.Contact__r.Email : '',
            requiresEmail : this.props.record.Service_Appointment__r.Requires_Attendee_Email__c,

            nameErrorMessage : '',
            emailErrorMessage : '',
            changedFields : false,
            contactId : ''
        }

        this.checkInAttendee = this.checkInAttendee.bind(this);
        this.validateAttendee = this.validateAttendee.bind(this);
        this.checkChangedFields = this.checkChangedFields.bind(this);
        this.updateContactAttendee = this.updateContactAttendee.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.updateAttendee = this.updateAttendee.bind(this);
    }

    //Upon successfull check in, popup prompt with the name of the user and little check animation or logo
    checkInAttendee(){
        let json = {
            "Status__c" : "Confirmed",
            "Check_in_Time__c" : new Date().toISOString()
        };

        APIWorker.updateAttendee(this.props.record.Id, json)
        .then(res =>{
            this.setState({
                checkedIn : true
            },
                () => {
                    this.props.hideSpinner();
                    this.props.toggleDetails('');
                    this.props.showPrompt();
                }
            );
        })
        .catch(error => {
            console.log(error);
            var errorMessage = String(JSON.parse(error.response.data.message)[0].message).includes('unique_email__c') ? 'Email must be unique!' : null;
            this.props.showErrorAlert(errorMessage);
        });
    }

    clearErrors(){
        this.setState({
            nameErrorMessage : '',
            emailErrorMessage : ''
        });
    }

    validateAttendee(){
        this.clearErrors();
        //Need to run this block if not skillspass or if skillspass block passes
        if(this.state.firstName === '' || this.state.lastName === ''){
            this.setState({
                nameErrorMessage : 'Cannot leave first name or last name blank!'
            });
        }
        else if(/^[a-zA-ZÀ-Ÿ-'`’.\s]+$/.test(this.state.firstName) !== true || /^[a-zA-ZÀ-Ÿ-'`’.\s]+$/.test(this.state.lastName) !== true){
            this.setState({
                nameErrorMessage : 'First or last name can only contain letters, hyphens, accents and apostrophes!'
            });
        }
        else if(this.state.email === '' && this.state.requiresEmail){
            this.setState({
                emailErrorMessage : 'Email is a required field!'
            });                  
        }
        else if(/^.+@.+\..+$/.test(this.state.email) !== true && this.state.email !== ''){
            this.setState({
                emailErrorMessage : 'Email format is invalid! (ex. john@gmail.com)'
            });  
        }
        //If no errors, proceed with check-in process
        else{
            this.props.showSpinner();

            this.setState({
                firstName : this.state.firstName.trim(),
                lastName : this.state.lastName.trim()
            },
                () => {
                    this.checkChangedFields();
                }
            );
        }
    }

    //Updates attendee with contactId
    updateAttendee(){
        //Update attendee record with contact record
        let attendeeJson = {
            "Contact__c" : this.state.contactId
        };

        APIWorker.updateAttendee(this.props.record.Id, attendeeJson)
        .then(res => {
            this.checkInAttendee();
        })
        .catch(error => {
            this.props.showErrorAlert();
        });
    }

    updateContactAttendee(json){
        //If there are no matching results, create contact and check attendee in
        if(this.state.data.length === 0){
            APIWorker.createContact(json)
            .then(res => {
                this.setState({
                    contactId : res.data
                },
                    () => {
                        this.updateAttendee();
                    }
                );
            })
            .catch(error => {
                this.props.showErrorAlert();
            });
        }
        else{
            let contact = this.state.data[0];
            this.setState({
                contactId : contact.Id
            },
                () => {
                    this.updateAttendee();
                }
            );

            //If there is more than 1 record, tag the used record as 'Duplicate Review'
            if(this.state.data.length > 1){
                let contactJson = {
                    "Duplicate_Review__c" : true
                };

                APIWorker.updateContact(this.state.contactId, contactJson)
                .then(res => {
                })
                .catch(error => {
                    this.props.showErrorAlert();
                });
            }
        }
    }

    checkChangedFields(){
        //If the user has changed the first or last name (priority fields)
        if(this.state.changedFields){

            let json = {
                "AccountId" : this.props.record.Contact__r.AccountId,
                "FirstName" : this.state.firstName,
                "LastName" : this.state.lastName,
                "Email" : this.state.email
            };

            //Search for contacts with matching data
            APIWorker.searchContact(json)
            .then(res =>{
                this.setState({
                    data : res.data
                },
                    () => {
                        this.updateContactAttendee(json);
                    }    
                );
            })
            .catch(error => {
                this.props.showErrorAlert();
            });
        }

        //Update existing records if no priority fields changed
        else{
            let contactJson = {
                "Email" : this.state.email
            };

            APIWorker.updateContact(this.props.record.Contact__c, contactJson)
            .then(res => {
                //Check Attendee in
                this.checkInAttendee();
            })
            .catch(error => {
                this.props.showErrorAlert();
            });
        }
    }

    render(){
        return(
            <div>
                <article className="slds-card">
                    <div style={{'textAlign' : 'center', 'fontWeight' : '200'}}>
                        If swapping, adjust details in right column to match new contact
                    </div>
                    <div className="slds-card__header slds-grid">
                        <div className="slds-media slds-media_center slds-has-flexi-truncate">
                            <div className="slds-media__figure">
                                <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                    <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                    </svg>
                                </span>                            
                            </div>
                            <div className="slds-media__body">
                                <h2 className="slds-card__header-title">
                                    <b>{this.props.record.Name}</b>
                                </h2>
                                <span>{this.props.record.Company__c}</span>
                                <br/>
                            </div>
                            <div className="slds-media__body">

                                <Input
                                    label="First Name"
                                    placeholder="ex. John"
                                    type="text"
                                    onChange={(event, data) => {
                                        this.setState({
                                            firstName : data.value,
                                            changedFields : true
                                        });
                                    }}
                                    value={this.state.firstName}
                                    minLength="1"
                                    required={true}
                                />

                                <Input
                                    label="Last Name"
                                    placeholder="ex. Smith"
                                    type="text"
                                    errorText={this.state.nameErrorMessage}
                                    onChange={(event, data) => {
                                        this.setState({
                                            lastName : data.value,
                                            changedFields : true
                                        });
                                    }}
                                    value={this.state.lastName}
                                    minLength="1"
                                    required={true}
                                />

                                <Input
                                    label="Email"
                                    placeholder="ex. john@gmail.com"
                                    type="text"
                                    errorText={this.state.emailErrorMessage}
                                    onChange={(event, data) => {
                                        this.setState({
                                            email : data.value
                                        });
                                    }}
                                    required={this.state.requiresEmail}
                                    value={this.state.email}
                                />

                            </div>
                        </div>
                    </div>

                    <div style={{'textAlign' : 'center'}} className="slds-card__body slds-card__body_inner">
                        <Button onClick={() => this.validateAttendee()} variant="brand">Confirm Details</Button>
                        <Button onClick={() => this.props.toggleDetails('')}>Cancel Check-In</Button>
                    </div>
                </article>
            </div>
        )
    }
}