import React from "react";

import ServiceAppointmentCard from "./ServiceAppointment/ServiceAppointmentCard";
import AttendeeCard from "./Attendee/AttendeeCard";
import FeedbackCard from "./Feedback/FeedbackCard";

export default class RecordDetailsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    console.log("this.props", this.props);
    let cardDetails = this.props.data;
    let cardType = this.props.object;
    let toggleDetails = this.props.toggleDetails;
    if (
      cardType === "ServiceAppointment" ||
      cardType === "PendingServiceAppointment" ||
      cardType === "CompletedServiceAppointment"
    ) {
      return (
        <ServiceAppointmentCard
          key={cardDetails.Id}
          id={cardDetails.Id}
          toggleDetails={toggleDetails}
          Icon="new-note"
          SVGSymbol="new_note"
          Action="Home"
          record={cardDetails}
        />
      );
    } else if (cardType === "Attendee") {
      return (
        <AttendeeCard
          key={cardDetails.Id}
          id={cardDetails.Id}
          toggleDetails={toggleDetails}
          Icon="add-contact"
          SVGSymbol="add_contact"
          Action="AttendeeList"
          record={cardDetails}
        />
      );
    } else if (cardType === "Feedback" || cardType === "CompletedFeedback") {
      return (
        <FeedbackCard
          key={cardDetails.Id}
          id={cardDetails.Id}
          toggleDetails={toggleDetails}
          Icon="submit-for-approval"
          SVGSymbol="submit_for_approval"
          Action={cardType === "Feedback" ? "FeedbackList" : "CompletedFeedbackList"}
          record={cardDetails}
        />
      );
    }
  }
}
