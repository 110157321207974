import React from "react";
import { FooterContext } from "../TrainingProvider";
import Accordion from "@salesforce/design-system-react/lib/components/accordion";
import AccordionPanel from "@salesforce/design-system-react/lib/components/accordion/panel";
import DataTable from "@salesforce/design-system-react/lib/components/data-table";
import DataTableColumn from "@salesforce/design-system-react/lib/components/data-table/column";
import IconSettings from "@salesforce/design-system-react/lib/components/icon-settings";

const columns = [
  <DataTableColumn key="date" label="Date" property="date" width="auto" />,
  <DataTableColumn key="time" label="Time" property="time" width="auto" />,
  <DataTableColumn key="trainer-name" label="Trainer Name" property="trainerName" width="auto" />,
];

function formatDate(date) {
  if (!date) {
    return "";
  }

  var options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
  let outDate = date.toLocaleDateString("en-US", options);
  outDate = outDate.split(",");
  return outDate[0] + ", " + outDate[1] + outDate[2];
}

function formatTime(date) {
  if (!date) {
    return "";
  }

  var options = { hour: "numeric", minute: "2-digit" };
  return date.toLocaleTimeString("en-US", options);
}

function formatDateTime(date) {
  if (!date) {
    return "";
  }

  return formatDate(date) + " @ " + formatTime(date);
}

//Custom view of a service appointment detailed card
export default class ServiceAppointmentCard extends React.Component {
  static contextType = FooterContext;

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      show: true,
      expandedPanels: {},
      startDate: new Date(this.props.record.SchedStartTime.substr(0, 19) + "Z"),
      endDate: new Date(this.props.record.SchedEndTime.substr(0, 19) + "Z"),
      contractor: this.props.record.Contractor__c
        ? " | Contractor: " + this.props.record.Contractor__c
        : "",
      devices: (this.props.record.Order_Product__r != null && this.props.record.Order_Product__r.Additional_Devices__c != null && this.props.record.Product_Family__c === "Evaluations")
      ? " | Additional Devices: " + this.props.record.Order_Product__r.Additional_Devices__c
      : "",
      attendeeName:
        this.props.record.Attendees__r != null &&
        this.props.record.Attendees__r.length === 1 &&
        this.props.record.Product_Family__c === "Evaluations"
          ? " | " +
            this.props.record.Attendees__r[0].Contact__r.FirstName +
            " " +
            this.props.record.Attendees__r[0].Contact__r.LastName
          : "",
      items: [],
    };

    //binding functions
  }

  componentDidMount() {
    //set OSG Assigned Resource records
    if (this.props.record.OSG_Assigned_Resources__r != null) {
      this.props.record.OSG_Assigned_Resources__r.map((ar) => {
        let item = {};

        let startDate = new Date(ar.Scheduled_Start__c.substr(0, 19) + "Z");
        let endDate = new Date(ar.Scheduled_End__c.substr(0, 19) + "Z");

        item.id = ar.Id;
        item.date = startDate.toLocaleDateString();
        item.time =
          startDate.toLocaleTimeString().substr(0, 4) +
          startDate.toLocaleTimeString().substr(7, 3) +
          " - " +
          endDate.toLocaleTimeString().substr(0, 4) +
          endDate.toLocaleTimeString().substr(7, 3);
        item.trainerName = ar.ServiceResource__r.Name;

        return this.state.items.push(item);
      });
    }

    //set footer context
    if (this.props.record.Status === "Completed") {
      this.context.changeFooter("CompletedServiceAppointment");
    } else if (this.props.record.IH3POS__c === "On-Site") {
      if (this.props.record.Product_Family__c === "Evaluations") {
        //Check if the account is Toyota Motor Manufacturing
        if (this.props.record.Account__r.Name.includes("Toyota Motor Manufacturing")) {
          this.context.changeFooter("ToyotaEvalServiceAppointment");
        } else {
          this.context.changeFooter("EvalServiceAppointment");
        }
      } else {
        //Check if the account is Toyota Motor Manufacturing
        if (this.props.record.Account__r.Name.includes("Toyota Motor Manufacturing")) {
          this.context.changeFooter("ToyotaOnSiteServiceAppointment");
        } 
        else if ((this.props.record.Product_Name__c.includes("CPR-C"))) {
          this.context.changeFooter("OnSiteCRCServiceAppointment");
        }
        else {
          //Check if a CPO Course and Non-DL
          if(!this.props.record.Training_Address1__r.Name.includes("Distance Learning") && this.props.record.Has_SkillsPass_Key__c){
            this.context.changeFooter("OnSiteCPOServiceAppointment");
          }
          else{
            this.context.changeFooter("OnSiteServiceAppointment");
          }
        }
      }
    } else if (this.props.record.IH3POS__c !== "On-Site") {
      if (this.props.record.Product_Name__c.includes("JHSCS")) {
        this.context.changeFooter("JHSCSupport");
      } else {
        //Check if a CPO Course and Non-DL
        if(!this.props.record.Training_Address1__r.Name.includes("Distance Learning") && this.props.record.Has_SkillsPass_Key__c){
          this.context.changeFooter("CPOServiceAppointment");
        }
        else{
          this.context.changeFooter("ServiceAppointment");
        }
      }
    }
  }

  togglePanel(data) {
    let expandedPanels = this.state.expandedPanels;
    let toggle = expandedPanels[data] === false ? true : false;

    expandedPanels[data] = toggle;
    this.setState((state) => ({
      expandedPanels,
    }));

    // this.setState(
    //   {
    //     expandedPanels: {},
    //   },
    //   () => {
    //     this.setState((state) => ({
    //       expandedPanels: {
    //         [data]: toggle,
    //       },
    //     }));
    //   }
    // );
  }

  //this.context.changeFooter either uses Home if showingDetails or props.Object if not
  changeContext(action) {
    this.context.changeFooter(action);
    this.props.toggleDetails(undefined);
  }

  render() {
    console.log("render");
    if (!this.state.show) return null;

    let cardIcon = "record";
    let cardSVGSymbol = "record";

    if (this.props.record.Product_Family__c === "Evaluations") {
      cardIcon = "new-note";
      cardSVGSymbol = "new_note";
    }

    let accordionPanels = [];
    console.log(this.props.record);

    if (
      this.props.record.Product_Name__c.substr(0, 4) != "DLIH" &&
      (this.props.record.Alternate_Contact_Name__c ||
        this.props.record.Primary_Contact__c !== "Ana Ivanic")
    ) {
      accordionPanels.push(
        <AccordionPanel
          expanded={this.state.expandedPanels["contact_information"] !== false}
          id={2}
          key={2}
          onTogglePanel={(e) => this.togglePanel("contact_information")}
          summary="Contact Information"
        >
          {this.props.record.Alternate_Contact_Name__c ? (
            <>
              <div className="slds-card__body slds-card__body_inner">
                <b>Primary Contact:</b>&nbsp;{this.props.record.Alternate_Contact_Name__c}
              </div>
              {this.props.record.Alternate_Contact_Mobile__c ? (
                <div className="slds-card__body slds-card__body_inner">
                  <b>Primary Contact Mobile:</b>&nbsp;
                  <a href={"tel:" + this.props.record.Alternate_Contact_Mobile__c}>
                    {this.props.record.Alternate_Contact_Mobile__c}
                  </a>
                </div>
              ) : null}
              {this.props.record.Alternate_Contact_Phone__c ? (
                <div className="slds-card__body slds-card__body_inner">
                  <b>Primary Contact Phone:</b>&nbsp;
                  <a href={"tel:" + this.props.record.Alternate_Contact_Phone__c}>
                    {this.props.record.Alternate_Contact_Phone__c}
                  </a>
                </div>
              ) : null}
              {this.props.record.Alternate_Contact_Email__c ? (
                <div className="slds-card__body slds-card__body_inner">
                  <b>Primary Contact Email:</b>&nbsp;
                  <a href={"mailto:" + this.props.record.Alternate_Contact_Email__c}>
                    {this.props.record.Alternate_Contact_Email__c}
                  </a>
                </div>
              ) : null}
              <hr />
            </>
          ) : null}

          {this.props.record.Primary_Contact__c !== "Ana Ivanic" ? (
            <>
              <div className="slds-card__body slds-card__body_inner">
                <b>Account Contact:</b>&nbsp;{this.props.record.Primary_Contact__c}
              </div>
              {this.props.record.Primary_Contact_Mobile__c ? (
                <div className="slds-card__body slds-card__body_inner">
                  <b>Account Contact Mobile:</b>&nbsp;
                  <a href={"tel:" + this.props.record.Primary_Contact_Mobile__c}>
                    {this.props.record.Primary_Contact_Mobile__c}
                  </a>
                </div>
              ) : null}
              {this.props.record.Primary_Contact_Phone__c ? (
                <div className="slds-card__body slds-card__body_inner">
                  <b>Account Contact Phone:</b>&nbsp;
                  <a href={"tel:" + this.props.record.Primary_Contact_Phone__c}>
                    {this.props.record.Primary_Contact_Phone__c}
                  </a>
                </div>
              ) : null}
              {this.props.record.Primary_Contact_Email__c ? (
                <div className="slds-card__body slds-card__body_inner">
                  <b>Account Contact Email:</b>&nbsp;
                  <a href={"mailto:" + this.props.record.Primary_Contact_Email__c}>
                    {this.props.record.Primary_Contact_Email__c}
                  </a>
                </div>
              ) : null}
            </>
          ) : null}
        </AccordionPanel>
      );
    }

    if (
      this.props.record.Product_Name__c.substr(0, 4) != "DLIH" &&
      this.props.record.Product_Family__c !== "Digital Goods"
    ) {
      accordionPanels.push(
        <AccordionPanel
          expanded={this.state.expandedPanels["training_logistics"] !== false}
          id={1}
          key={1}
          onTogglePanel={(e) => this.togglePanel("training_logistics")}
          summary="Training Logistics"
        >
          <div className="slds-card__body slds-card__body_inner">
            <b>Address:</b>&nbsp;
            <a
              href={
                "https://maps.google.com/maps/?q=" +
                this.props.record.Training_Address1__r.Location_Address__r.Address_1__c +
                "-" +
                this.props.record.Training_Address1__r.Location_Address__r.City__c +
                "-" +
                this.props.record.Training_Address1__r.Location_Address__r.Province__c +
                "-" +
                this.props.record.Training_Address1__r.Location_Address__r.Postal_Code__c
              }
            >
              {this.props.record.Training_Address1__r.Location_Address__r.Address_1__c +
                ", " +
                this.props.record.Training_Address1__r.Location_Address__r.City__c +
                ", " +
                this.props.record.Training_Address1__r.Location_Address__r.Province__c +
                ", " +
                this.props.record.Training_Address1__r.Location_Address__r.Postal_Code__c}
            </a>
          </div>

          {this.props.record.Parking_Notes__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Parking Notes:</b>&nbsp;{this.props.record.Parking_Notes__c}
            </div>
          ) : null}
          {this.props.record.Location_Notes__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Location Notes:</b>&nbsp;{this.props.record.Location_Notes__c}
            </div>
          ) : null}
          {this.props.record.Personal_Protective_Equipment_PPE__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>PPE Notes:</b>&nbsp;{this.props.record.Personal_Protective_Equipment_PPE__c}
            </div>
          ) : null}
          {this.props.record.Training_Room_Access_Details__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Training Room Access Notes:</b>&nbsp;
              {this.props.record.Training_Room_Access_Details__c}
            </div>
          ) : null}
          {this.props.record.Toyota_Training_Room__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Toyota Training Room:</b>&nbsp;{this.props.record.Toyota_Training_Room__c}
            </div>
          ) : null}
        </AccordionPanel>
      );
    }

    let materialFields = (
      <>
        {this.props.record.Number_of_OS_Participants__c ? (
          <div className="slds-card__body slds-card__body_inner">
            <b>Estimated Number of OS Attendees:</b>&nbsp;
            {this.props.record.Number_of_OS_Participants__c}
          </div>
        ) : null}
        {this.props.record.Course_Material__c ? (
          <div className="slds-card__body slds-card__body_inner">
            <b>Course Material:</b>&nbsp;{this.props.record.Course_Material__c}
          </div>
        ) : null}
        {this.props.record.Material_Location__c ? (
          <div className="slds-card__body slds-card__body_inner">
            <b>Material Location:</b>&nbsp;{this.props.record.Material_Location__c}
          </div>
        ) : null}
        {this.props.record.Material_Quantity_Packed__c ? (
          <div className="slds-card__body slds-card__body_inner">
            <b>Material Quantity Packed:</b>&nbsp;{this.props.record.Material_Quantity_Packed__c}
          </div>
        ) : null}
        {this.props.record.Material_Notes__c ? (
          <div className="slds-card__body slds-card__body_inner">
            <b>Material Notes:</b>&nbsp;{this.props.record.Material_Notes__c}
          </div>
        ) : null}
      </>
    );

    let nonNullCount = 0;
    materialFields.props.children.forEach((e) => {
      nonNullCount += e ? 1 : 0;
    });

    if (nonNullCount) {
      accordionPanels.push(
        <AccordionPanel
          expanded={this.state.expandedPanels["materials"] !== false}
          id={3}
          key={3}
          onTogglePanel={(e) => this.togglePanel("materials")}
          summary="Materials"
        >
          {materialFields}
        </AccordionPanel>
      );
    }

    if (this.props.record.Feedback_Count__c) {
      accordionPanels.push(
        <AccordionPanel
          expanded={this.state.expandedPanels["feedback"] !== false}
          id={4}
          key={4}
          onTogglePanel={(e) => this.togglePanel("feedback")}
          summary="Feedback"
        >
          <div className="slds-card__body slds-card__body_inner">
            <b>Feedback Count:</b>&nbsp;{Number(this.props.record.Feedback_Count__c)}
          </div>

          {this.props.record.Feedback_Instructor__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Instructor Rating:</b>&nbsp;
              {Number(this.props.record.Feedback_Instructor__c.toFixed(2))}
            </div>
          ) : null}
          {this.props.record.Feedback_Overall__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Overall Rating:</b>&nbsp;
              {Number(this.props.record.Feedback_Overall__c.toFixed(2))}
            </div>
          ) : null}
        </AccordionPanel>
      );
    }

    return (
      <>
        <article className="slds-card">
          <div
            className="slds-card__header slds-grid"
            style={{
              borderBottom: "1px solid var(--sds-c-accordion-color-border,#dddbda)",
              paddingBottom: ".5rem",
              margin: 0,
            }}
          >
            <div
              onClick={() => this.changeContext(this.props.Action)}
              style={{ marginLeft: "-2px", userSelect: "none", cursor: "pointer" }}
            >
              <span
                className={`slds-icon_container slds-icon-utility-announcement slds-current-color`}
                title="account"
                style={{}}
              >
                <svg className="slds-icon slds-icon_xx-small" aria-hidden="true">
                  <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#back`}></use>
                </svg>
              </span>
              <span style={{ marginLeft: "5px" }}>Back</span>
            </div>
          </div>

          <div className="slds-card__header slds-grid">
            <div className="slds-media slds-media_center slds-has-flexi-truncate">
              <div
                className="slds-media__figure"
                onClick={() => this.changeContext(this.props.Action)}
              >
                <span
                  className={`slds-icon_container slds-icon_container--circle slds-icon-action-${cardIcon}`}
                  title="account"
                  style={{ borderRadius: "5px" }}
                >
                  <svg className="slds-icon slds-icon_small" aria-hidden="true">
                    <use
                      xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#${cardSVGSymbol}`}
                    ></use>
                  </svg>
                </span>
              </div>
              <div className="slds-media__body">
                <h2 className="slds-card__header-title">
                  <b>
                    {this.props.record.AppointmentNumber +
                      this.state.attendeeName +
                      this.state.contractor +
                      this.state.devices}
                  </b>
                </h2>
                <span>{this.props.record.Product_Name__c}</span>
              </div>
            </div>
          </div>

          <div style={{ overflow: "auto" }}>
            <DataTable items={this.state.items} id="dataTable" striped>
              {columns}
            </DataTable>
          </div>

          <div className="slds-card__body slds-card__body_inner">
            <b>Account:</b>&nbsp;{this.props.record.Account__r.Name}
          </div>
          <div className="slds-card__body slds-card__body_inner">
            <b>Account Owner:</b>&nbsp;{this.props.record.Account_Owner__c}
          </div>
          <div className="slds-card__body slds-card__body_inner">
            <b>Start Time:</b>&nbsp;
            {formatDateTime(this.state.startDate)}
          </div>
          <div className="slds-card__body slds-card__body_inner">
            <b>End Time:</b>&nbsp;
            {formatDateTime(this.state.endDate)}
          </div>
          {this.props.record.Days_Till_Scheduled_Start__c > 0 ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Days to Course:</b>&nbsp;
              {this.props.record.Days_Till_Scheduled_Start__c}
            </div>
          ) : (
            <div className="slds-card__body slds-card__body_inner">
              <b>Checked-In Seats:</b>&nbsp;{this.props.record.Checked_In_Seats__c}
            </div>
          )}
          <div className="slds-card__body slds-card__body_inner">
            <b>Confirmed Seats:</b>&nbsp;{this.props.record.Confirmed_Seats__c}
          </div>
          {this.props.record.Trainer_Notes__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Trainer Notes:</b>&nbsp;{this.props.record.Trainer_Notes__c}
            </div>
          ) : null}
          {this.props.record.Virtual_Webinar_Link__c ? (
            <div className="slds-card__body slds-card__body_inner">
              <b>Webinar Link:</b>&nbsp;
              <a href={this.props.record.Virtual_Webinar_Link__c}>Virtual Webinar Link</a>
            </div>
          ) : null}

          {/* Accordion section to hold additional information that trainers find useful */}
          <IconSettings iconPath="/assets/icons">
            <Accordion>{accordionPanels}</Accordion>
          </IconSettings>
        </article>
      </>
    );
  }
}
