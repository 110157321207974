import React from "react";
import FooterComponent from "./FooterComponent";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: "Home",
      show: true,
    };
  }

  componentDidMount() {}

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (this.state.layout !== newProps.layout) {
      this.setState({
        layout: newProps.layout,
      });
    }
  }

  generateFooter() {
    let children = [];
    if (this.state.layout === "Home") {
      //Can make these function to generate different footers
      children = this.generateHomeFooter();
    } else if (this.state.layout === "ServiceAppointment") {
      children = this.generateSAFooter();
    } else if(this.state.layout === 'CPOServiceAppointment') {
      children = this.generateCPOSAFooter();
    } else if (this.state.layout === "CompletedServiceAppointment") {
      children = this.generateCompletedSAFooter();
    } else if (this.state.layout === "AttendeeSkillsPass") {
      children = this.generateAttendeeSkillsPassFooter();
    } else if (this.state.layout === "AttendeeAddEval") {
      children = this.generateAttendeeAddEvalFooter();
    } else if (this.state.layout === "AttendeeList") {
      children = this.generateAttendeeListFooter();
    } else if (this.state.layout === "FeedbackList") {
      children = this.generateFeedbackListFooter();
    } else if (this.state.layout === "CompletedFeedbackList") {
      children = this.generateCompletedFeedbackListFooter();
    } else if (this.state.layout === "OnSiteServiceAppointment") {
      children = this.generateOnSiteSAFooter();
    } else if(this.state.layout === "OnSiteCPOServiceAppointment") {
      children = this.generateOnSiteCPOSAFooter();
    } else if (this.state.layout === "OnSiteCRCServiceAppointment") {
      children = this.generateOnSiteCRCFooter();
    }else if (this.state.layout === "EvalServiceAppointment") {
      children = this.generateEvalSAFooter();
    } else if (this.state.layout === "ToyotaEvalServiceAppointment") {
      children = this.generateToyotaEvalSAFooter();
    } else if (this.state.layout === "ToyotaOnSiteServiceAppointment") {
      children = this.generateToyotaOnSiteSAFooter();
    } else if (this.state.layout === "JHSCSupport") {
      children = this.generateJHSCSupportFooter();
    }
    return children;
  }

  generateHomeFooter() {
    let children = [];
    /*
    children.push(
      <FooterComponent
        key="TodayServiceAppointments"
        Icon="new-event"
        SVGSymbol="new_event"
        Action="List"
        Label="Today"
        Object="ServiceAppointment"
        Details={false}
      />
    );
    */
    children.push(
      <FooterComponent
        key="ServiceAppointments"
        Icon="recall"
        SVGSymbol="record"
        Action="List"
        Label="Upcoming"
        Object="ServiceAppointment"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="PendingServiceAppointments"
        Icon="priority"
        SVGSymbol="priority"
        Action="List"
        Label="Incomplete"
        Object="PendingServiceAppointment"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="CompletedServiceAppointments"
        Icon="record"
        SVGSymbol="approval"
        Action="List"
        Label="Completed"
        Object="CompletedServiceAppointment"
        Details={false}
      />
    );
    return children;
  }

  generateEvalSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="AttendeeList"
        Icon="add-contact"
        SVGSymbol="add_contact"
        Action="List"
        Label="Attendees"
        Object="Attendee"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="AddAttendee"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Check-In/Swap"
        Object="ServiceAppointment"
        ModalObject="AddAttendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateToyotaEvalSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="ToyotaQuestionnaire"
        Icon="share-post"
        SVGSymbol="share_post"
        Action="Modal"
        Label="Toyota Questionnaire"
        Object="ServiceAppointment"
        ModalObject="ToyotaQuestionnaire"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="AttendeeList"
        Icon="add-contact"
        SVGSymbol="add_contact"
        Action="List"
        Label="Attendees"
        Object="Attendee"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="CheckIn"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Check-In"
        Object="ServiceAppointment"
        ModalObject="Attendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="Management"
        Icon="share-poll"
        SVGSymbol="share_poll"
        Action="Modal"
        Label="Management"
        Object="ServiceAppointment"
        ModalObject="CheckedAttendees"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateCPOSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="FileUpload"
        Icon="upload"
        SVGSymbol="upload"
        Action="Modal"
        Label="File Upload"
        Object="ServiceAppointment"
        ModalObject="FileUpload"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="AttendeeList"
        Icon="add-contact"
        SVGSymbol="add_contact"
        Action="List"
        Label="Attendees"
        Object="Attendee"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="CheckIn"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Check-In"
        Object="ServiceAppointment"
        ModalObject="Attendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="Management"
        Icon="share-poll"
        SVGSymbol="share_poll"
        Action="Modal"
        Label="Management"
        Object="ServiceAppointment"
        ModalObject="CheckedAttendees"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateCompletedSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    return children;
  }

  generateOnSiteSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="AttendeeList"
        Icon="add-contact"
        SVGSymbol="add_contact"
        Action="List"
        Label="Attendees"
        Object="Attendee"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="AddAttendee"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Add Attendee"
        Object="ServiceAppointment"
        ModalObject="AddAttendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="Management"
        Icon="share-poll"
        SVGSymbol="share_poll"
        Action="Modal"
        Label="Management"
        Object="ServiceAppointment"
        ModalObject="CheckedAttendees"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateOnSiteCPOSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="FileUpload"
        Icon="upload"
        SVGSymbol="upload"
        Action="Modal"
        Label="File Upload"
        Object="ServiceAppointment"
        ModalObject="FileUpload"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="AttendeeList"
        Icon="add-contact"
        SVGSymbol="add_contact"
        Action="List"
        Label="Attendees"
        Object="Attendee"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="AddAttendee"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Add Attendee"
        Object="ServiceAppointment"
        ModalObject="AddAttendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="Management"
        Icon="share-poll"
        SVGSymbol="share_poll"
        Action="Modal"
        Label="Management"
        Object="ServiceAppointment"
        ModalObject="CheckedAttendees"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateOnSiteCRCFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="FileUpload"
        Icon="upload"
        SVGSymbol="upload"
        Action="Modal"
        Label="File Upload"
        Object="ServiceAppointment"
        ModalObject="FileUpload"
        Details={true}
      />
    )
    children.push(
      <FooterComponent
        key="AttendeeList"
        Icon="add-contact"
        SVGSymbol="add_contact"
        Action="List"
        Label="Attendees"
        Object="Attendee"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="AddAttendee"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Add Attendee"
        Object="ServiceAppointment"
        ModalObject="AddAttendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="Management"
        Icon="share-poll"
        SVGSymbol="share_poll"
        Action="Modal"
        Label="Management"
        Object="ServiceAppointment"
        ModalObject="CheckedAttendees"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }  

  generateToyotaOnSiteSAFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="FileUpload"
        Icon="upload"
        SVGSymbol="upload"
        Action="Modal"
        Label="File Upload"
        Object="ServiceAppointment"
        ModalObject="FileUpload"
        Details={true}
      />
    )
    children.push(
      <FooterComponent
        key="FeedbackList"
        Icon="submit-for-approval"
        SVGSymbol="submit_for_approval"
        Action="List"
        Label="Feedback"
        Object="Feedback"
        Details={false}
      />
    );
    children.push(
      <FooterComponent
        key="ToyotaQuestionnaire"
        Icon="share-post"
        SVGSymbol="share_post"
        Action="Modal"
        Label="Toyota Questionnaire"
        Object="ServiceAppointment"
        ModalObject="ToyotaQuestionnaire"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="AddAttendee"
        Icon="update-status"
        SVGSymbol="update_status"
        Action="Modal"
        Label="Add Attendee"
        Object="ServiceAppointment"
        ModalObject="AddAttendee"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="Management"
        Icon="share-poll"
        SVGSymbol="share_poll"
        Action="Modal"
        Label="Management"
        Object="ServiceAppointment"
        ModalObject="CheckedAttendees"
        Details={true}
      />
    );
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateAttendeeAddEvalFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="AddEvaluation"
        Icon="following"
        SVGSymbol="following"
        Action="Modal"
        Label="Add Evaluation"
        Object="Attendee"
        ModalObject="AddEvaluation"
        Details={true}
      />
    );
    return children;
  }

  generateAttendeeSkillsPassFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="SendConsentForm"
        Icon="email"
        SVGSymbol="email"
        Action="Modal"
        Label="Send Consent Form"
        Object="Attendee"
        ModalObject="SendConsentForm"
        Details={true}
      />
    );
    return children;
  }

  generateJHSCSupportFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="ChangeStatus"
        Icon="goal"
        SVGSymbol="goal"
        Action="Modal"
        Label="Complete"
        Object="ServiceAppointment"
        ModalObject="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateAttendeeListFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="BackToSA"
        Icon="back"
        SVGSymbol="back"
        Action="Back"
        Label="Back"
        Object="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateFeedbackListFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="BackToSA"
        Icon="back"
        SVGSymbol="back"
        Action="Back"
        Label="Back"
        Object="ServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  generateCompletedFeedbackListFooter() {
    let children = [];
    children.push(
      <FooterComponent
        key="BackToSA"
        Icon="back"
        SVGSymbol="back"
        Action="Back"
        Label="Back"
        Object="CompletedServiceAppointment"
        Details={true}
      />
    );
    return children;
  }

  render() {
    if (!this.state.show) return null;

    return <footer className="appFooter">{this.generateFooter()}</footer>;
  }
}
