import axios from "axios";
let apiEndpoint = process.env.REACT_APP_API;

function getAPIUrl(path) {
  return apiEndpoint + "v1/" + path;
}

export default class APIWorker {
  /* AUTHENTICATE */
  static authenticateUser() {
    return axios.get(getAPIUrl("authenticate"));
  }

  /* APP USERS */
  static checkUser(request) {
    return axios.post(getAPIUrl("AppUsers"), request);
  }

  /* SERVICE APPOINTMENTS */
  static getServiceAppointments(request) {
    if (request.type === "pending") {
      return axios.post(getAPIUrl("ServiceAppointments/Pending"), request);
    } else if (request.type === "completed") {
      return axios.post(getAPIUrl("ServiceAppointments/Completed"), request);
    } else {
      return axios.post(getAPIUrl("ServiceAppointments/Upcoming"), request);
    }
  }

  static getServiceAppointment(id) {
    return axios.get(getAPIUrl("ServiceAppointments/" + id));
  }

  static updateServiceAppointment(id, request) {
    return axios.patch(getAPIUrl("ServiceAppointments/" + id), request);
  }

  /* ATTENDEES */
  static getAttendee(id) {
    return axios.get(getAPIUrl("Attendees/" + id));
  }

  static getAttendees(SAid) {
    return axios.get(getAPIUrl("Attendees/ServiceAppointment/" + SAid));
  }

  static getCheckedAttendees(SAid) {
    return axios.get(getAPIUrl("Attendees/ServiceAppointment/CheckedIn/" + SAid));
  }

  static getConfirmedAttendees(SAid) {
    return axios.get(getAPIUrl("Attendees/ServiceAppointment/Confirmed/" + SAid));
  }

  static updateAttendee(id, request) {
    return axios.patch(getAPIUrl("Attendees/" + id), request);
  }

  static createAttendee(request) {
    return axios.post(getAPIUrl("Attendees"), request);
  }

  /* CONTACTS */
  static getContact(id) {
    return axios.get(getAPIUrl("Contacts/" + id));
  }

  static createContact(request) {
    return axios.post(getAPIUrl("Contacts"), request);
  }

  static updateContact(id, request) {
    return axios.patch(getAPIUrl("Contacts/" + id), request);
  }

  static searchContact(request) {
    return axios.post(getAPIUrl("Contacts/Search"), request);
  }

  /* EVALUATIONS */
  static getEvaluation(id) {
    return axios.get(getAPIUrl("Evaluations/" + id));
  }

  static getEvaluationRecordType(request) {
    return axios.post(getAPIUrl("Evaluations/RecordType"), request);
  }

  static getAttendeeEvaluations(id) {
    return axios.get(getAPIUrl("Evaluations/Attendee/" + id));
  }

  static getServiceAppointmentEvalutations(id) {
    return axios.get(getAPIUrl("Evaluations/ServiceAppointment/" + id));
  }

  static createEvaluation(request) {
    return axios.post(getAPIUrl("Evaluations"), request);
  }

  /* FEEDBACK */
  static getFeedbackList(SAid) {
    return axios.get(getAPIUrl("Feedback/ServiceAppointment/" + SAid));
  }

  static getFeedback(id) {
    return axios.get(getAPIUrl("Feedback/" + id));
  }

  /* COURSE QUESTIONNAIRES */
  static getCourseQuestionnaireRecordType(request) {
    return axios.post(getAPIUrl("CourseQuestionnaires/RecordType"), request);
  }

  static updateCourseQuestionnaire(id, request) {
    return axios.patch(getAPIUrl("CourseQuestionnaires/" + id), request);
  }

  static createCourseQuestionnaire(request) {
    return axios.post(getAPIUrl("CourseQuestionnaires"), request);
  }

  /* FILE UPLOAD */
  static uploadFile(request) {
    return axios.post(getAPIUrl("File/Upload"), request);
  }
}
