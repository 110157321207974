import React from "react";
import CardComponent from "./CardComponent";
import RecordDetailsCard from "./RecordDetailsCard";
import ServiceAppointmentList from "./ServiceAppointmentList";

import CloseSAModal from "./ServiceAppointment/CloseSAModal";
import APIWorker from "./APIWorker";
import CheckInModal from "./CheckIn/CheckInModal";
import AddAttendeeModal from "./CheckIn/AddAttendeeModal";
import ManagementModal from "./Management/ManagementModal";
import SendConsentFormModal from "./Attendee/SendConsentFormModal";
import AddEvaluationModal from "./Evaluation/AddEvaluationModal";
import QuestionnaireModal from "./Questionnaire/QuestionnaireModal";
import FileUploadModal from "./FileUploadModal";
import { BodyContext } from "./TrainingProvider";

// import IconSettings from "@salesforce/design-system-react/components/icon-settings";

// import Icon from "@salesforce/design-system-react/components/icon";

// console.log("process.env", process.env);
const allSAs =
  process.env.REACT_APP_SHOW_ALL_SA && process.env.REACT_APP_SHOW_ALL_SA === "1" ? true : false;

export default class CardLayout extends React.Component {
  static contextType = BodyContext;

  constructor(props) {
    super(props);

    this.state = {
      object: this.props.object, //what object type is being displayed in layout
      showDetails: this.props.showDetails, //determines if we are showing a specific card details or not (a list)
      cards: [], //array of child components -> Need to have this be the component representation of the data array0
      cardDetailId: -1, //id of specific record that details will be shown
      isLoading: false, //show a spinner when loading data
      data: [], //data that we want to render
      modal: this.props.modal, //modal that needs to be displayed
    };

    this.toggleDetails = this.toggleDetails.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
    this.hideSpinner = this.hideSpinner.bind(this);
    this.showErrorAlert = this.showErrorAlert.bind(this);
  }

  componentDidMount() {
    //Load the initial data set
    this.refreshData(this.state.showDetails, this.state.cardDetailId, this.state.object);
  }

  componentDidUpdate(oldProps) {
    let updateData = false;

    const newProps = this.props;
    if (oldProps.object !== newProps.object) {
      this.setState({
        object: newProps.object,
      });
      updateData = true;
    }

    if (oldProps.showDetails !== newProps.showDetails) {
      this.setState({
        showDetails: newProps.showDetails,
      });
      updateData = true;
    }

    if (oldProps.modal !== newProps.modal) {
      this.setState({
        modal: newProps.modal,
      });
    }

    if (updateData === true) {
      let renderObject;
      if (newProps.object !== this.state.object) {
        renderObject = newProps.object;
        this.context.changeBody(newProps.object);
      } else {
        renderObject = this.state.object;
      }
      this.refreshData(newProps.showDetails, this.state.cardDetailId, renderObject);
    } else {
    }
  }

  showSpinner() {
    this.context.showSpinner();
  }

  hideSpinner() {
    this.context.hideSpinner();
  }

  showErrorAlert() {
    this.context.showErrorAlert();
  }

  toggleDetails(id) {
    //Set the spinner here
    this.showSpinner();

    this.setState({
      data: [],
    });

    let showDetails = !this.state.showDetails;

    this.context.changeShowDetails(showDetails);

    this.setState({
      cardDetailId: id,
    });
  }

  //When the user goes between cards and lists, this will generate the data the user is requesting and pass data into state to be rendered
  refreshData(showDetails, id, renderObject) {
    this.showSpinner();
    //Generate list
    if (showDetails === false) {
      if (
        renderObject === "ServiceAppointment" ||
        renderObject === "PendingServiceAppointment" ||
        renderObject === "CompletedServiceAppointment"
      ) {
        let today = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();

        let type = "upcoming";
        if (renderObject === "PendingServiceAppointment") {
          type = "pending";
        } else if (renderObject === "CompletedServiceAppointment") {
          type = "completed";
        }

        let json = {
          userId: this.context.userId,
          startDate: today,
          allSAs,
          type,
        };

        APIWorker.getServiceAppointments(json)
          .then((res) => {
            this.setState(
              {
                data: res.data,
              },
              () => {
                this.generateLayout();
                this.hideSpinner();
              }
            );
          })
          .catch((error) => {
            this.showErrorAlert();
          });
      } else if (renderObject === "Attendee") {
        APIWorker.getConfirmedAttendees(id)
          .then((res) => {
            this.setState(
              {
                data: res.data,
              },
              () => {
                this.generateLayout();
                this.hideSpinner();
              }
            );
          })
          .catch((error) => {
            this.showErrorAlert();
          });
      } else if (renderObject === "Feedback" || renderObject === "CompletedFeedback") {
        APIWorker.getFeedbackList(id)
          .then((res) => {
            this.setState(
              {
                data: res.data,
              },
              () => {
                this.generateLayout();
                this.hideSpinner();
              }
            );
          })
          .catch((error) => {
            this.showErrorAlert();
          });
      }
    }
    //Generate record
    else {
      if (
        renderObject === "ServiceAppointment" ||
        renderObject === "PendingServiceAppointment" ||
        renderObject === "CompletedServiceAppointment"
      ) {
        APIWorker.getServiceAppointment(id)
          .then((res) => {
            this.setState(
              {
                data: res.data,
              },
              () => {
                this.generateLayout();
                this.hideSpinner();
              }
            );
          })
          .catch((error) => {
            this.showErrorAlert();
          });
      } else if (renderObject === "Attendee") {
        APIWorker.getAttendee(id)
          .then((res) => {
            this.setState(
              {
                data: res.data,
              },
              () => {
                this.generateLayout();
                this.hideSpinner();
              }
            );
          })
          .catch((error) => {
            this.showErrorAlert();
          });
      } else if (renderObject === "Feedback" || renderObject === "CompletedFeedback") {
        APIWorker.getFeedback(id)
          .then((res) => {
            this.setState(
              {
                data: res.data,
              },
              () => {
                this.generateLayout();
                this.hideSpinner();
              }
            );
          })
          .catch((error) => {
            this.showErrorAlert();
          });
      }
    }
  }

  //This should be called anytime the state changes so it can re-render the body of the layout
  generateLayout() {
    let cardsArr = [];

    //Showing 1 card
    if (this.state.showDetails === true) {
      cardsArr = (
        <RecordDetailsCard
          data={this.state.data[0]}
          object={this.state.object}
          toggleDetails={this.toggleDetails}
        />
      );
    }

    //Showing a list of cards
    else {
      cardsArr = this.generateCards();
    }

    //return cardsArr;
    this.setState({
      cards: cardsArr,
    });
  }

  generateCards() {
    var cardsArr = [];

    if (
      this.state.object === "ServiceAppointment" ||
      this.state.object === "PendingServiceAppointment" ||
      this.state.object === "CompletedServiceAppointment"
    ) {
      return <ServiceAppointmentList data={this.state.data} toggleDetails={this.toggleDetails} />;
    } else if (this.state.object === "Attendee") {
      this.state.data.map((attendee) => {
        let checkInTime = attendee.Check_in_Time__c
          ? new Date(attendee.Check_in_Time__c.substr(0, 19) + "Z")
          : "";
        return cardsArr.push(
          <CardComponent
            key={attendee.Id}
            id={attendee.Id}
            toggleDetails={this.toggleDetails}
            Icon="add-contact"
            SVGSymbol="add_contact"
            Action="Attendee"
            objects={this.state.object}
            title={attendee.Name}
            field1={
              checkInTime
                ? "Check-In Time: " +
                  checkInTime.toDateString() +
                  " " +
                  checkInTime.toLocaleTimeString()
                : ""
            }
            field2={attendee.Email__c ? "Email: " + attendee.Email__c : ""}
            field3={attendee.Company__c}
            field4={"Consent Form Filled: " + attendee.Contact__r.Consent_Form_Filled__c}
          />
        );
      });
    } else if (this.state.object === "Evaluation") {
      for (let i = 0; i < 20; i++) {
        //cardsArr.push(<CardComponent key={i} toggleDetails={this.toggleDetails} Icon="new-task" SVGSymbol="new_task" Action="Evaluation" objects={this.state.object} title={"Evaluation " + i} field1={'Field 1'} field2={'Field 2'} field3={'Field 3'}/>);
      }
    } else if (this.state.object === "Feedback") {
      this.state.data.map((feedback) => {
        //overall experience | instructor rating | instructor knowledgable | instructor engaging
        return cardsArr.push(
          <CardComponent
            key={feedback.Id}
            id={feedback.Id}
            toggleDetails={this.toggleDetails}
            Icon="submit-for-approval"
            SVGSymbol="submit_for_approval"
            Action="Feedback"
            objects={this.state.object}
            title={"Course Engaging: " + feedback.Course_Engaging__c}
            field1={
              "Instructor Rating: " +
              feedback.Instructor__c +
              " | Instructor Engaging: " +
              feedback.Instructor_Engaging__c +
              " | Instructor Knowledgable: " +
              feedback.Instructor_Knowledgable__c
            }
            field2={
              feedback.Additional_Feedback__c ? "Comments: " + feedback.Additional_Feedback__c : ""
            }
            field3={"Overall Experience: " + feedback.Overall_Experience__c}
          />
        );
      });
    } else if (this.state.object === "CompletedFeedback") {
      this.state.data.map((feedback) => {
        //overall experience | instructor rating | instructor knowledgable | instructor engaging
        return cardsArr.push(
          <CardComponent
            key={feedback.Id}
            id={feedback.Id}
            toggleDetails={this.toggleDetails}
            Icon="submit-for-approval"
            SVGSymbol="submit_for_approval"
            Action="CompletedFeedback"
            objects={this.state.object}
            title={"Course Engaging: " + feedback.Course_Engaging__c}
            field1={
              "Instructor Rating: " +
              feedback.Instructor__c +
              " | Instructor Engaging: " +
              feedback.Instructor_Engaging__c +
              " | Instructor Knowledgable: " +
              feedback.Instructor_Knowledgable__c
            }
            field2={
              feedback.Additional_Feedback__c ? "Comments: " + feedback.Additional_Feedback__c : ""
            }
            field3={"Overall Experience: " + feedback.Overall_Experience__c}
          />
        );
      });
    }

    return cardsArr;
  }

  render() {
    return (
      <div className="cardlayout">
        {this.state.modal === "Attendee" && (
          <CheckInModal cardDetailId={this.state.cardDetailId} />
        )}

        {this.state.modal === "AddAttendee" && (
          <AddAttendeeModal cardDetailId={this.state.cardDetailId} sa={this.state.data[0]} />
        )}

        {this.state.modal === "CheckedAttendees" && (
          <ManagementModal cardDetailId={this.state.cardDetailId} />
        )}

        {this.state.modal === "ServiceAppointment" && (
          <CloseSAModal cardDetailId={this.state.cardDetailId} record={this.state.data} />
        )}

        {this.state.modal === "SendConsentForm" && (
          <SendConsentFormModal
            cardDetailId={this.state.cardDetailId}
            record={this.state.data[0]}
          />
        )}

        {this.state.modal === "AddEvaluation" && (
          <AddEvaluationModal
            cardDetailId={this.state.cardDetailId}
            record={this.state.data}
            sa={this.state.data[0]}
          />
        )}

        {this.state.modal === "ToyotaQuestionnaire" && (
          <QuestionnaireModal
            type="toyota"
            cardDetailId={this.state.cardDetailId}
            data={this.state.data[0]}
          />
        )}

        {this.state.modal === "FileUpload" && (
          <FileUploadModal
          cardDetailId={this.state.cardDetailId}
          data={this.state.data[0]}
        />
        )}

        {this.state.cards}
      </div>
    );
  }
}
