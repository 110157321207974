import React from 'react';
import {BodyContext} from './TrainingProvider';

//Footer component can
// - render body components (list)
export default class FooterComponent extends React.Component{
    static contextType = BodyContext;

    componentDidMount(){

    }

    changeContext(object, details, action){
        this.context.changeBody(object);
        this.context.changeShowDetails(details);

        if(action === "Back"){
            if(object === "ServiceAppointment"){
                //this.context.changeFooter(object);
            }
            else if(object === "CompletedServiceAppointment"){
                //this.context.changeFooter(object);
            }
        }
        else if(action === "List"){
            if(object === "Attendee"){
                this.context.changeFooter('AttendeeList');
            }            
            else if(object === "ServiceAppointment"){
            }
            else if(object === "PendingServiceAppointment"){
            }
            else if(object === "Feedback"){
                this.context.changeFooter('FeedbackList');
            }
            else if(object === "CompletedFeedback"){
                this.context.changeFooter('CompletedFeedbackList');
            }
        }
        else if(action === "Modal"){
            this.context.changeModal(this.props.ModalObject);
        }
    }

    render(){
        let selectedClass = this.context.object === this.props.Object && this.props.Action === 'List' ? 'selectedFooterComponent' : '';
        return(
            <div onClick={() => this.changeContext(this.props.Object, this.props.Details, this.props.Action)}>
                <span>
                    <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-${this.props.Icon}`}>
                        <svg className="slds-icon slds-icon--small">
                            <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#${this.props.SVGSymbol}`}></use>
                        </svg>
                    </span>
                    <figcaption className={`slds-p-top--xxx-small slds-text-body_small ${selectedClass}`}>{this.props.Label}</figcaption>
                </span>
            </div>
        );
    }
}