import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';
import OverheadCraneForm from './OverheadCraneForm';
import MobileBoomCraneForm from './MobileBoomCraneForm';
import BucketTruckForm from './BucketTruckForm';
import AerialLiftForm from './AerialLiftForm';
import LiftTruckForm from './LiftTruckForm';
import TelehandlerForm from './TelehandlerForm';
import FallProtectionForm from './FallProtectionForm';
import QualitativeRespiratorForm from './QualitativeRespiratorForm';
import QuantitativeRespiratorForm from './QuantitativeRespiratorForm';

export default class AddEvaluationModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            cardDetailId : this.props.cardDetailId,
            cards : []
        }

        this.generateLayout = this.generateLayout.bind(this);
    }

    componentDidMount(){
        this.generateLayout();
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    generateLayout(){
        let cards = [];

        if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Overhead Crane Practical Evaluation'){
            cards.push(
                <OverheadCraneForm key="OC" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Mobile Boom Crane Practical Evaluation'){
            cards.push(
                <MobileBoomCraneForm key="MBC" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Bucket Truck Practical Evaluation'){
            cards.push(
                <BucketTruckForm key="BT" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Aerial Lift Practical Evaluation'){
            cards.push(
                <AerialLiftForm key="AWP" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Lift Truck Practical Evaluation'){
            cards.push(
                <LiftTruckForm key="LT" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );            
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Telehandler Practical Evaluation'){
            cards.push(
                <TelehandlerForm key="TLT" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );            
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Fall Protection Evaluation'){
            cards.push(
                <FallProtectionForm key="FP" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );            
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Respirator Fit Testing (Qualitative)'){
            cards.push(
                <QualitativeRespiratorForm key="QUAL" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );            
        }
        else if(this.props.sa.Service_Appointment__r.Product__r.Evaluation_Record_Type__c === 'Respirator Fit Testing (Quantitative)'){
            cards.push(
                <QuantitativeRespiratorForm key="QUAN" attendeeId={this.state.cardDetailId} sa={this.props.sa}/>
            );            
        }        

        this.setState({
            cards
        });
    }

    render(){
        return (
            <div>
                <Modal isOpen={true} heading="Add Evaluation" ariaHideApp={false} align="center" size="large"
                footer={[
                    <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                ]}>
                    {this.state.cards}
                </Modal>                   
            </div>
        )
    }
}