import React from 'react'

export const BodyContext = React.createContext({
    object: 'ServiceAppointment',
    userId : '',
    getUserId: () =>{},
    changeBody: () =>{},
    changeShowDetails: () =>{},
    changeModal: () =>{},
    showSpinner: () => {},
    hideSpinner: () => {},
    showErrorAlert: () => {},
    showSuccessAlert: () => {},
    changeFooter: () =>{},
});

export const FooterContext = React.createContext({
    footer: 'Home',
    changeFooter: () =>{},
    showSpinner: () => {},
    hideSpinner: () => {},
    showErrorAlert: () => {}
});