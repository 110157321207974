import Button from '@salesforce/design-system-react/lib/components/button';
import React from 'react';
import {BodyContext} from '../TrainingProvider';
import Input from '@salesforce/design-system-react/lib/components/input'; 
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import ProgressIndicator from '@salesforce/design-system-react/lib/components/progress-indicator'; 
import APIWorker from '../APIWorker';

export default class SearchResultComponent extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {

        }
    }


    selectContact(id){
        //create attendee record and link to contact record selected (passed in contact id in)
        this.props.selectContact(id, this.props.duplicateReview);
    }

    createContact(){
        //creates contact and attendee (create contact record for id)
        this.props.createContact();
    }

    clearResults(){
        this.props.clearResults();
    }

    render(){
        //If a specific contact record is passed in, render details
        if(this.props.record){
            return (
                <div>
                    <article className="slds-card">
                        <div className="slds-card__header slds-grid">
                            <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                <div className="slds-media__figure">
                                    <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="attendee">
                                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                            <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                        </svg>
                                    </span>                            
                                </div>
                                <div className="slds-media__body">
                                    <h2 className="slds-card__header-title">
                                        <b>{this.props.record.FirstName} {this.props.record.LastName}</b>
                                    </h2>
                                    {
                                        this.props.record.Email ?
                                        <span>{this.props.record.Email}</span> :
                                        ''
                                    }
                                    <br/>
                                    {
                                        this.props.record.Phone ? 
                                        <span>{this.props.record.Phone}</span> :
                                        ''
                                    }                                    
                                </div>
                                
                                <div className="slds-no-flex">
                                    <Button onClick={() => this.selectContact(this.props.record.Id)}>Select Contact</Button>
                                </div>
                                            
                            </div>
                        </div>
                    </article>            
                </div>
            )
        }
        //if no specific contact record is passed in, create new record using details
        else{
            return (
                <div>
                    <article className="slds-card">
                        <div className="slds-card__header slds-grid">
                            <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                
                                <div className="slds-media__body">
                                    <h2 className="slds-card__header-title">
                                        0 search results found. Create record with following details and proceed?
                                    </h2>
                                    <br/>
                                    <b>Name: {this.props.data.FirstName} {this.props.data.LastName}</b>
                                    <br/>
                                    {
                                        this.props.data.Email ?
                                        <span>Email: {this.props.data.Email}</span> :
                                        ''
                                    }
                                    <br/>
                                    {
                                        this.props.data.Phone ? 
                                        <span>Phone: {this.props.data.Phone}</span> :
                                        ''
                                    }
                                </div>
                                
                                <div className="slds-no-flex">
                                    <Button onClick={() => this.createContact()} variant="brand">Confirm</Button>
                                    <Button onClick={() => this.clearResults()}>Search Again?</Button>
                                </div>
                                            
                            </div>
                        </div>
                    </article>            
                </div>
            )
        }
    }

}